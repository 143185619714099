import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { QRCodeSVG } from 'qrcode.react';
import { CopyButton } from '../CopyButton';
import {
  ButtonWrapper,
  PixCodeContainer,
  PixContainer,
  QrCodeWrapper,
} from './styled';

export interface IQRCodeProps {
  code: string;
  pdfUrl?: string;
}

export function QRCode(props: IQRCodeProps) {
  const downloadLink = (link?: string) => {
    const newWindow = window.open(link);
    if (newWindow) {
      dispatchToast({
        type: 'success',
        content: 'Pix baixado com sucesso!',
      });
      newWindow.focus();
    } else {
      dispatchToast({
        type: 'error',
        content: 'Não foi possível baixar o pix.',
        description:
          'Tente novamente. Se o problema persistir, entre em contato com o suporte',
      });
    }
  };

  return (
    <PixContainer>
      <Flex direction="row">
        <QrCodeWrapper>
          <QRCodeSVG value={props.code} size={140} />
        </QrCodeWrapper>
        <PixCodeContainer>{props.code}</PixCodeContainer>
      </Flex>
      <Flex
        padding="xs4"
        gap="xs2"
        direction="row"
        align="center"
        justify="center"
        wrap="wrap-reverse"
      >
        <ButtonWrapper>
          <CopyButton
            textToCopy={props.code}
            copiedLabel="Código copiado!"
            defaultLabel="Copiar código Pix"
          />
        </ButtonWrapper>

        {props.pdfUrl && (
          <PillButton
            icon={'IconDownload'}
            size="small"
            label="Baixar PDF"
            type="secondary"
            variant="pink"
            onClick={() => downloadLink(props.pdfUrl)}
            iconPosition="left"
          />
        )}
      </Flex>
    </PixContainer>
  );
}

export function copyCodeToClipboard(
  setCopied: React.Dispatch<React.SetStateAction<boolean>>,
  code?: string,
) {
  if (!code) return;

  navigator.clipboard.writeText(code).then(() => {
    setCopied(true);

    setTimeout(function () {
      setCopied(false);
    }, 5000);
  });
}
