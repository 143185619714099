import AuthService from '@frontend/services/AuthService';
import { trpc } from '@frontend/trpc';

type Input = {
  orderId?: string;
  paymentMethod: 'PIX' | 'BILLET';
};

export function useGetCode(input: Input) {
  const user = AuthService.useUser();

  const query = trpc.corporateCard.deposits.getBill.useQuery(
    {
      orderId: input.orderId ?? '',
    },
    {
      enabled: !!input.orderId,
    },
  );

  return {
    code: query.data?.code,
    pdfLink: query.data?.pdfLink,
    isLoading: query.isFetching,
    isError: query.isError,
    onRetryClick: () => {
      void query.refetch();
    },
    paymentInfos: {
      paymentMethod: query.data?.type,
      paymentTitle: formatPaymentTitle(),
      email: user?.email ?? 'cadastrado em seu usuário',
      emailText: 'Ele também será enviado para o e-mail',
    },
  };

  function formatPaymentTitle() {
    const paymentMethod = input.paymentMethod;
    switch (paymentMethod) {
      case 'BILLET':
        return 'Leia ou copie o código do Boleto';
      case 'PIX':
        return 'Leia ou copie o código Pix';
      default:
        return 'Leia ou copie o código';
    }
  }
}
