import SummaryFeedback from '@frontend/components/SummaryFeedback';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { useSummaryData } from '../../data/useSummaryData';
import { LoadingPaymentFile } from './LoadingPaymentFile';
import { PaymentAvailable } from './PaymentAvailable';
import { PaymentFailed } from './PaymentFailed';

type Props = {
  form: UseFormReturn;
};

export function PaymentSummary(props: Props) {
  const data = useSummaryData({
    form: props.form,
  });

  function Content() {
    if (data.downloadPdf?.isLoading) return <LoadingPaymentFile />;

    if (data.downloadPdf?.onRetryClick)
      return (
        <PaymentFailed
          email={data.downloadPdf?.email}
          onRetryClick={data.downloadPdf?.onRetryClick}
        />
      );

    if (
      (data.deposit.paymentDetails?.paymentMethod?.label === 'Pix' &&
        data.pixCode) ||
      data.deposit.status === 'SUCCESS'
    )
      return <PaymentAvailable {...data} />;

    return null;
  }

  return (
    <>
      {data.paymentMethod === 'FLASH_CASH' ? null : (
        <SummaryFeedback
          title={data.paymentTitle}
          headerGridTemplateColumns="auto 50px;"
        >
          {!data.downloadPdf?.onRetryClick && (
            <Flex padding={['xs3', 'xs3', undefined, 'xs3']}>
              <Typography.Body4 color="neutral_30">
                {data.downloadPdf?.emailText}{' '}
                <Typography.Body4 weight={700} as="span">
                  {data.downloadPdf?.email}
                </Typography.Body4>
              </Typography.Body4>
            </Flex>
          )}
          <Content />
        </SummaryFeedback>
      )}
    </>
  );
}
