/**
 * Notion features description
 * https://www.notion.so/flashapp/Cart-o-Corporativo-Feature-Flags-4ba618e62d1b4f0692344afacedca0d3?pvs=4
 */
import * as featureFlag from '@flash-tecnologia/feature-flags';
import AuthService from './AuthService';
import EnvService from './EnvService';

const corporateCardRequest = 'FLASH_OS_CORPORATE_CARD_REQUEST_CARD';
const externalCards = 'FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS';
const externalCardsV2 = 'FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS_V2';
const policies = 'FLASH_OS_CORPORATE_CARD_POLICIES';
const uploadTransactions = 'FLASH_OS_CORPORATE_CARD_UPLOAD_TRANSACTIONS';
const exportReportAsPdf = 'FLASH_OS_CORPORATE_CARD_EXPORT_REPORT_AS_PDF';
const limitByCategoryPolicy =
  'FLASH_OS_CORPORATE_CARD_POLICY_LIMIT_BY_CATEGORY';
const accountManagement = 'FLASHOS_CORPORATE_CARD_MANAGEMENT_V2';
const switchNewXP = 'FLASHOS_CORPORATE_CARD_SWITCH_NEW_XP';
const approvals = 'FLASH_OS_CORPORATE_CARD_APPROVALS';
const displayCode = 'FLASH_OS_DISPLAY_CODE';
const newSaas = 'FLASHOS_EXPENSE_NEW_SAAS_ACQUISITION';
const statement = 'FLASH_OS_CORPORATE_CARD_STATEMENT';
const addFlashCashV2 = 'FLASH_OS_CORPORATE_CARD_ADD_FLASH_CASH';
const emailReceiptEnabled = 'FLASH_OS_ENABLE_EMAIL_RECEIPT';
const reclassificationModalV2Enabled =
  'FLASH_OS_ENABLE_RECLASSIFICATION_MODAL_V2';

const FEATURES = {
  accountManagement,
  addFlashCashV2,
  approvals,
  corporateCardRequest,
  displayCode,
  emailReceiptEnabled,
  externalCards,
  exportReportAsPdf,
  externalCardsV2,
  limitByCategoryPolicy,
  newSaas,
  policies,
  reclassificationModalV2Enabled,
  statement,
  switchNewXP,
  uploadTransactions,
};

export default class FeatureFlagService {
  static getFlag(flagName: keyof typeof FEATURES) {
    featureFlag.WithFlag({
      flagName: FEATURES[flagName],
    });
    return featureFlag.useFlag({
      flagName: FEATURES[flagName],
    });
  }
}

export function FeatureFlagProvider(props: { children: React.ReactNode }) {
  const employeeId = AuthService.useEmployeeId();
  const company = AuthService.useCompany();
  return (
    <featureFlag.FlagsProvider
      appName={`flash_os&environment=${EnvService.BUILD_ENV}`}
      token={EnvService.UNLEASH_TOKEN}
      url={EnvService.UNLEASH_URL}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId: employeeId ?? '',
        properties: {
          employeeNanoId: employeeId ?? '',
          companyNanoId: company?.id ?? '',
          environment: EnvService.BUILD_ENV,
        },
      }}
    >
      {props.children}
    </featureFlag.FlagsProvider>
  );
}
