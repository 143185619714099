import { trpc } from '@frontend/trpc';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Input = {
  employeeIds: Set<string>;
  deposit: {
    amount: number;
    automatic: boolean;
    complementary: boolean;
    confirmationToken: string;
    creditDate: Date;
    expirationDate?: Date;
    expires: boolean;
    paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  } | null;
  onSuccess: () => void;
};

export function useActivateUsers() {
  const activateMany = trpc.company.employees.activateMany.useMutation();

  return {
    data: activateMany.data,
    mutate: (input: Input) => {
      return activateMany.mutate(
        {
          employeeIds: Array.from(input.employeeIds),
          deposit: input.deposit,
        },
        {
          onSuccess: (data) => {
            if (data.auth === 'FAILED') {
              dispatchToast({
                content: 'Código de confirmação inválido',
                description: errors.actions.toastDescription,
                type: 'error',
              });
            } else {
              input.onSuccess();
            }
          },
        },
      );
    },
  };
}
