import ShowSensitiveDataModal from '../modals/ShowSensitiveDataModal';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import React from 'react';

/** Fetches the virtual Flash cards data.
 *
 * Includes sensitive data if the transaction password is provided
 */
export default function useGetVirtualCards() {
  const [showSensitiveData, setShowSensitiveData] = React.useState(false);
  const [transactionPassword, setTransactionPassword] =
    React.useState<string>();

  const getAllSensitiveQueryEnabled = !!transactionPassword;

  const toggleSensitiveData = (newValue: boolean) => {
    setShowSensitiveData(newValue);
    if (newValue && !transactionPassword) {
      ModalService.show(ShowSensitiveDataModal, {
        onClick(password) {
          setTransactionPassword(password);
          toastStart();
          ModalService.hide(ShowSensitiveDataModal);
        },
      });
    }
  };

  const getAll = trpc.employee.flashCards.virtual.getAll.useQuery();
  const getAllSensitive =
    trpc.employee.flashCards.virtual.getAllSensitive.useQuery(
      {
        transactionPassword: transactionPassword ?? '',
      },
      {
        enabled: getAllSensitiveQueryEnabled,
        onSuccess(data) {
          if ('message' in data) {
            switch (data.message) {
              case 'INVALID_TRANSACTION_PASSWORD':
                toastWrongPassword();
                break;
              case 'TOO_MANY_PASSWORD_ATTEMPTS':
                toastTooManyAttempts();
                break;
              default:
                toastError();
            }
          } else {
            toastSuccess();
          }
        },
        onError() {
          dispatchToast({
            type: 'error',
            content: 'Erro ao carregar dados sensíveis.',
            description:
              'Tente novamente. Se o problema persistir, entre em contato com o suporte',
          });
          setTransactionPassword(undefined);
        },
      },
    );
  const cards = getAll.data?.cards ?? [];
  const sensitiveData = Array.isArray(getAllSensitive.data)
    ? getAllSensitive.data
    : [] ?? [];

  const mergedCards = cards.map((card) => ({
    ...card,
    // Merges the `sensitiveData` into `cards`
    sensitiveData: showSensitiveData
      ? sensitiveData.find((data) => data.cardId === card.cardId)
      : undefined,
  }));

  return {
    data: {
      employee: {
        name: getAll.data?.name,
      },
      cards: mergedCards,
    },
    isLoading: getAll.isLoading,
    isLoadingSensitiveData:
      getAllSensitiveQueryEnabled && getAllSensitive.isLoading,
    sensitiveDataAvailable: showSensitiveData && !!sensitiveData.length,
    toggleSensitiveData,
  };
}

export type UseGetVirtualCardsReturn = ReturnType<typeof useGetVirtualCards>;

function toastStart() {
  dispatchToast({
    type: 'warning',
    content: 'Carregando dados sensíveis...',
  });
}

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Dados sensíveis carregados com sucesso!',
  });
}

function toastError() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível carregar os dados sensíveis.',
    description: errors.actions.toastDescription,
  });
}

function toastWrongPassword() {
  dispatchToast({
    type: 'error',
    content: errors.cards.password.wrongPasswordToast.content,
  });
}

function toastTooManyAttempts() {
  dispatchToast({
    type: 'error',
    content: errors.cards.password.tooManyAttemptsToast.content,
    description: errors.cards.password.tooManyAttemptsToast.description,
  });
}
