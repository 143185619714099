import { Navigate, Route } from 'react-router-dom';

import { ROUTE_CorporateStatement } from '@frontend/routes/ExternalRoutes';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import FailedCredit from './tabs/FlashCard/FailedCredit/FailedCredit';
import FlashCash from './tabs/FlashCard/FlashCard';
import NextCredit from './tabs/FlashCard/NextCredit/NextCredit';
import RequestBalanceSection from './tabs/RequestBalanceSection/RequestBalanceSection';
import RequestBalanceSectionV2 from './tabs/RequestBalanceSectionV2/RequestBalanceSectionV2';

export function RouteComponent_Management() {
  const approvals = FeatureFlagService.getFlag('approvals');

  function resolveBalanceRequestElement() {
    if (approvals) return <RequestBalanceSectionV2 type="deposit" />;

    return <RequestBalanceSection />;
  }

  return (
    <>
      <Route
        path="/corporateCard/management"
        element={<Navigate to={ROUTE_CorporateStatement.buildPath({})} />}
      >
        <Route
          element={
            <Navigate to="/corporateCard/management/deposits/nextCredit" />
          }
        />
        <Route
          path="/corporateCard/management/deposits"
          element={<FlashCash />}
        >
          <Route
            path="/corporateCard/management/deposits/nextCredit"
            element={<NextCredit />}
          />
          <Route
            path="/corporateCard/management/deposits/failedCredit"
            element={<FailedCredit />}
          />
          <Route
            index
            path="/corporateCard/management/deposits/*"
            element={
              <Navigate to="/corporateCard/management/deposits/nextCredit" />
            }
          />
        </Route>
        <Route
          path="/corporateCard/management/balanceRequests"
          element={resolveBalanceRequestElement()}
        />
      </Route>
      <Route
        index
        path="/corporateCard/management/*"
        element={<Navigate to="/corporateCard/management/deposits" />}
      />
    </>
  );
}
