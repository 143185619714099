import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import Button from '@frontend/components/Button';
import Spacer from '@frontend/components/Spacer';
import { TableProps } from '@frontend/components/TableV2';
import Tag from '@frontend/components/TagV2';
import ModalService from '@frontend/services/ModalService';
import { RouterOutputs } from '@frontend/trpc';
import { formatDate, toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import ApproveRequestModal from '../modals/ApproveRequestModal';
import ReproveRequestModal from '../modals/ReproveRequestModal';
import * as styled from '../styled';
import { TABLE_COLUMNS } from './columns';

type Output = RouterOutputs['corporateCard']['requestBalance']['search'];

type Rows = Output['entries'][number];
type Columns = TableProps<Rows>['columns'];

export function useTableColumns(): Columns {
  return [
    {
      accessorKey: 'name',
      accessorFn: (row) => ({
        name: capitalizeWords(row.employee.name),
        email: row.employee.email,
      }),
      header: () => (
        <styled.Cell>
          <Spacer x="xs" />
          {TABLE_COLUMNS[0]}
        </styled.Cell>
      ),
      cell: ({ cell }) => {
        const value = cell.getValue<{ name: string; email?: string }>();
        return (
          <styled.StyledBoxAdornment
            leftAdornment={
              <styled.StyledIcon>{value.name[0]}</styled.StyledIcon>
            }
            title={value.name}
            description={
              value.email && (
                <styled.Wrapper>
                  <Icons fill="transparent" name="IconMail" size={15} />
                  <Spacer x="xs5" />
                  {value.email}
                </styled.Wrapper>
              )
            }
          />
        );
      },
    },
    {
      accessorKey: 'reason',
      header: () => <styled.Cell>{TABLE_COLUMNS[1]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{row.original.reason}</styled.Cell>;
      },
    },
    {
      accessorKey: 'value',
      header: () => <styled.Cell>{TABLE_COLUMNS[2]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{toCurrency(row.original.value)}</styled.Cell>;
      },
    },
    {
      accessorKey: 'status',
      header: () => <styled.Cell>{TABLE_COLUMNS[3]}</styled.Cell>,
      cell: ({ row }) => {
        const { label, value } = row.original.status;

        return (
          <Tag size="sm" variant={getColorTag(value)}>
            {label}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: () => (
        <styled.Cell>
          {TABLE_COLUMNS[4]}
          <Spacer x="xs4" />
          <Tooltip
            title="Você tem 15 dias para aprovar esse pedido, a partir da data de solicitação, ou ele será recusado automaticamente"
            arrow={true}
          >
            <div>
              <Icons name="IconInfoCircle" fill="transparent" color="#83727D" />
            </div>
          </Tooltip>
        </styled.Cell>
      ),
      cell: ({ row }) => {
        return <styled.Cell>{formatDate(row.original.createdAt)}</styled.Cell>;
      },
    },
    {
      accessorKey: 'creditDate',
      header: () => <styled.Cell>{TABLE_COLUMNS[5]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{formatDate(row.original.creditDate)}</styled.Cell>;
      },
    },
    {
      id: 'actions',
      isSticky: true,
      header: () => <styled.Cell>Aprovar pedidos de saldo</styled.Cell>,
      cell: ({ row }) => {
        row.original;

        return (
          <styled.Cell>
            <styled.Wrapper>{actionButtons(row.original)}</styled.Wrapper>
          </styled.Cell>
        );
      },
    },
  ];
}

function getColorTag(
  status: Rows['status']['value'],
): Parameters<typeof Tag>[number]['variant'] {
  switch (status) {
    case 'expired':
    case 'cancelled':
    case 'reproved':
    case 'depositCancelled':
      return 'error';
    case 'paid':
      return 'success';
    default:
      return 'info';
  }
}

function actionButtons(order: Rows) {
  switch (order.status.value) {
    case 'cancelled':
    case 'expired':
    case 'paid':
    case 'reproved':
    case 'processing':
    case 'depositCancelled':
    case 'waiting':
      return (
        <Button onClick={() => null} size="small" variant="secondary" disabled>
          Sem pendências
        </Button>
      );
    default:
      return (
        <>
          <Button
            size="small"
            variant="secondary"
            color="success"
            onClick={() => {
              ModalService.show(ApproveRequestModal, {
                order: {
                  _id: order._id,
                  creditDate: order.creditDate,
                  employees: [order.employee],
                  value: order.value,
                },
              });
            }}
          >
            Aprovar
          </Button>
          <Spacer x="xs2" />
          <Button
            size="small"
            variant="secondary"
            color="error"
            onClick={() => {
              ModalService.show(ReproveRequestModal, {
                orderId: order._id,
              });
            }}
          >
            Reprovar
          </Button>
        </>
      );
  }
}
