import Flex from '@frontend/components/frames/Flex';
import TextArea from '@frontend/components/inputs/TextArea';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import React from 'react';
import { useCancelRequest } from '../data/useCancelRequest';

type Props = {
  orderId: string;
  employee: string;
};

function CancelRequestModal(props: Props) {
  const modalController = ModalService.useModalController();
  const cancelRequest = useCancelRequest({
    multiple: false,
    closeModal: modalController.remove,
  });
  const [reason, setReason] = React.useState<string>('');

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'confirmar cancelamento',
          onClick: () => {
            cancelRequest.cancel({
              orderId: props.orderId,
              justification: reason,
            });
          },
        },
        cancel: {
          text: 'Fechar',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="error"
      text={{
        title: `Tem certeza que deseja cancelar o pedido de ${props.employee}?`,
        highlightedText: 'Atenção!',
        body: (
          <Flex direction="column" gap="xs2">
            <Typography.Body4 color="neutral_40">
              O pedido de saldo será cancelado e o solicitante receberá uma
              notificação com o motivo do cancelamento.
            </Typography.Body4>
            <TextArea
              maxLength={250}
              label="Informe o motivo do cancelamento"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Flex>
        ),
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(CancelRequestModal);
