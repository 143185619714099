import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 'xs4',
})`
  width: 100%;
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const CardContent = styled(Flex).attrs({
  align: 'center',
  gap: 'xs3',
})`
  width: 100%;
`;

export const CardWrapper = styled(Flex).attrs({
  align: 'center',
  padding: 'xs2',
})`
  border-bottom: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};

  &:last-child {
    border-bottom: none;
  }
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;
