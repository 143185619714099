import { DatePicker } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../form/useForm';
import { useFormMetadata } from '../data/useFormMetadata';

type Props = {
  form: UseFormReturn;
  formMetadata: ReturnType<typeof useFormMetadata>;
};

export function DepositCreditDate(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="deposit.creditDate"
      render={({ field, fieldState }) => (
        <DatePicker
          label="Data de depósito"
          fromDate={props.formMetadata.creditDateFrom}
          value={field.value}
          onDateChange={(date) => field.onChange(date?.toDate() ?? undefined)}
          helperText={
            fieldState.error?.message ??
            'Data em que o valor do depósito será creditado'
          }
          error={Boolean(fieldState.error)}
          onBlur={field.onBlur}
        />
      )}
    />
  );
}
