import { cpfMask } from '@flash-tecnologia/corporate-card-bff/src/utils/masks/formatCPF';
import { RouterInputs, trpc } from '@frontend/trpc';

type Props = {
  enabled?: boolean;
};

/** Returns a list of employees as options for the ComboBox input */
export function useGetEmployees(props?: Props) {
  const context = trpc.useContext();

  const getEmployees = trpc.corporateCard.employees.search.useQuery(undefined, {
    cacheTime: 0,
    staleTime: 0,
    enabled: props?.enabled,

    structuralSharing(oldData, newData) {
      if (!oldData) {
        return newData;
      }

      if (oldData.length === newData.length) {
        return newData;
      }

      const copyOldData = Array.from(oldData);

      const data = [
        ...newData.map((data) => {
          const index = copyOldData.findIndex(
            (selectedOption) => selectedOption.id === data.id,
          );

          const [deletedValue] = index > -1 ? copyOldData.splice(index) : [];

          return {
            ...data,
            selected: deletedValue ? deletedValue.selected : data.selected,
          };
        }),
        ...copyOldData,
      ];

      return data;
    },
  });

  const fetch = (input: RouterInputs['corporateCard']['employees']['search']) =>
    context.corporateCard.employees.search.fetch(input, {
      structuralSharing: (_, newData) => {
        context.corporateCard.employees.search.setData(undefined, (oldData) => {
          const selectedOptions =
            oldData?.filter((data) => data.selected) || [];

          const data = [
            ...newData.map((data) => {
              const index = selectedOptions?.findIndex(
                (selectedOption) => selectedOption.id === data.id,
              );

              if (index >= 0) {
                selectedOptions.splice(index, 1);
              }

              return {
                ...data,
                selected: index >= 0,
              };
            }),
            ...selectedOptions,
          ];
          return data;
        });

        return newData;
      },
    });

  return {
    isError: getEmployees.isError,
    loading: getEmployees.isLoading,
    fetch,
    refresh: getEmployees.refetch,
    employees:
      getEmployees.data
        ?.sort((a) => (a.alreadyActive ? 1 : -1))
        .map((user) => ({
          /** Employee's name */
          title: user.name,
          /** Employee's nanoID */
          value: user.id,
          /** Employee's document number */
          description: user.alreadyActive
            ? 'Esta pessoa já usa o cartão corporativo'
            : cpfMask(user.documentNumber),
          /** Employee's raw document number */
          additionalFilterKey: user.documentNumber,
          /** Disables selection of the employee if already active */
          disabled: user.alreadyActive,
          /** whether employee is selected or not */
          selected: user.selected,
        })) ?? [],
  };
}
