import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Countdown } from '@frontend/components/Countdown/Countdown';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import { OTPField } from '@frontend/components/inputs/OTPField/OTPField';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import React from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../../form/useForm';
import { useSendConfirmationCode } from './data/useSendConfirmationCode';

type Props = {
  form: UseFormReturn;
  onConfirm: () => void;
};

function ConfirmDepositModal(props: Props) {
  const modalController = ModalService.useModalController();
  const confirmationCode = useSendConfirmationCode();
  const [canResend, setCanResend] = React.useState(false);
  const ref = React.useRef<React.ComponentRef<typeof Countdown>>(null);

  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      highlight={{
        description: 'Atenção',
        size: 'compact',
        variant: 'warning',
      }}
      title="Confirmar depósito"
      subtitle={`Para realizar o depósito precisamos confirmar sua identidade. Insira o código de confirmação enviado para o e-mail ${confirmationCode.email}`}
      children={
        <>
          <Controller
            control={props.form.control}
            name="deposit.confirmationToken"
            render={(context) => (
              <OTPField
                length={6}
                value={context.field.value}
                onChange={context.field.onChange}
                disabled={context.field.disabled}
              />
            )}
          />
          <Spacer y="xs3" />
          <Flex gap="xs4" align="center">
            {canResend ? (
              <>
                <Typography.Body4 color="neutral_40" as="span">
                  Não recebeu seu código?
                </Typography.Body4>
                <LinkButton
                  onClick={() => {
                    void confirmationCode.resend().then(() => {
                      setCanResend(false);
                      ref.current?.reset(confirmationCode.timeRemaining());
                    });
                  }}
                  variant="default"
                >
                  Reenviar
                </LinkButton>
              </>
            ) : (
              <>
                <Typography.Body4 color="neutral_40" as="span">
                  Aguarde o recebimento •
                </Typography.Body4>
                <Countdown
                  onEnd={() => setCanResend(true)}
                  timeInSeconds={30}
                  render={(_, formattedTime) => (
                    <Typography.Body4 color="neutral_20" as="span" weight={700}>
                      {formattedTime}
                    </Typography.Body4>
                  )}
                  ref={ref}
                />
              </>
            )}
          </Flex>
        </>
      }
      buttons={{
        cancel: { text: 'Cancelar', onClick: modalController.remove },
        confirm: {
          text: 'Confirmar',
          onClick: () => {
            props.onConfirm();
            modalController.remove();
          },
          icon: 'IconArrowRight',
          color: 'default',
        },
      }}
    />
  );
}

export default ModalService.create(ConfirmDepositModal);
