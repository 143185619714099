import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Props = {
  multiple: boolean;
  closeModal(): void;
};

export function useCancelRequest({ multiple, closeModal }: Props) {
  const context = trpc.useContext();

  const cancelRequest = trpc.corporateCard.requestBalance.cancel.useMutation({
    onSuccess: () => {
      const { success } = getFeedback(multiple);

      setTimeout(() => {
        void context.corporateCard.requestBalance.search.invalidate();
        void context.corporateCard.requestBalance.countPendingApproval.invalidate();
        dispatchToast({
          type: 'success',
          content: success.content,
          description: success.description,
        });
      }, 2_500);
      closeModal();
    },
    onError: () => {
      const { error } = getFeedback(multiple);
      dispatchToast({
        type: 'error',
        content: error.content,
        description: error.description,
      });
    },
  });

  function cancel(data: { orderId: string; justification: string }) {
    if (!data.justification) {
      dispatchToast({
        type: 'error',
        content: 'Preencha uma justificativa.',
      });
      return;
    }
    cancelRequest.mutate({ id: data.orderId });
  }

  return {
    cancel,
    loading: cancelRequest.isLoading,
  };
}

function getFeedback(multiple: boolean) {
  return {
    success: {
      content: multiple
        ? 'Pedidos de saldo cancelados com sucesso!'
        : 'Pedido de saldo cancelado com sucesso!',
      description: multiple
        ? 'Os solicitantes serão notificados sobre o cancelamento.'
        : 'O solicitante será notificado sobre o cancelamento.',
    },
    error: {
      content: multiple
        ? 'Não foi possível cancelar os pedidos de saldo.'
        : 'Não foi possível cancelar o pedido de saldo.',
      description: 'Por favor, tente novamente dentro de alguns instantes.',
    },
  };
}
