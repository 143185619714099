import SummaryFeedback from '@frontend/components/SummaryFeedback';
import ModalService from '@frontend/services/ModalService';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import SummaryModal from '../../../01-Form/components/SummaryModal/SummaryModal';
import { useDepositSummaryData } from '../../data/useSummaryData';
import { DepositSucceeded } from './DepositSucceeded';

type Props = {
  form: UseFormReturn;
};

export function DepositSummary(props: Props) {
  const data = useDepositSummaryData({
    form: props.form,
  });
  const activatedEmployees = data.selectedPeople;

  function Content() {
    if (data.deposit.status === 'SUCCESS')
      return <DepositSucceeded {...data} />;
    return null;
  }

  return (
    <SummaryFeedback
      icon="IconUsers"
      title={activatedEmployees?.formattedAsText ?? ''}
      onClickViewMore={
        activatedEmployees?.employeeIds
          ? () => {
              ModalService.show(SummaryModal, {
                form: props.form,
              });
            }
          : undefined
      }
    >
      <Content />
    </SummaryFeedback>
  );
}
