import Flex from '../frames/Flex';
import Typography from '../Typography';

type Props = {
  label?: string;
};

export function ErrorCard(props: Props) {
  return (
    <Flex backgroundColor="neutral_95" padding="xs2" radius="m">
      <Typography.Body4 weight={700} color="neutral_30">
        {props.label ?? 'Ocorreu um erro ao carregar as informações'}
      </Typography.Body4>
    </Flex>
  );
}
