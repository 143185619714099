import FormService from '@frontend/services/FormService';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import React, { useContext } from 'react';
import { z } from 'zod';

const automaticCredit = ['automaticCredit', 'automaticCreditFixedValue'];

const automaticCreditSchema = z.object({
  creditType: z.enum(['automaticCredit', 'automaticCreditFixedValue']),
});

const automaticDebitSchema = z.object({
  creditType: z.enum(['automaticDebit']),
  debitDate: z.date(),
});

const schema = z
  .object({
    amount: z.number().positive(),
    creditDate: z.date(),
  })
  .and(automaticCreditSchema.or(automaticDebitSchema));

const EditDepositContext = React.createContext<Context>({
  isLoading: false,
  isAutomaticCredit: false,
  /** Disable ts in initial data in form data */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  form: null,
  fields: {
    amount: 0,
    creditDate: new Date(),
    creditType: 'automaticCredit',
  },
  onSubmit: () => null,
});

type CreditType =
  | 'automaticDebit'
  | 'automaticCredit'
  | 'automaticCreditFixedValue';

type Deposit = {
  id: string;
  employeeId: string;
};

export type EditDepositModalProps = {
  creditType: CreditType;
  creditDate: Date;
  debitDate?: Date;
  amount: number;
  deposits: Deposit[];
  onSuccess?: VoidFunction;
};

type Props = {
  children: React.ReactNode;
} & EditDepositModalProps;

type Form = ReturnType<typeof FormService.useCreateForm<typeof schema>>;

type Context = {
  isLoading: boolean;
  isAutomaticCredit: boolean;
  form: Form['control'];
  fields: z.infer<typeof schema>;
  getValue: Form['getValues'];
  onSubmit: VoidFunction;
};

export function EditAutomaticCreditProvider(props: Props) {
  const controller = ModalService.useModalController();
  const form = FormService.useCreateForm(schema, {
    defaultValues: {
      amount: props.amount,
      creditDate: props.creditDate,
      debitDate: props.debitDate,
      creditType: props.creditType,
    },
  });
  const fetch = trpc.company.automaticCredit.update.useMutation();

  async function serializeDeposit(): Promise<
    Parameters<typeof fetch.mutateAsync>[0]
  > {
    await form.trigger();

    const data = form.getValues();

    return {
      employeeDepositIdList: props.deposits.map((employee) => employee.id),
      amount: data.amount,
      availabilityDate: data.creditDate,
      automaticCreditType:
        data.creditType === 'automaticCreditFixedValue'
          ? 'fixedValue'
          : 'fixedDate',
    };
  }

  async function onSubmit() {
    const data = await serializeDeposit();

    void fetch
      .mutateAsync(data, {
        onSuccess: () => {
          controller.remove();
          dispatchToast({
            type: 'success',
            content: 'Depósito editado com sucesso!',
          });
          props.onSuccess?.();
        },
        onError: (data) => {
          dispatchToast({
            type: 'error',
            content: data.message ?? 'Não foi possível editar o depósito',
            description: data.message
              ? undefined
              : 'Por favor, tente novamente dentro de alguns instantes.',
          });
        },
      })
      .catch(() => null);
  }

  return (
    <EditDepositContext.Provider
      value={{
        isLoading: fetch.isLoading,
        isAutomaticCredit: automaticCredit.includes(props.creditType),
        form: form.control,
        fields: form.watch(),
        getValue: form.getValues,
        onSubmit: () => void onSubmit(),
      }}
    >
      {props.children}
    </EditDepositContext.Provider>
  );
}

export function useEditDepositContext() {
  return useContext(EditDepositContext);
}
