import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { DepositSummary } from '../DepositSummary/DepositSummary';
import { PaymentSummary } from '../PaymentSummary/PaymentSummary';

type Props = {
  form: UseFormReturn;
};

export function Success(props: Props) {
  return (
    <>
      <Flex direction="column" align="center">
        <Icon
          name="IconCheck"
          background="success"
          color="success_40"
          size={48}
        />
        <Spacer y="xs4" />
        <Typography.Headline8 center>Depósito confirmado!</Typography.Headline8>
        <Spacer y="xs4" />
        <Typography.Body4 center color="neutral_40">
          Você pode conferir todos os depósitos feitos em Extratos.
        </Typography.Body4>
        <Spacer y="m" />
        <DepositSummary form={props.form} />
        <Spacer y="m" />
        <PaymentSummary form={props.form} />
      </Flex>
      <Spacer y="m" />
      <SurveyStore
        title="Como você avalia a facilidade de uso do depósito via planilha?"
        type="create-deposits-from-spreadsheet"
      />
    </>
  );
}
