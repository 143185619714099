import { TextField, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import { onlyNumbers, toCurrency } from '@frontend/utils/masks';
import { CardJSON } from 'backend/src/externalServices/ExpenseManagementService/users/schemas/card.json-schema';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Box } from '../../styled';
import { useRefundBalance } from './data/useRefundBalance';

type Props = {
  allSelected?: boolean;
  callback?: () => void;
  filter: {
    active?: Array<'active' | 'disabled'>;
    balance?: {
      max: number;
      min: number;
    };
    behavior?: 'include' | 'exclude';
    cards?: {
      status?: Array<CardJSON['status']>;
    };
    employeeId?: Array<string>;
    stringInput?: string;
  };
  total: number;
};

function RefundUserBalanceModal({ callback, ...input }: Props) {
  const modalController = ModalService.useModalController();

  const [retry, setRetry] = useState(true);

  const { form, handleSubmit, loading, userBalance } = useRefundBalance(
    input,
    callback,
  );

  const { total } = input;

  const modalData =
    total > 1
      ? {
          inputText: 'Valor da retirada de cada Pessoa',
          switchText:
            'Se ativado, os saldos das Pessoas serão zerados e os valores retornarão em um instante para o Flash Cash Corporativo.',
          footerInfo:
            'Caso o valor inserido seja maior que o saldo de alguma das Pessoas selecionadas, o seu saldo ficará zerado. ',
        }
      : {
          inputText: 'Valor da retirada',
          switchText:
            'Se ativado, o saldo da Pessoa será zerado e o valor retornará para o Flash Cash Corporativo.',
          footerInfo: '',
        };

  return (
    <DefaultModal
      buttons={{
        confirm: {
          text: 'Confirmar',
          onClick: () => {
            setRetry(false);
            handleSubmit();
          },
          disabled: !loading && !retry,
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      loading={loading}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      title="Retirar saldo corporativo"
      subtitle="O saldo que for retirado irá retornar para Flash Cash e poderá ser utilizado em outras recargas futuras."
      children={
        <>
          <Typography.Body3 color="neutral_30" weight={700}>
            Valor a ser retirado
          </Typography.Body3>
          <Spacer y="xs" />
          {total === 1 && (
            <Typography.Body3 color="neutral_50">
              A Pessoa possui&nbsp;
              <Typography.Body3
                style={{ display: 'unset' }}
                color="neutral_50"
                weight={700}
              >
                {userBalance ? toCurrency(userBalance) : '-'}
              </Typography.Body3>
              &nbsp;no Flash Cash Corporativo
            </Typography.Body3>
          )}
          <Spacer y="xs" />
          <Controller
            name="returnAllBalance"
            control={form.control}
            render={(props) => (
              <Box>
                <Toggle
                  value={props.field.value}
                  onChange={(_, value) => {
                    if (value) {
                      form.resetField('amountToReturn');
                    }
                    props.field.onChange(value);
                    setRetry(true);
                  }}
                />
                <Spacer x="xs" />
                <div>
                  <Typography.Body3 weight={600} color="neutral_30">
                    Retirar todo o saldo
                  </Typography.Body3>
                  <Typography.Body4 color="neutral_50" weight={400}>
                    {modalData.switchText}
                  </Typography.Body4>
                </div>
              </Box>
            )}
          />
          <Spacer y="xs" />
          <Controller
            name="amountToReturn"
            control={form.control}
            render={(props) => (
              <TextField
                disabled={form.watch('returnAllBalance')}
                name="amountToReturn"
                style={{ width: '100%' }}
                value={
                  props.field.value !== undefined
                    ? toCurrency(props.field.value)
                    : ''
                }
                onChange={({ target }) => {
                  const numberTarget = onlyNumbers(
                    target.value.replace(',', ''),
                  );
                  const onlyNumbersTarget = numberTarget.replace(/\.|\,/, '');
                  props.field.onChange(
                    parseInt(onlyNumbersTarget ? onlyNumbersTarget : '0'),
                  );
                  setRetry(true);
                }}
                label={modalData.inputText}
                error={!!props.formState.errors.amountToReturn}
                helperText={
                  props.formState.errors.amountToReturn?.message || ''
                }
              />
            )}
          />
          <Spacer y="xs5" />
          <Typography.Body4 color="neutral_50" weight={400}>
            {modalData.footerInfo}
          </Typography.Body4>
        </>
      }
    />
  );
}

export default ModalService.create(RefundUserBalanceModal);
