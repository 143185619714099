import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';

type Props = {
  continueWithoutDeposit: () => void;
};

function InvalidActivationWithDepositModal(props: Props) {
  const modalController = ModalService.useModalController();
  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      highlight={{
        description: 'Atenção',
        size: 'compact',
        variant: 'warning',
      }}
      title="Você não preencheu todos os campos necessários para o depósito"
      subtitle="Você pode voltar e preencher as informações ou seguir com a ativação sem fazer o depósito."
      children={<></>}
      buttons={{
        cancel: { text: 'Voltar', onClick: modalController.remove },
        confirm: {
          text: 'Ativar sem depósito',
          onClick: () => {
            props.continueWithoutDeposit();
            modalController.remove();
          },
          icon: 'IconArrowRight',
          color: 'error',
        },
      }}
    />
  );
}

export default ModalService.create(InvalidActivationWithDepositModal);
