import { cpfMask } from '@flash-tecnologia/corporate-card-bff/src/utils/masks/formatCPF';
import { RouterInputs, trpc } from '@frontend/trpc';

type Props = {
  enabled?: boolean;
};

/** Returns a list of employees as options for the ComboBox input */
export function useGetEmployees(props?: Props) {
  const context = trpc.useContext();

  const getEmployees = trpc.corporateCard.employees.search.useQuery(
    { activeOnly: true },
    {
      enabled: props?.enabled,
      staleTime: 0,
      cacheTime: 0,

      structuralSharing(oldData, newData) {
        if (!oldData) {
          return newData;
        }

        if (oldData.length === newData.length) {
          return newData;
        }

        const copyOldData = Array.from(oldData);

        const data = [
          ...newData.map((data) => {
            const index = copyOldData.findIndex(
              (selectedOption) => selectedOption.id === data.id,
            );

            const [deletedValue] =
              index > -1 ? copyOldData.splice(index, 1) : [{ selected: false }];

            return {
              ...data,
              selected: deletedValue.selected || data.selected,
            };
          }),
          ...copyOldData,
        ];

        return data;
      },
    },
  );

  const fetch = (input: RouterInputs['corporateCard']['employees']['search']) =>
    context.corporateCard.employees.search.fetch(
      { ...input, activeOnly: true },
      {
        structuralSharing: (_, newData) => {
          context.corporateCard.employees.search.setData(
            { activeOnly: true },
            (oldData) => {
              const selectedOptions =
                oldData?.filter((data) => data.selected) || [];

              const data = [
                ...newData.map((data) => {
                  const index = selectedOptions?.findIndex(
                    (selectedOption) => selectedOption.id === data.id,
                  );

                  if (index >= 0) {
                    selectedOptions.splice(index, 1);
                  }

                  return {
                    ...data,
                    selected: index >= 0,
                  };
                }),
                ...selectedOptions,
              ];

              return data;
            },
          );

          return newData;
        },
      },
    );

  return {
    isError: getEmployees.isError,
    loading: getEmployees.isLoading,
    fetch,
    refresh: getEmployees.refetch,
    employees:
      getEmployees.data?.map((user) => ({
        /** Employee's name */
        title: user.name,
        /** Employee's nanoID */
        value: user.id,
        /** Employee's document number */
        description: cpfMask(user.documentNumber),
        /** Employee's raw document number */
        additionalFilterKey: user.documentNumber,
        /** Disables selection of the employee if already active */
        disabled: false,
        /** whether employee is selected or not */
        selected: user.selected,
      })) ?? [],
  };
}
