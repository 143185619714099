import { trpc } from '@frontend/utils/trpc';

export default function useGetPlasticCards() {
  const plasticCards = trpc.employee.flashCards.plastic.getAll.useQuery();

  return {
    items: plasticCards.data?.cards ?? [],
    isError: plasticCards.isError,
    isLoading: plasticCards.isLoading,
    refetch: plasticCards.refetch,
  };
}

export type UseGetPlasticCardsReturn = ReturnType<typeof useGetPlasticCards>;
