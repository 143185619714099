import { trpc } from '@frontend/trpc';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import ModalService from '@frontend/services/ModalService';
import UpdateCardNameModal from '../modals/UpdateCardNameModal';

type Input = {
  cardId: string;
  password: string;
  cardName: string;
};

export default function useUpdateCardName() {
  const context = trpc.useContext();
  const patch = trpc.employee.flashCards.virtual.patch.useMutation({
    onMutate: toastStart,
    onError: toastError,
    onSuccess(data, { cardId, cardName }) {
      // Mutation failed
      if (!data.success) {
        switch (data.code) {
          case 'INVALID_TRANSACTION_PASSWORD':
            toastWrongPassword();
            break;
          case 'TOO_MANY_PASSWORD_ATTEMPTS':
            toastTooManyAttempts();
            ModalService.hide(UpdateCardNameModal);
            break;
          default:
            toastError();
        }
        return;
      }

      // Mutation succeeded
      toastSuccess();

      // Optimistic update
      context.employee.flashCards.virtual.getAll.setData(
        undefined,
        (oldData) => {
          if (!oldData || !cardName) return undefined;
          return {
            name: oldData.name,
            cards:
              oldData?.cards.map((card) => {
                if (card.cardId === cardId) {
                  return {
                    ...card,
                    cardName,
                  };
                }
                return card;
              }) ?? [],
          };
        },
      );

      // Waits until the update event propagates to em-service
      setTimeout(() => {
        context.employee.flashCards.virtual.getAll.invalidate();
      }, 10000);

      ModalService.hide(UpdateCardNameModal);
    },
  });

  return {
    mutateAsync: (input: Input) => patch.mutateAsync(input),
    isLoading: patch.isLoading,
  };
}

function toastStart() {
  dispatchToast({
    type: 'warning',
    content: 'Alterando apelido do cartão...',
  });
}

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Apelido alterado com sucesso!',
  });
}

function toastError() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível alterar o apelido do cartão.',
    description: errors.actions.toastDescription,
  });
}

function toastWrongPassword() {
  dispatchToast({
    type: 'error',
    content: errors.cards.password.wrongPasswordToast.content,
  });
}

function toastTooManyAttempts() {
  dispatchToast({
    type: 'error',
    content: errors.cards.password.tooManyAttemptsToast.content,
    description: errors.cards.password.tooManyAttemptsToast.description,
  });
}
