import FeatureFlagService from '@frontend/services/FeatureFlagService';
import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { sortedMccGroups } from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { RouterOutputs } from 'backend';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { z } from 'zod';
import useCreatePolicy from '../data/useCreatePolicy';
import { FormSchema, PolicyLimit } from './schema';
import { toasts } from './toasts';

export type FormSchemaOutput = z.output<typeof FormSchema>;
export type LimitSchema = z.output<typeof PolicyLimit>;

type Input = {
  /** Callback for the submit success */
  onSuccess: (policy: RouterOutputs['company']['policies']['create']) => void;
};

export default function useForm(input: Input) {
  const limitByCategoryEnabled = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );
  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    defaultValues: {
      limitEnabled: false,
      mccGroups: {
        CONVENIENCE: true,
        CULTURE: true,
        EDUCATION: true,
        GROCERY: true,
        HEALTH: true,
        MEAL: true,
        MOBILITY: true,
      },
      weekdaysEnabled: {
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
      },
      withdrawEnabled: false,
      limitType: 'UNLIMITED',
    },
  });

  /* --------------------- Additional schema validations -------------------- */
  const mccGroups = form.watch('mccGroups');
  const mccLimits = form.watch('mccLimits') || {};
  const withdrawEnabled = form.watch('withdrawEnabled');
  const limitType = form.watch('limitType');
  const limitEnabled = form.watch('limitEnabled');

  React.useEffect(() => {
    if (limitByCategoryEnabled) {
      sortedMccGroups.map((mcc) => {
        if (!mccGroups[mcc]) {
          form.unregister(`mccLimits.${mcc}`);
        }
      });
    }
    void form.trigger();
  }, [mccGroups]);

  React.useEffect(() => {
    if (limitByCategoryEnabled)
      if (!withdrawEnabled) {
        form.unregister('mccLimits.WITHDRAW');
      } else if (!mccLimits['WITHDRAW']) {
        form.resetField('mccLimits.WITHDRAW.amount');
        form.resetField('mccLimits.WITHDRAW.period');
      }
    void form.trigger();
  }, [withdrawEnabled]);

  React.useEffect(() => {
    switch (limitType) {
      case 'GENERAL':
        form.setValue('limitEnabled', true);
        limitByCategoryEnabled && form.unregister('mccLimits');
        break;

      case 'BY_CATEGORY':
        form.unregister('limit');
        form.setValue('limitEnabled', true);
        break;

      case 'UNLIMITED':
        form.setValue('limitEnabled', false);
        form.unregister('limit');
        limitByCategoryEnabled && form.unregister('mccLimits');
        break;
    }

    void form.trigger();
  }, [limitType]);

  React.useEffect(() => {
    if (limitEnabled) {
      if (limitType) {
      }
      form.resetField('limit.amount');
      form.resetField('limit.period');
    } else {
      form.unregister('limit');
    }
  }, [limitEnabled]);

  /* ------------------------ Schema validation check ----------------------- */
  const validSchema = form.formState.isValid && _isEmpty(form.formState.errors);

  /* ------------------------------ Form submit ----------------------------- */
  const createPolicy = useCreatePolicy();

  const onSubmit = form.handleSubmit(
    (values) => {
      void createPolicy.mutateAsync(values).then((result) => {
        if (result) {
          input.onSuccess(result);
        }
      });
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating policy form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isSubmitting: form.formState.isSubmitting || createPolicy.isLoading,
    onSubmit,
    result: createPolicy.data,
    validSchema,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
