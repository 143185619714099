import { colors, ColorToken } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  useNewVersion?: boolean;
  background?: ColorToken;
}>`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs}`};
  background-color: ${({ useNewVersion, background }) =>
    background
      ? colors.get(background)
      : colors.get(useNewVersion ? 'secondary.99' : 'neutral.95')};

  ${({ theme, background, useNewVersion }) =>
    !background &&
    css`
      border-width: 1px 0px;
      border-color: ${useNewVersion
        ? theme.colors.secondary[95]
        : theme.colors.neutral[90]};
    `};
`;

export const IconContainer = styled.div<{ background: ColorToken }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => colors.get(background)};
  border-radius: 50%;
`;

export const ActionContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;
