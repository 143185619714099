import {
  Button,
  Icons,
  PageContainer,
  PageHeader,
  Tab,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { VersionCallout } from '@frontend/components/VersionCallout';
import AddFlashCashModal from '@frontend/external/DashboardSection/add-flash-cash-modal';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { useTabPage } from '@frontend/utils/hooks/useTabPage';
import { Outlet } from 'react-router-dom';
import ModalService from '../../services/ModalService';
import AddBalance from './tabs/FlashCard/focusedFlows/AddBalance/AddBalance';
import { RequestBalanceTab } from './tabs/RequestBalanceSection';

const BASE_PATH = '/corporateCard/management';
const AVAILABLE_TABS = ['deposits', 'balanceRequests'];

export default function Management() {
  const { currentTab, setParams } = useTabPage(BASE_PATH, AVAILABLE_TABS);
  const requestBalanceTab = RequestBalanceTab();

  const isAddFlashCashV2Enabled = FeatureFlagService.getFlag('addFlashCashV2');

  const AddBalanceButton = (
    <Button
      variant="primary"
      size="large"
      onClick={() => {
        ModalService.show(
          isAddFlashCashV2Enabled ? AddFlashCashModal : AddBalance,
          {},
        );
      }}
    >
      Adicionar crédito
      <Icons name="IconCash" fill="none" />
    </Button>
  );

  return (
    <>
      <VersionCallout.New feature="accountManagement" />

      <PageHeader style={{ justifyContent: 'space-between' }}>
        <Typography variant="headline6">Saldo corporativo</Typography>
        {AddBalanceButton}
      </PageHeader>
      <PageContainer>
        <Tab
          defaultTab={currentTab}
          onTabChanged={setParams}
          tabItens={[
            {
              label: 'Depósitos',
              component: <Outlet />,
            },
            {
              label: requestBalanceTab.label,
              component: requestBalanceTab.component,
              disabled: !requestBalanceTab.shouldShow,
            },
          ]}
        />
      </PageContainer>
    </>
  );
}
