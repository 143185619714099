import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import formatCardNumber from '@frontend/utils/masks/formatCardNumber';
import { DateTime } from 'luxon';
import React from 'react';
import CardStatusTag from '../../../components/CardStatusTag';
import { UseGetVirtualCardsReturn } from '../data/useGetVirtualCards';
import useMenuActions from './useMenuActions';

type Row = UseGetVirtualCardsReturn['data']['cards'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'provider',
    header: 'Emissora',
    enableGlobalFilter: false,
    enableSorting: false,
    cell: () => <CardProviderChip provider="FLASH" variant="color" />,
  },
  {
    accessorFn: (card) => card.sensitiveData?.cardNumber ?? card.lastFourDigits,
    header: 'Cartão',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.sensitiveData
          ? formatCardNumber(context.row.original.sensitiveData.cardNumber)
          : `––– ${context.row.original.lastFourDigits}`}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'cardName',
    header: 'Apelido do cartão',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.cardName}
      </Typography.Body4>
    ),
  },
  {
    accessorFn: (row) =>
      row.sensitiveData?.expirationDate
        ? DateTime.fromFormat(
            row.sensitiveData?.expirationDate,
            'MM/yy',
          ).toJSDate()
        : undefined,
    sortingFn: 'datetime',
    header: 'Validade',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.sensitiveData?.expirationDate ?? '–––'}
      </Typography.Body4>
    ),
  },
  {
    accessorFn: (row) => row.sensitiveData?.cvv,
    header: 'CVV',
    enableGlobalFilter: false,
    enableSorting: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.sensitiveData?.cvv ?? '–––'}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'balanceType',
    header: 'Saldo Associado',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.balanceType
          .map((type: keyof typeof balanceTypeMap) => balanceTypeMap[type])
          .join(', ')}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableGlobalFilter: false,
    cell: (context) => <CardStatusTag status={context.row.original.status} />,
  },
  {
    id: 'actions',
    header: 'Ações',
    isSticky: true,
    cell: (context) => {
      const actions = useMenuActions();

      return (
        <Menu type="default" locked options={actions(context.row.original)}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;

const balanceTypeMap = {
  BENEFITS: 'Benefícios',
  CORPORATE: 'Corporativo',
};
