import { Feature, VersionContent } from './types';

export const VERSIONS_CONTENT: Record<Feature, VersionContent> = {
  accountManagement: {
    featureName: 'Cartão corporativo',
    survey: {
      title:
        'Como você avalia a nova experiência da nova experiência de Cartão corporativo?',
      type: 'account-management-v2-feedback',
    },
    lifecycle: {
      discontinuationDate: new Date('2025-03-21T03:00'),
      timeline: [new Date('2025-02-27T03:00'), new Date('2025-03-10T03:00')],
    },
  },
};
