import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import PolicyDrawer from '@frontend/external/CardsSection/drawers/PolicyDrawer';
import { useCheckIsCorporateCard } from '@frontend/pages/Statement/data/useCheckIsCorporateCard';
import ModalService from '@frontend/services/ModalService';
import conditionalList from '@frontend/utils/conditionalList';
import { useNavigate } from 'react-router-dom';
import { UseGetVirtualCardsReturn } from '../data/useGetVirtualCards';
import UpdateCardNameModal from '../modals/UpdateCardNameModal';
import UpdateCardStatusModal from '../modals/UpdateCardStatusModal';

type Item = UseGetVirtualCardsReturn['data']['cards'][number];

export default function useMenuActions() {
  const navigate = useNavigate();
  const { isCorporateCardUser } = useCheckIsCorporateCard();

  return function actions(
    item: Item,
  ): React.ComponentProps<typeof Menu>['options'] {
    return conditionalList([
      [
        item.status === 'ENABLED',
        {
          label: 'Bloquear cartão',
          onClick: () =>
            ModalService.show(UpdateCardStatusModal, {
              cardId: item.cardId,
              newStatus: 'BLOCKED',
            }),
        },
      ],
      [
        item.status === 'BLOCKED',
        {
          label: 'Desbloquear cartão',
          onClick: () =>
            ModalService.show(UpdateCardStatusModal, {
              cardId: item.cardId,
              newStatus: 'ENABLED',
            }),
        },
      ],
      [
        true,
        {
          label: 'Editar apelido',
          onClick: () =>
            ModalService.show(UpdateCardNameModal, {
              currentCardName: item.cardName,
              cardId: item.cardId,
            }),
        },
      ],
      [
        true,
        {
          label: 'Ver extrato',
          onClick: () => navigate('/corporateCard/statement/corporateCard'),
        },
      ],
      [
        !!item.policy && isCorporateCardUser,
        {
          label: 'Ver política de uso corporativo',
          onClick: () =>
            ModalService.show(PolicyDrawer, {
              card: { ...item, policy: item.policy! },
            }),
        },
      ],
    ]);
  };
}
