import Icon from '@frontend/components/Icon';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import * as SC from './styled';

function InvalidDepositModal() {
  const modalController = ModalService.useModalController();
  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      highlight={{
        description: 'Atenção',
        size: 'compact',
        variant: 'warning',
      }}
      title="Você não preencheu todos os campos necessários para o depósito"
      subtitle={
        <>
          Confira se você preencheu as informações a seguir e tente novamente:
          <br />
          • Valor do depósito
          <br />
          • Data de depósito
          <br />• Forma de pagamento
        </>
      }
    >
      <SC.StyledButton
        size="medium"
        variant="primary"
        color="error"
        onClick={() => modalController.remove()}
      >
        <Icon color="error_10" name="IconArrowLeft" /> Voltar e conferir
        informações
      </SC.StyledButton>
    </DefaultModal>
  );
}

export default ModalService.create(InvalidDepositModal);
