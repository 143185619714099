import styled from 'styled-components';

/** Top accent strip on card */
export const CardBackgroundAccent = styled.div<{
  /** The accent color is based on the business unit.
   * Light for benefits, dark for corporate */
  bu: 'BENEFITS' | 'EXPENSE_MANAGEMENT';
}>`
  background-color: ${({ theme, bu }) =>
    bu === 'BENEFITS'
      ? theme.colors.secondary[90]
      : theme.colors.secondary[30]};
  height: 256px;
  width: 423px;
  padding-top: 5px;
  border-radius: ${({ theme }) => theme.spacings.xs3};
`;

/** White card background with gray border */
export const CardBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-top: none;
`;

/** Pink pill that hides sensitive information */
export const NoVisible = styled.span<{
  width: number;
}>`
  width: ${(props) => props.width}px;
  height: 12px;
  background: ${({ theme }) => theme.colors.secondary[90]};
  border-radius: 6px;
`;
