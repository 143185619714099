import { useNavigate } from 'react-router-dom';
import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import conditionalList from '@frontend/utils/conditionalList';

export default function useMenuActions() {
  const navigate = useNavigate();

  return function actions(): React.ComponentProps<typeof Menu>['options'] {
    return conditionalList([
      [
        true,
        {
          label: 'Ver extrato',
          onClick: () => navigate('/corporateCard/statement/externalCards'),
        },
      ],
    ]);
  };
}
