import Flex from '@frontend/components/frames/Flex';
import BalanceField from '@frontend/components/inputs/BalanceField';
import SelectField from '@frontend/components/inputs/SelectField';
import ToggleField from '@frontend/components/inputs/ToggleField';
import { translatedPeriodTypes } from '@frontend/pages/Policies/shared/mappers';
import { PeriodTypeEnum } from 'backend/src/utils/periodTypeEnum';
import { Controller } from 'react-hook-form';
import { LimitSchema, UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function LimitSwitch(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="limitEnabled"
      render={(fieldProps) => (
        <ToggleField
          title="Limite de gastos"
          description="Você pode definir um valor máximo que um cartão corporativo poderá utilizar em um período (diário, semanal ou mensal)."
          value={fieldProps.field.value}
          onChange={(event) => {
            fieldProps.field.onChange(event);
            if (event.valueOf()) {
              props.form.setValue('limitType', 'GENERAL');
            } else {
              props.form.setValue('limitType', 'UNLIMITED');
            }
          }}
        >
          {fieldProps.field.value && (
            <Flex justify="space-between" gap="xs">
              <Controller
                control={props.form.control}
                name="limit.amount"
                render={({ field, fieldState }) => (
                  <BalanceField
                    fullWidth
                    label="Limite de gastos"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                control={props.form.control}
                name="limit.period"
                render={({ field, fieldState }) => (
                  <SelectField<LimitSchema['period']>
                    fullWidth
                    label="Período"
                    value={field.value}
                    onChange={field.onChange}
                    options={Object.values(PeriodTypeEnum).map(
                      (periodType) => ({
                        label: translatedPeriodTypes[periodType],
                        value: periodType,
                      }),
                    )}
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Flex>
          )}
        </ToggleField>
      )}
    />
  );
}
