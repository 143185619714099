import Button from '@frontend/components/Button';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { useDepositSummaryData } from '../../data/useSummaryData';

export function DepositFailed(data: ReturnType<typeof useDepositSummaryData>) {
  return (
    <Flex padding={'xs2'} gap="xs5" direction="column">
      <Flex align="center" gap="xs4">
        <Tag variant="error" size="xs">
          <Flex align="center" gap="xs5">
            <Icon name="IconAlertTriangle" color="error_10" size={14} />
            Erro no depósito
          </Flex>
        </Tag>
        <Typography.Body4 color="neutral_20" weight={600}>
          Não foi possível agendar o depósito
        </Typography.Body4>
      </Flex>
      <Flex align="center" gap="xs4" direction="row" justify="space-between">
        <Typography.Caption color="neutral_40">
          Tivemos um erro ao processar as informações do depósito. Por favor,
          tente novamente.
        </Typography.Caption>
        <Button
          variant="secondary"
          color="error"
          onClick={data.goToCreateDepositFlow}
          icon="IconArrowRight"
          size="small"
        >
          Depositar
        </Button>
      </Flex>
    </Flex>
  );
}
