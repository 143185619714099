import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import formatCardOwnerName from '@frontend/utils/masks/formatCardOwnerName';
import { Card } from '../../components/Card';
import * as styles from '../../styled';
import type { UseGetVirtualCardsReturn } from './data/useGetVirtualCards';
import VirtualCardsTable from './table';
import useMenuActions from './table/useMenuActions';

type Props = {
  virtualCards: UseGetVirtualCardsReturn;
  view: 'grid' | 'list';
};

export default function VirtualCardsSection(props: Props) {
  const actions = useMenuActions();

  if (!props.virtualCards.data.cards.length) return null;

  function handleToggleVisibility() {
    props.virtualCards.toggleSensitiveData(
      !props.virtualCards.sensitiveDataAvailable,
    );
  }

  return (
    <>
      <styles.Wrapper>
        <Typography.Headline8>Virtual</Typography.Headline8>
        <Spacer x="xs4" />
        <PillButton
          size="small"
          variant="default"
          onClick={handleToggleVisibility}
          loading={props.virtualCards.isLoadingSensitiveData}
          icon={
            props.virtualCards.sensitiveDataAvailable ? 'IconEye' : 'IconEyeOff'
          }
        />
      </styles.Wrapper>
      <Spacer y="xs" />
      {props.view === 'grid' ? (
        <styles.HorizontalList>
          {props.virtualCards.data.cards.map((item) => (
            <Spacer key={item.cardId} useMargin x="xs">
              <Card
                balances={new Set(item.balanceType)}
                cardName={item.cardName}
                cardNumber={
                  props.virtualCards.sensitiveDataAvailable &&
                  item.sensitiveData?.cardNumber
                    ? item.sensitiveData.cardNumber
                    : item.lastFourDigits
                }
                copyToClipboard={copyToClipboard}
                cvv={item.sensitiveData?.cvv ?? ''}
                dueDate={item.sensitiveData?.expirationDate ?? ''}
                isVirtual
                menuOptions={actions(item)}
                ownerName={formatCardOwnerName(
                  props.virtualCards.data.employee.name ?? '',
                )}
                policy={item.policy}
                provider={'FLASH'}
                status={item.status}
                visible={props.virtualCards.sensitiveDataAvailable}
              />
            </Spacer>
          ))}
        </styles.HorizontalList>
      ) : (
        <VirtualCardsTable
          loading={props.virtualCards.isLoading}
          data={props.virtualCards.data.cards}
        />
      )}
      <Spacer y="l" />
    </>
  );
}

const copyToClipboard = (cardNumber: string) => {
  navigator.clipboard.writeText(cardNumber);
  dispatchToast({
    type: 'success',
    content: 'Copiado para área de transferência!',
  });
};
