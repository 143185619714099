import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { METADATA_UserAccounts, ROUTE_UserAccounts } from '../..';
import { useFlowController } from './controllers/useFlowController';

type Props = {
  isOnboardingFlow?: boolean;
  requiredDeposit?: boolean;
  onActionClick?(this: void): void;
  onClose?(this: void): void;
};

function ActivateUsers(props: Props) {
  const flowController = useFlowController({
    requiredDeposit: props?.requiredDeposit,
    onActionClick: props?.onActionClick,
    onClose: props?.onClose,
    isOnboardingFlow: props?.isOnboardingFlow,
  });
  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: METADATA_UserAccounts.title,
            link: ROUTE_UserAccounts.buildPath({}),
          },
          {
            text: 'Ativar uso corporativo',
          },
        ],
      }}
      content={flowController.content}
      footer={{
        leftActions: flowController.leftButtons,
        rightActions: flowController.rightButtons,
      }}
    />
  );
}

export const MODAL_ActivateUsers = ModalService.create(ActivateUsers);
