import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { ReactNode } from 'react';

import { RequireAtLeastOne } from 'type-fest';
import * as SC from './styled';
import SummaryFeedbackContent, {
  SummaryFeedbackContentProps,
} from './SummaryFeedbackContent';
import { SummaryHeader } from './SummaryHeader';

export type SummaryFeedbackProps = RequireAtLeastOne<
  {
    title: string;
    icon?: IconTypes;
    onClickViewMore?: () => void;
    content?: SummaryFeedbackContentProps;
    headerGridTemplateColumns?: string;
    children?: ReactNode;
  },
  'children' | 'content'
>;

export default function SummaryFeedback(props: SummaryFeedbackProps) {
  return (
    <SC.Container>
      <SummaryHeader
        icon={props.icon}
        title={props.title}
        onClickViewMore={props.onClickViewMore}
        headerGridTemplateColumns={props.headerGridTemplateColumns}
      />

      {props.content ? (
        <SummaryFeedbackContent {...props.content} />
      ) : (
        props.children
      )}
    </SC.Container>
  );
}
