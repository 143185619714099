import { Icons, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import EmptyTableSVG from '../../assets/table/empty_table.svg';
import Spacer from '../../components/Spacer';
import Typography from '../../components/Typography';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const WrapperSpaceBetween = styled(Wrapper)`
  justify-content: space-between;
`;

export const CenterText = styled(Typography.Body3)`
  text-align: center;
`;

const StyledSwitchView = styled.div`
  display: flex;
  cursor: pointer;
`;

const SwitchViewLeft = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 40px;

  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-right: 0.5px solid ${({ theme }) => theme.colors.neutral[80]};
  border-top-left-radius: 58px;
  border-bottom-left-radius: 58px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const SwitchViewRight = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 40px;

  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-left: 0.5px solid ${({ theme }) => theme.colors.neutral[80]};
  border-top-right-radius: 58px;
  border-bottom-right-radius: 58px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface SwitchViewProps {
  onClick?: Dispatch<SetStateAction<'grid' | 'list' | undefined>>;
  option?: 'list' | 'grid';
}

export function SwitchView({ onClick, option }: SwitchViewProps) {
  const theme = useTheme();

  return (
    <StyledSwitchView>
      <SwitchViewLeft
        onClick={() => {
          onClick?.('list');
        }}
      >
        <Icons
          name="IconList"
          color={
            option === 'list'
              ? theme.colors.secondary[50]
              : theme.colors.neutral[50]
          }
        />
      </SwitchViewLeft>
      <SwitchViewRight
        onClick={() => {
          onClick?.('grid');
        }}
      >
        <Icons
          name="IconLayoutGrid"
          color={
            option === 'grid'
              ? theme.colors.secondary[50]
              : theme.colors.neutral[50]
          }
          fill="transparent"
        />
      </SwitchViewRight>
    </StyledSwitchView>
  );
}

export const CardBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;

  width: 423px;
  height: 250px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`;

export const HorizontalList = styled.div`
  display: flex;
  width: 100%;
  height: 265px;
  overflow-x: auto;
`;

const EmptyTable = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface StatusBoxProps {
  error?: boolean;
  loading: boolean;
  title: string;
  subTitle: string;
}

export function StatusBox({ error, loading, title, subTitle }: StatusBoxProps) {
  const theme = useTheme();
  return (
    <EmptyTable>
      {loading ? (
        <Spinner variant="primary" size={48} />
      ) : (
        <>
          {!error ? (
            <EmptyTableSVG />
          ) : (
            <Icons
              color={theme.colors.feedback.error[40]}
              fill={theme.colors.feedback.error[90]}
              size={60}
              name="IconAlertCircle"
            />
          )}
          <Typography.Body3 weight={600}>{title}</Typography.Body3>
          <Spacer y="xs" />
          <Typography.Caption>{subTitle}</Typography.Caption>
        </>
      )}
    </EmptyTable>
  );
}

interface NoWrapProps {
  width?: number;
}

export const NoWrap = styled.div<NoWrapProps>`
  white-space: nowrap;
  ${({ width }) => width !== undefined && `width: ${width}px`}
`;
