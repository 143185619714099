import columns from './columns';
import TableClient from '@frontend/components/TableClient';
import type { UseGetExternalCardsOutput } from '../data/useGetExternalCards';

type Props = {
  loading: boolean;
  data: UseGetExternalCardsOutput['items'];
};

export default function ExternalCardsTable(props: Props) {
  return (
    <TableClient
      loading={props.loading}
      emptyState={{
        emptyText: '',
        filteredEmptyText: '',
      }}
      columns={columns}
      data={props.data}
    />
  );
}
