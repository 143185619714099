import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../../services/ModalService';
import { toCurrency } from '../../../../../utils/masks';
import { useCancelDeposit } from './data/useCancelDeposit';

type AutomaticCredit = {
  date: Date;
  amount: number;
  type: string;
  employeeDepositIdList: string[];
};

export type CancelAutomaticCreditProps = {
  automaticCreditMetadata: AutomaticCredit;
  onCancel?: () => void;
};

function CancelAutomaticCreditFeedbackModal(props: CancelAutomaticCreditProps) {
  const modalController = ModalService.useModalController();
  const { loading, cancelDeposit } = useCancelDeposit();

  const body = `A Recarga Automática no valor de ${
    props.automaticCreditMetadata &&
    toCurrency(props.automaticCreditMetadata.amount)
  } ${
    props.automaticCreditMetadata.employeeDepositIdList.length > 1
      ? 'dos colaboradores será cancelada e eles não irão mais receber o saldo mensalmente.'
      : 'do colaborador será cancelada e ele não receberá o saldo mensalmente.'
  }`;

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza cancelar Recarga Automática?',
        body,
      }}
      confirmationWord="cancelar"
      buttons={{
        confirm: {
          loading,
          text: 'Cancelar Recarga Automática',
          onClick: () => {
            cancelDeposit(
              props.automaticCreditMetadata.employeeDepositIdList,
              props.onCancel,
            );
          },
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
    />
  );
}

export default ModalService.create(CancelAutomaticCreditFeedbackModal);
