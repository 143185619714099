import AuthService from '@frontend/services/AuthService';
import { trpc } from '@frontend/trpc';

export const useCheckIsCorporateCard = () => {
  const employeeId = AuthService.useEmployeeId();
  const { data, isLoading, error } = trpc.user.checkCorporateCard.useQuery(
    undefined,
    {
      enabled: !!employeeId,
    },
  );

  return {
    isCorporateCardUser: !!data?.corporateCardEnabled,
    isLoading,
    hasError: !!error,
  };
};
