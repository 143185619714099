import { Divider, Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import Flex from '@frontend/components/frames/Flex';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import formatCardNumber from '@frontend/utils/masks/formatCardNumber';
import { RouterOutputs } from 'backend';
import React from 'react';
import useGetCorporateAvailableBalance from '../../data/useGetCorporateBalance';
import PolicyDrawer from '../../drawers/PolicyDrawer';
import CardStatusTag from '../CardStatusTag';
import mappers from './mappers';
import * as SC from './styles';

type SharedTypes = {
  balances: Set<'BENEFITS' | 'CORPORATE'>;
  cardName: string;
  cardNumber: string;
  cardType?: 'CREDIT' | 'PREPAID';
  ownerName: string;
  provider: string;
  policy?: RouterOutputs['employee']['flashCards']['virtual']['getAll']['cards'][number]['policy'];
  status?: React.ComponentProps<typeof CardStatusTag>['status'];
  menuOptions?: React.ComponentProps<typeof Menu>['options'];
};

type VirtualCardProps = SharedTypes & {
  isVirtual: true;
  copyToClipboard: (_cardNumber: string) => void;
  cvv?: string;
  dueDate?: string;
  visible: boolean;
};

type PlasticCardProps = SharedTypes & {
  isVirtual?: false;
};

export function Card(props: VirtualCardProps | PlasticCardProps) {
  const corporateBalance = useGetCorporateAvailableBalance({
    policy: props.policy,
  });

  /** Controls the accent color and the available balance visibility */
  const businessUnit = props.balances.has('BENEFITS')
    ? 'BENEFITS'
    : 'EXPENSE_MANAGEMENT';

  /** Determines if the sensitive data should be shown */
  const showSensitiveData = props.isVirtual && props.visible;

  return (
    <SC.CardBackgroundAccent bu={businessUnit}>
      <SC.CardBackground>
        {/* ------------------------- Main content ------------------------- */}
        <Flex
          direction="column"
          padding="xs2"
          justify="space-between"
          style={{ flexGrow: 1 }}
        >
          {/* --------------------------- Top row -------------------------- */}
          <Flex
            align="center"
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Flex gap="xs4">
              <CardProviderChip provider={props.provider} variant="color" />
              {props.balances.has('BENEFITS') && (
                <Tag variant="primary" size="xs">
                  Benefícios
                </Tag>
              )}
              {props.balances.has('CORPORATE') && (
                <Tag variant="secondary" size="xs">
                  Corporativo
                </Tag>
              )}
              {props.status && <CardStatusTag status={props.status} />}
            </Flex>
            {props.menuOptions && (
              <Menu type="default" options={props.menuOptions}>
                <PillButton
                  size="small"
                  variant="default"
                  icon="IconDotsVertical"
                />
              </Menu>
            )}
          </Flex>
          {/* ------------------------- Middle row ------------------------- */}
          <Flex justify="space-between">
            <Flex direction="column" gap="xs5">
              <Flex gap="xs4">
                <Typography.Body3>
                  {capitalizeWords(props.cardName)}
                </Typography.Body3>
                {props.cardType && (
                  <Tag variant="neutral" size="xs">
                    {mappers.cardType[props.cardType]}
                  </Tag>
                )}
              </Flex>
              <Flex align="center">
                {!showSensitiveData && (
                  <>
                    <SC.NoVisible width={175} />
                    <Spacer x="xs4" />
                  </>
                )}
                <Typography.Headline7>
                  {formatCardNumber(props.cardNumber)}
                </Typography.Headline7>
              </Flex>
            </Flex>
            {props.isVirtual && props.visible && (
              <PillButton
                size="small"
                variant="default"
                icon="IconCopy"
                onClick={() => props.copyToClipboard(props.cardNumber)}
              />
            )}
          </Flex>
          {/* ------------------------- Bottom row ------------------------- */}
          <Flex justify="space-between">
            <Flex direction="column">
              <Typography.Caption>Nome no cartão</Typography.Caption>
              <Typography.Body3 weight={600}>
                {props.ownerName}
              </Typography.Body3>
            </Flex>
            <Flex gap="xs">
              <Flex direction="column">
                <Typography.Caption>Validade</Typography.Caption>
                {showSensitiveData && props.dueDate ? (
                  <Typography.Body3 weight={600}>
                    {props.dueDate}
                  </Typography.Body3>
                ) : (
                  <SC.NoVisible width={48} />
                )}
              </Flex>
              <Flex direction="column">
                <Typography.Caption>CVV</Typography.Caption>
                {showSensitiveData && props.cvv ? (
                  <Typography.Body3 weight={600}>{props.cvv}</Typography.Body3>
                ) : (
                  <SC.NoVisible width={25} />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {/* ------------------ Available corporate balance ----------------- */}
        {props.isVirtual && businessUnit === 'EXPENSE_MANAGEMENT' && (
          <>
            <Divider orientation="horizontal" />
            {props.policy?.limit ? (
              /* --------------------- Has policy limit --------------------- */
              <Flex padding="xs2" justify="space-between">
                <Flex direction="column">
                  {corporateBalance.data ? (
                    <Typography.Body3>
                      <Typography.Body3
                        as="span"
                        color="secondary_40"
                        weight={700}
                      >
                        {toCurrency(corporateBalance.data)}
                      </Typography.Body3>{' '}
                      disponível para uso
                    </Typography.Body3>
                  ) : (
                    <>
                      <SC.NoVisible width={180} />
                      <Spacer y="xs4" />
                    </>
                  )}
                  <Typography.Caption>
                    Limite de {toCurrency(props.policy.limit.amount)}/
                    {mappers.limitPeriod[props.policy.limit.period]}
                  </Typography.Caption>
                </Flex>
                <PillButton
                  size="small"
                  variant="default"
                  type="primary"
                  icon="IconChevronRight"
                  onClick={() =>
                    ModalService.show(PolicyDrawer, {
                      card: {
                        bu: props.balances.has('BENEFITS')
                          ? 'BENEFITS'
                          : 'EXPENSE_MANAGEMENT',
                        cardName: props.cardName,
                        lastFourDigits: props.cardNumber ?? '',
                        policy: props.policy!,
                        type: props.isVirtual ? 'VIRTUAL' : 'PLASTIC',
                      },
                    })
                  }
                />
              </Flex>
            ) : (
              /* ---------- Only limited by the corporate balance --------- */
              <Flex padding={['xs', 'xs2']}>
                {corporateBalance.data ? (
                  <Typography.Body3>
                    <Typography.Body3
                      as="span"
                      color="secondary_40"
                      weight={700}
                    >
                      {toCurrency(corporateBalance.data)}
                    </Typography.Body3>{' '}
                    disponível para uso
                  </Typography.Body3>
                ) : (
                  <SC.NoVisible width={150} />
                )}
              </Flex>
            )}
          </>
        )}
      </SC.CardBackground>
    </SC.CardBackgroundAccent>
  );
}
