import React from 'react';
import Spacer from '../../components/Spacer';
import ExternalCardsSection from './sections/ExternalCardsSection';
import useGetExternalCards from './sections/ExternalCardsSection/data/useGetExternalCards';
import PlasticCardsSection from './sections/PlasticCardsSection';
import useGetPlasticCards from './sections/PlasticCardsSection/data/useGetPlasticCards';
import VirtualCardsSection from './sections/VirtualCardsSection';
import useGetVirtualCards from './sections/VirtualCardsSection/data/useGetVirtualCards';
import * as styles from './styled';

export default function CardsSection() {
  const [view, setView] = React.useState<'grid' | 'list'>('list');

  const externalCards = useGetExternalCards();
  const plasticCards = useGetPlasticCards();
  const virtualCards = useGetVirtualCards();

  const cardsAvailable =
    !!plasticCards.items.length ||
    !!virtualCards.data.cards.length ||
    !!externalCards.items.length;

  return (
    <>
      {(!cardsAvailable || plasticCards.isLoading) && (
        <styles.StatusBox
          error={plasticCards.isError}
          loading={plasticCards.isLoading}
          title={
            plasticCards.isError
              ? 'Dados não encontrados'
              : 'Você ainda não tem cartões cadastrados'
          }
          subTitle={
            plasticCards.isError
              ? 'Tivemos um problema tentando trazer essa informação. Entre em contato com o suporte.'
              : 'Procure seu gestor para solicitar o seu cartão Flash.'
          }
        />
      )}
      {cardsAvailable && (
        <>
          <styles.WrapperSpaceBetween>
            <div></div>
            <styles.SwitchView option={view} onClick={setView} />
          </styles.WrapperSpaceBetween>
          <Spacer y="m" />
        </>
      )}
      <PlasticCardsSection view={view} plasticCards={plasticCards} />
      <VirtualCardsSection view={view} virtualCards={virtualCards} />
      <ExternalCardsSection view={view} externalCards={externalCards} />
      <Spacer y="xs" />
    </>
  );
}
