import NiceModal from '@ebay/nice-modal-react';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import '@total-typescript/ts-reset';
import { BrowserRouter } from 'react-router-dom';
import { Error } from './components/Error';
import { VersionProvider } from './components/VersionCallout/context';
import Router from './routes/Router';
import { FeatureFlagProvider } from './services/FeatureFlagService';
import { TrpcProvider } from './utils/trpc';

export default function Root() {
  return (
    <Error.Boundary moduleName="Cartão corporativo">
      <BrowserRouter>
        <ThemeProvider>
          <TrpcProvider>
            <FeatureFlagProvider>
              <NiceModal.Provider>
                <VersionProvider>
                  <Router />
                </VersionProvider>
              </NiceModal.Provider>
            </FeatureFlagProvider>
          </TrpcProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Error.Boundary>
  );
}
