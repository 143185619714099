import columns from './columns';
import TableClient from '@frontend/components/TableClient';
import type { UseGetVirtualCardsReturn } from '../data/useGetVirtualCards';

type Props = {
  loading: boolean;
  data: UseGetVirtualCardsReturn['data']['cards'];
};

export default function VirtualCardsTable(props: Props) {
  return (
    <TableClient
      loading={props.loading}
      emptyState={{
        emptyText: '',
        filteredEmptyText: '',
      }}
      columns={columns}
      data={props.data}
    />
  );
}
