import { MODAL_ActivateUsers } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/ActivateUsers/ActivateUsers';
import ModalService from '@frontend/services/ModalService';

type Props = {
  isOnboardingFlow?: boolean;
  requiredDeposit?: boolean;
  onActionClick?(this: void): void;
};

export function showActivateUsers(props?: Props) {
  ModalService.show(MODAL_ActivateUsers, {
    isOnboardingFlow: props?.isOnboardingFlow,
    requiredDeposit: props?.requiredDeposit,
    onActionClick: props?.onActionClick,
  });
}
