import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import React from 'react';
import { useActivateUsers } from '../data/useActivateUsers';
import { ActivateUsersFormSchema } from './schema';

const REQUEST_VALIDATION_TOKEN = false;

type Input = {
  onSuccess: () => void;
};

export function useForm(input: Input) {
  const form = FormService.useCreateForm(ActivateUsersFormSchema, {
    mode: 'onChange',
    defaultValues: {
      employeeIds: new Set(),
      deposit: {
        automatic: false,
        complementary: false,
        expires: false,
      },
    },
  });
  /* --------------------- Additional schema validations -------------------- */
  const formValues = form.watch();

  /** When `expires` is enabled, disable `automatic` and `complementary` */
  React.useEffect(() => {
    if (formValues.deposit.expires) {
      form.setValue('deposit.automatic', false);
      form.setValue('deposit.complementary', false);
    }
  }, [formValues.deposit.expires]);

  /** When `automatic` is enabled:
   * - disable `expires`;
   * - deselect PIX/BILLET payment method;
   */
  React.useEffect(() => {
    if (formValues.deposit.automatic) {
      form.setValue('deposit.expires', false);
      if (isIncluded(formValues.deposit.paymentMethod, ['BILLET', 'PIX'])) {
        form.setValue('deposit.paymentMethod', undefined);
      }
    }
  }, [formValues.deposit.automatic]);

  /** Triggers additional `schema.refine` validations */
  React.useEffect(() => {
    void form.trigger('deposit');
  }, [formValues.deposit.creditDate, formValues.deposit.paymentMethod]);
  /* ------------------------------- On Submit ------------------------------ */
  const activateUsers = useActivateUsers();

  const onSubmit = form.handleSubmit(
    (values) => {
      const { amount, confirmationToken, creditDate, paymentMethod } =
        values.deposit;
      if (
        amount &&
        (confirmationToken || !REQUEST_VALIDATION_TOKEN) &&
        creditDate &&
        paymentMethod
      ) {
        activateUsers.mutate({
          employeeIds: values.employeeIds,
          deposit: {
            ...values.deposit,
            amount,
            // TODO: Remove short-circuit when confirmation token is required
            confirmationToken: confirmationToken ?? 'NOT_REQUIRED',
            creditDate,
            paymentMethod,
          },
          onSuccess: input.onSuccess,
        });
      } else {
        activateUsers.mutate({
          employeeIds: values.employeeIds,
          deposit: null,
          onSuccess: input.onSuccess,
        });
      }
    },
    (error) => {
      dispatchToast({
        type: 'error',
        content:
          'Falha ao validar formulário de ativação de usuários corporativos',
        description: errors.actions.toastDescription,
      });
      ErrorMonitorService.error({
        message: 'Error while activating users',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(error),
        },
      });
    },
  );

  return {
    ...form,
    onSubmit,
    result: activateUsers.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
