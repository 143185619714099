import Flex from '@frontend/components/frames/Flex';
import BalanceField from '@frontend/components/inputs/BalanceField';
import RadioCard from '@frontend/components/inputs/RadioCard';
import SelectField from '@frontend/components/inputs/SelectField';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { translatedPeriodTypes } from '@frontend/pages/Policies/shared/mappers';
import { translatedMccGroups } from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { PeriodTypeEnum } from 'backend/src/utils/periodTypeEnum';
import { Controller } from 'react-hook-form';
import { LimitSchema, UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function LimitSection(props: Props) {
  return (
    <>
      <Spacer y="s" />
      <Typography.Headline8 color="neutral_30">
        Limites de uso
      </Typography.Headline8>
      <Spacer y="xs2" />
      <Controller
        control={props.form.control}
        name="limitType"
        render={(fieldProps) => (
          <>
            <RadioCard
              metadata={{
                title: 'Não quero definir limites de uso',
                description:
                  'As pessoas poderão usar todo o saldo disponível para elas',
              }}
              selected={fieldProps.field.value === 'UNLIMITED'}
              onClick={() => {
                fieldProps.field.onChange('UNLIMITED');
              }}
              key={'UNLIMITED'}
            />
            <Spacer y="xs" />
            <RadioCard
              metadata={{
                title: 'Mesmo limite para todas as categorias',
                description:
                  'Defina um valor máximo que valerá da mesma forma para todas as categorias ativas.',
              }}
              selected={fieldProps.field.value === 'GENERAL'}
              onClick={() => {
                fieldProps.field.onChange('GENERAL');
              }}
              key={'GENERAL'}
            >
              {fieldProps.field.value === 'GENERAL' && (
                <Flex justify="space-between" gap="xs">
                  <Controller
                    control={props.form.control}
                    name="limit.amount"
                    render={({ field, fieldState }) => (
                      <BalanceField
                        fullWidth
                        label="Limite (R$)"
                        value={field.value}
                        onChange={field.onChange}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={props.form.control}
                    name="limit.period"
                    render={({ field, fieldState }) => (
                      <SelectField<LimitSchema['period']>
                        fullWidth
                        label="Período"
                        value={field.value}
                        onChange={field.onChange}
                        options={Object.values(PeriodTypeEnum).map(
                          (periodType) => ({
                            label: translatedPeriodTypes[periodType],
                            value: periodType,
                          }),
                        )}
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Flex>
              )}
            </RadioCard>
            <Spacer y="xs" />
            <RadioCard
              metadata={{
                title: 'Limites diferentes para cada categoria',
                description:
                  'Defina um valor máximo de uso para cada categoria ativa na política e o período que poderá ser utilizado.',
              }}
              selected={fieldProps.field.value === 'BY_CATEGORY'}
              onClick={() => {
                fieldProps.field.onChange('BY_CATEGORY');
              }}
              key={'BY_CATEGORY'}
            >
              {fieldProps.field.value === 'BY_CATEGORY' && (
                <Controller
                  control={props.form.control}
                  name="mccLimits"
                  render={() => {
                    const enabledCategoryList = props.form.watch('mccGroups');
                    const withdrawEnabled = props.form.watch('withdrawEnabled');

                    const categories = [
                      ...Object.keys(enabledCategoryList).filter(
                        (mcc) => enabledCategoryList[mcc],
                      ),
                      ...(withdrawEnabled ? ['WITHDRAW'] : []),
                    ];

                    return (
                      <Flex direction="column" gap="xs2">
                        {categories.map(
                          (category: keyof typeof translatedMccGroups) => (
                            <Flex align="center" key={category}>
                              <Flex grow={1}>
                                <Typography.Body3>
                                  {translatedMccGroups[category].title}
                                </Typography.Body3>
                              </Flex>
                              <Spacer x="xs" />

                              <Flex grow={3}>
                                <Controller
                                  control={props.form.control}
                                  name={`mccLimits.${category}.amount`}
                                  render={({ field, fieldState }) => {
                                    return (
                                      <BalanceField
                                        fullWidth
                                        label="Limite (R$)"
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={fieldState.error?.message}
                                      />
                                    );
                                  }}
                                />
                              </Flex>
                              <Spacer x="xs2" />
                              <Flex grow={3}>
                                <Controller
                                  control={props.form.control}
                                  name={`mccLimits.${category}.period`}
                                  render={({ field, fieldState }) => (
                                    <SelectField<LimitSchema['period']>
                                      fullWidth
                                      label="Período"
                                      value={field.value}
                                      onChange={field.onChange}
                                      options={Object.values(
                                        PeriodTypeEnum,
                                      ).map((periodType) => ({
                                        label:
                                          translatedPeriodTypes[periodType],
                                        value: periodType,
                                      }))}
                                      variant="standard"
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                    />
                                  )}
                                />
                              </Flex>
                            </Flex>
                          ),
                        )}
                      </Flex>
                    );
                  }}
                />
              )}
            </RadioCard>
          </>
        )}
      />
    </>
  );
}
