import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';
import { Navigate, Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';

export const ROUTE_Home_FlashCard = RouterService.create(
  `${BasePath.relativePath}/home/flashCard`,
  {
    searchParams: {
      currentPage: zod(z.coerce.number().catch(0)),
      pageSize: zod(z.coerce.number().catch(10)),
    },
  },
);

export const RouteComponent_Home_FlashCard_Index = (
  <Route index element={<Navigate to="/corporateCard/user-accounts" />} />
);

export const RouteComponent_Home_FlashCard = (
  <Route
    path={ROUTE_Home_FlashCard.path}
    element={<Navigate to="/corporateCard/user-accounts" />}
  />
);
