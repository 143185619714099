import { Divider, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { CreditCard } from '@frontend/components/display/CreditCard/CreditCard';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Callout from '@frontend/components/frames/Callout';
import Flex from '@frontend/components/frames/Flex';
import Drawer from '@frontend/components/modals/Drawer';
import { PolicyWithMccLimits } from '@frontend/pages/Policies/focusedFlows/CreatePolicy/forms/schema';
import ModalService from '@frontend/services/ModalService';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import { toCurrency } from '@frontend/utils/masks';
import { PolicyWithLimitUsageJSONSchema } from 'backend/src/externalServices/ExpenseManagementService/policies/schemas/policy.json-schema';
import { z } from 'zod';
import useGetCorporateAvailableBalance from '../data/useGetCorporateBalance';
import { FullWidthContainer } from './styled';

type Props = {
  card: {
    bu: 'BENEFITS' | 'EXPENSE_MANAGEMENT';
    type: 'PLASTIC' | 'VIRTUAL';
    cardName: string;
    lastFourDigits: string;
    policy: z.output<typeof PolicyWithLimitUsageJSONSchema>;
  };
};

function PolicyDrawer(props: Props) {
  const modalController = ModalService.useModalController();
  const corporateBalance = useGetCorporateAvailableBalance({
    policy: props.card.policy,
  });

  const cardTitle = `Cartão ${
    props.card.type === 'PLASTIC' ? 'físico' : 'virtual'
  }${props.card.bu === 'EXPENSE_MANAGEMENT' ? ' corporativo' : ''}`;

  const limitsByCategory: PolicyWithMccLimits['mccLimits'] | undefined =
    props.card.policy.mccLimits;

  return (
    <Drawer
      visible={modalController.visible}
      close={modalController.remove}
      title="Política de uso corporativo"
      anchor="right"
    >
      <Flex direction="column" gap="xs">
        <Flex direction="row" gap="xs">
          <CreditCard brand="FLASH" provider="MASTERCARD" bu={props.card.bu} />
          <Flex direction="column" gap="xs3">
            <Typography.Headline8>{cardTitle}</Typography.Headline8>
            <div>
              <Typography.Body3>{props.card.cardName}</Typography.Body3>
              <Typography.Body4>
                Final - {props.card.lastFourDigits}
              </Typography.Body4>
            </div>
          </Flex>
        </Flex>
        <Typography.Body4 color="neutral_40">
          Saiba como usar este cartão corporativo de acordo com a política
          definida pela sua empresa.
        </Typography.Body4>
        {props.card.bu === 'BENEFITS' && (
          <Typography.Body4 color="neutral_40">
            As regras a seguir se aplicam somente quando o cartão estiver com o
            saldo corporativo ativado no app da Flash!
          </Typography.Body4>
        )}
        {props.card.policy.limit && (
          <BorderedSection>
            <Flex direction="column">
              <Flex>
                <Flex
                  direction="column"
                  padding={['xs3', 'xs']}
                  gap="xs5"
                  style={{ flexGrow: 1 }}
                >
                  <Typography.Body4>Disponível para uso</Typography.Body4>
                  {corporateBalance.data !== null ? (
                    <Typography.Headline7 color="secondary_40">
                      {toCurrency(corporateBalance.data)}
                    </Typography.Headline7>
                  ) : (
                    <Spinner size={32} variant="primary" />
                  )}
                </Flex>
                <Divider orientation="vertical" />
                <Flex
                  direction="column"
                  padding={['xs3', 'xs']}
                  gap="xs5"
                  style={{ flexGrow: 1 }}
                >
                  <Typography.Body4>
                    Limite {periodMap[props.card.policy.limit.period]}
                  </Typography.Body4>
                  <Typography.Headline7 color="neutral_40">
                    {toCurrency(props.card.policy.limit.amount)}
                  </Typography.Headline7>
                </Flex>
              </Flex>
              <Divider orientation="horizontal" />
              <Flex padding={['xs3', 'xs']} style={{ gridArea: 'DESCRIPTION' }}>
                <Typography.Caption>
                  O valor disponível para uso pode sofrer variações dependendo
                  do limite que já foi consumido e do saldo corporativo
                  disponível no momento.
                </Typography.Caption>
              </Flex>
            </Flex>
          </BorderedSection>
        )}
        {
          // Should only be shown for plastic cards
          props.card.type === 'PLASTIC' &&
            (props.card.policy.withdrawEnabled ? (
              <Callout
                title="Saque no cartão físico habilitado"
                description="Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
                leftAdornment={
                  <Icon
                    name="IconCash"
                    color="success_40"
                    background="success"
                    size={48}
                  />
                }
              >
                {!!limitsByCategory && limitsByCategory['WITHDRAW'] && (
                  <Tag size="sm" variant="neutral">
                    {`${toCurrency(limitsByCategory['WITHDRAW'].amount)} / ${limitPeriodTranslated[limitsByCategory['WITHDRAW'].period]}`}
                  </Tag>
                )}
              </Callout>
            ) : (
              <Callout
                title="Saque no cartão físico desabilitado"
                description="Os titulares dos cartões não poderão realizar saques do saldo corporativo em caixas eletrônicos."
                leftAdornment={
                  <Icon
                    name="IconCashOff"
                    color="error_40"
                    background="error"
                    size={48}
                  />
                }
              />
            ))
        }
        <Callout
          title="Dias de uso"
          description="Dias da semana em que transações com esse cartão estão permitidas."
          leftAdornment={
            <Icon
              name="IconCalendar"
              size={48}
              color="secondary_50"
              background="default"
            />
          }
        >
          <Flex wrap="wrap" gap="xs4">
            {sortedWeekdays
              .filter((weekday) => props.card.policy.weekdaysEnabled[weekday])
              .map((weekday) => (
                <Tag size="sm" variant="neutral" key={weekday}>
                  {translatedWeekdays[weekday]}
                </Tag>
              ))}
          </Flex>
        </Callout>
        <Callout
          title={
            !!limitsByCategory
              ? 'Limites de uso por categoria'
              : 'Tipos de estabelecimento'
          }
          description="Categorias nas quais transações feitas com esse cartão serão aceitas."
          leftAdornment={
            <Icon
              name="IconBuildingStore"
              size={48}
              color="secondary_50"
              background="default"
            />
          }
        >
          <Flex wrap="wrap" direction="column">
            {sortedMccGroups
              .filter((mccGroup) => props.card.policy.mccGroups[mccGroup])
              .map((mccGroup: keyof typeof translatedMccGroups, index) => {
                const category = translatedMccGroups[mccGroup];
                const categoryLimit =
                  limitsByCategory && limitsByCategory[mccGroup];

                return (
                  <>
                    {index !== 0 && (
                      <FullWidthContainer>
                        <Spacer y="xs3" />
                        <Divider orientation="horizontal" />
                        <Spacer y="xs3" />
                      </FullWidthContainer>
                    )}
                    <Flex justify="space-between" align="center">
                      <Flex direction="column">
                        <Typography.Body3 color="neutral_30" weight={600}>
                          {category.title}
                        </Typography.Body3>
                        <Typography.Body4 color="neutral_40">
                          {category.description}
                        </Typography.Body4>
                      </Flex>
                      {!!categoryLimit &&
                        !!categoryLimit.amount &&
                        !!categoryLimit.period && (
                          <Tag size="sm" variant="neutral">
                            {`${toCurrency(categoryLimit.amount)} / ${limitPeriodTranslated[categoryLimit.period]}`}
                          </Tag>
                        )}
                    </Flex>
                  </>
                );
              })}
          </Flex>
        </Callout>
      </Flex>
    </Drawer>
  );
}
export default ModalService.create(PolicyDrawer);

const periodMap = {
  DAY: 'diário',
  WEEK: 'semanal (renova todo Domingo)',
  MONTH: 'mensal (renova todo dia 01)',
};

const limitPeriodTranslated = {
  DAY: 'dia',
  WEEK: 'semana',
  MONTH: 'mês',
};
