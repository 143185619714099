import {
  DatePicker,
  LinkButton,
  Modal,
  Spinner,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import Button from '@frontend/components/Button';
import { DateTime } from 'luxon';
import ModalService from '../../../../../services/ModalService';
import { onlyNumbers, toCurrency } from '../../../../../utils/masks';
import { AutomaticCreditMetadata } from '../../types';
import { useEditAutomaticCredit } from './data/useEditAutomaticCreditController';
import * as SC from './styled';

function disabledDate(date: Date): boolean {
  return (
    DateTime.now().startOf('day').diff(DateTime.fromJSDate(date), 'days').days >
    0
  );
}

export type EditAutomaticCreditProps = {
  automaticCredit: AutomaticCreditMetadata;
};

function EditAutomaticCreditModal(props: EditAutomaticCreditProps) {
  const modalController = ModalService.useModalController();

  const {
    actions,
    automaticCreditMetadata,
    setUpdatedAutomaticCredit,
    loading,
  } = useEditAutomaticCredit({
    automaticCreditMetadata: props.automaticCredit,
  });

  return (
    <Modal.Root
      open={modalController.visible}
      onClose={modalController.remove}
      size="xs"
    >
      <>
        <Modal.Header
          title="Editar Recarga Automática"
          description="O saldo da Recarga Automática é disponibilizado no cartão do colaborador mensalmente."
        />

        <Modal.Content>
          {loading ? (
            <Spinner variant="primary" size={32} />
          ) : (
            <SC.BodyWrapper>
              <SC.TitleTypography>
                Dados da Recarga Automática
              </SC.TitleTypography>
              <TextField
                label="Valor da recarga"
                value={
                  automaticCreditMetadata.creditAmount !== null
                    ? toCurrency(automaticCreditMetadata.creditAmount)
                    : ''
                }
                onChange={({ target }) => {
                  const amount = onlyNumbers(target.value.replace(',', ''));
                  const amountAsInt = parseInt(amount.replace(/\.|\,/, ''));

                  setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                    ...automaticCreditMetadata,
                    creditAmount: amountAsInt,
                  }));
                }}
              />
              <SC.ToggleWrapper>
                <Toggle
                  onChange={(event) => {
                    setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                      ...automaticCreditMetadata,
                      type: event.target.checked ? 'fixedValue' : 'fixedDate',
                    }));
                  }}
                  value={automaticCreditMetadata.type === 'fixedValue'}
                  defaultChecked={automaticCreditMetadata.type === 'fixedValue'}
                />
                <SC.ToggleTextWrapper>
                  <SC.ToggleTitle>Função complementar</SC.ToggleTitle>
                  <SC.ToggleDescription>
                    Se ativado, o colaborador irá receber apenas o valor que
                    complete o saldo que ele já possui no cartão totalizando no
                    valor da recarga. Esse valor é definido no momento da
                    recarga, podendo ter variações do valor depositado.
                  </SC.ToggleDescription>
                </SC.ToggleTextWrapper>
              </SC.ToggleWrapper>
              <DatePicker
                onDateChange={(date) => {
                  setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                    ...automaticCreditMetadata,
                    availabilityDate: date ? date.toDate() : undefined,
                  }));
                }}
                value={automaticCreditMetadata.availabilityDate as Date}
                label="Disponibilização do Saldo Corporativo"
                disabledDate={disabledDate}
              />
            </SC.BodyWrapper>
          )}
        </Modal.Content>

        <Modal.Footer>
          <LinkButton variant="neutral" onClick={actions.cancel.onClick}>
            {actions.cancel.text}
          </LinkButton>

          <Button variant="primary" onClick={actions.confirm.onClick}>
            {actions.confirm.text}
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
}

export default ModalService.create(EditAutomaticCreditModal);
