const cardType = {
  CREDIT: 'Crédito',
  PREPAID: 'Pré-pago',
};

const limitPeriod = {
  DAY: 'dia',
  WEEK: 'semana, renova todo Domingo',
  MONTH: 'mês, renova todo dia 01',
};

export default {
  cardType,
  limitPeriod,
};
