interface ServicesProps {
  [key: string]: {
    development: string;
    staging: string;
    production: string;
  };
}

export const services = {
  bff: {
    development: 'https://corporate-card-bff.private.flashapp.dev/bff/trpc',
    staging: 'https://corporate-card-bff.private.flashapp.dev/bff/trpc',
    production: 'https://corporate-card-bff.us.flashapp.services/bff/trpc',
  },
  unleash: {
    development:
      'https://unleash-benefits-proxy-development.us.flashapp.services/proxy',
    staging:
      'https://unleash-benefits-proxy-development.us.flashapp.services/proxy',
    production:
      'https://unleash-benefits-proxy-production.us.flashapp.services/proxy',
  },
} as ServicesProps;
