import { Dialog, DialogContent } from '@mui/material';
import styled from 'styled-components';

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: ${({ theme }) => theme.borders.radius.l};
    max-height: ${({ theme }) => `calc(100vh - ${theme.spacings.l})`};
    max-width: fit-content;
  }
`;

export const Container = styled(DialogContent)<{ size: 'sm' | 'md' | 'lg' }>`
  width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '480px';
      case 'md':
        return '800px';
      case 'lg':
        return '960px';
    }
  }};
  text-align: left;
  padding: 0;
  overflow-y: auto;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 20px;
`;

export const Content = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.m} ${theme.spacings.xs}`};
`;

export const CloseContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: 12px;
  top: 12px;
`;
