import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import styled from 'styled-components';

type Props = {
  bu: 'BENEFITS' | 'EXPENSE_MANAGEMENT';
  brand: 'FLASH';
  provider: 'MASTERCARD';
};

export function CreditCard(props: Props) {
  return (
    <CardBody bu={props.bu}>
      <CardProviderChip provider={props.brand} variant="foreground" />
      <div style={{ position: 'absolute', right: '4px', bottom: '4px' }}>
        <CardProviderChip provider={props.provider} variant="foreground" />
      </div>
    </CardBody>
  );
}

const CardBody = styled.div<{
  bu: 'BENEFITS' | 'EXPENSE_MANAGEMENT';
}>`
  position: relative;
  border-radius: 4px;
  padding: 8px;
  height: 75px;
  width: 120px;
  background-color: ${(p) =>
    p.bu === 'BENEFITS'
      ? p.theme.colors.primary
      : p.theme.colors.secondary[30]};
`;
