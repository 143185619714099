import AddFlashCashModal from '@frontend/external/DashboardSection/add-flash-cash-modal';
import { toCurrency } from '@frontend/utils/masks';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ModalService from '../../../services/ModalService';

type Props = {
  value?: number;
};

function ShowSensitiveInfoModal({ value }: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Adicionar Flash Cash Corporativo',
          onClick: () => {
            modalController.remove();
            ModalService.show(AddFlashCashModal, {});
          },
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        title: 'Você não tem saldo suficiente no Flash Cash Corporativo',
        body: `Você precisa adicionar ${
          value ? toCurrency(value) : 'saldo'
        } no Flash Cash Corporativo para realizar a Recarga.`,
      }}
    />
  );
}

export default ModalService.create(ShowSensitiveInfoModal);
