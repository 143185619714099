import { BarcodeGenerator } from '@frontend/components/Barcode';
import Flex from '@frontend/components/frames/Flex';
import { QRCode } from '@frontend/components/QrCode';
import { useGetCode } from '../../data/useGetCode';

export function PaymentSucceeded(props: ReturnType<typeof useGetCode>) {
  if (props.paymentInfos == null) return null;
  return (
    <Flex padding={'xs4'} gap="xs" direction="column">
      {props.paymentInfos.paymentMethod === 'PIX' && props.code && (
        <Flex justify="center">
          <QRCode code={props.code} pdfUrl={props.pdfLink} />
        </Flex>
      )}
      {props.paymentInfos?.paymentMethod === 'BILLET' && props.code && (
        <Flex justify="center" padding="xs4">
          <BarcodeGenerator
            barcode={props.code}
            pdfUrl={props.pdfLink}
            doNotDisplayBarCode={true}
          />
        </Flex>
      )}
    </Flex>
  );
}
