import styled from 'styled-components';

import { colors, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Radio } from '@mui/material';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  > div {
    height: 100%;
  }
`;

export const Button = styled.div<{ selected: boolean; disabled?: boolean }>`
  align-items: flex-start;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${colors.neutral[80]};
  border-radius: ${(props) => props.theme.borders.radius.m}
    ${(props) => props.theme.borders.radius.xl2}
    ${(props) => props.theme.borders.radius.m}
    ${(props) => props.theme.borders.radius.m};
  gap: ${({ theme }) => theme.spacings.xs3};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: ${(props) =>
    `${props.theme.spacings.xs2} ${props.theme.spacings.xs3}`};
  width: 100%;
  cursor: pointer;
  border-color: ${colors.neutral[90]};
  background-color: ${colors.neutral[100]};

  &:hover {
    background-color: ${colors.secondary[99]};
    border-color: transparent;
  }

  ${(props) =>
    props.selected && {
      'border-color': 'transparent',
      'background-color': colors.secondary[99],
    }};
  ${(props) =>
    props.disabled && {
      'border-color': colors.neutral[80],
      'background-color': colors.neutral[95],
      cursor: 'not-allowed',
      '&:hover': {
        'background-color': colors.neutral[95],
      },
    }},
`;

export const Description = styled(Typography.Caption)`
  color: ${colors.neutral[50]};
  text-align: left;
`;

export const TopContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const RadioButton = styled(Radio)`
  padding: 0;

  &.MuiRadio-root {
    color: var(--color-neutral-light4);

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--color-neutral-dark3);
    }
  }

  &.Mui-checked {
    &:hover,
    &:focus {
      > span:first-of-type {
        background-color: var(--color-secondary-light4);
        border-radius: 50%;
      }
    }

    &.disabled {
      color: black;
    }

    .MuiSvgIcon-root {
      &:first-child {
        z-index: 1;
        color: var(--color-primary);
        background-color: var(--color-primary);
        border-radius: 50%;
      }

      &:last-child {
        z-index: 2;
        color: #fff;
      }
    }
  }

  &.Mui-disabled {
    color: transparent !important;

    .MuiSvgIcon-root {
      background-color: var(--color-neutral-light1);
      border-radius: 50%;
    }
  }

  .radio-error {
    color: var(--color-feedback-error-pure) !important;

    &.Mui-checked {
      &:hover,
      &:focus {
        > span:first-of-type {
          background-color: var(--color-feedback-error-light1);
          border-color: var(--color-feedback-error-light1);
        }
      }

      .MuiSvgIcon-root {
        &:first-child {
          color: var(--color-feedback-error-pure) !important;
          background-color: var(--color-feedback-error-pure) !important;
        }
      }
    }
  }
`;

export const ErrorMessageArea = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding-left: ${({ theme }) => theme.spacings.xs};
`;
