/** Formats the card owner name
 *
 * @example
 * ```
 * formatCardOwnerName('John Doe Smith') // => 'JOHN D'
 * ```
 */
export default function formatCardOwnerName(name: string) {
  const words = name
    .normalize('NFD') // Decomposes `é` into `e` + `'`
    .replace(/[\u0300-\u036f]/g, '') // Removes diacritics
    .split(' ');

  if (words.length <= 2) return words.join(' ').toUpperCase();
  const firstName = words.shift();
  const lastName = words.pop();

  return `${firstName} ${words
    .map((word) => word.charAt(0))
    .join(' ')} ${lastName}`.toUpperCase();
}
