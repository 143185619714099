import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon-business-days';
import { UseFormReturn } from '../../../form/useForm';
import useGetFlashCashBalance from './useGetFlashCashBalance';

type Input = {
  form: UseFormReturn;
};

export function useFormMetadata(input: Input) {
  const formData = input.form.watch();
  const flashCashBalance = useGetFlashCashBalance();

  const creditDateFrom = DateTime.now()
    .plusBusiness({ days: formData.deposit.paymentMethod === 'BILLET' ? 3 : 0 })
    .toJSDate();

  const totalCreditAmount =
    formData.employeeIds.size * (formData.deposit.amount ?? 0);

  const hasEnoughFlashCash =
    (flashCashBalance.balance ?? -Infinity) >= totalCreditAmount;

  const expirationDateFrom = DateTime.fromJSDate(
    formData.deposit.creditDate ?? creditDateFrom,
  )
    .plus({ days: 1 })
    .toJSDate();

  const automaticDepositAvailable =
    formData.deposit.paymentMethod === 'FLASH_CASH';

  const selectedCount = formData.employeeIds.size;

  const flashCashOnly = formData.deposit.automatic;

  const recurrentDepositDisabled = isIncluded(formData.deposit.paymentMethod, [
    'BILLET',
    'PIX',
  ]);

  return {
    automaticDepositAvailable,
    /** Lower limit for the credit day (today) */
    creditDateFrom,
    /** Lower limit for the expiration date */
    expirationDateFrom,
    /** Whether the company has enough Flash Cash for paying the deposit */
    hasEnoughFlashCash,
    /** The available amount of Flash Cash */
    flashCashBalance: {
      isLoading: flashCashBalance.isLoading,
      balance: toCurrency(flashCashBalance.balance ?? 0),
    },
    /** When automatic deposit is enabled, flash-cash is the only payment method allowed */
    flashCashOnly,
    /** The total amount that will be deposited */
    totalCreditAmount,
    selectedCount,
    recurrentDepositDisabled,
  };
}
