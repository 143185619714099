import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import DefaultModal from '../../../../components/modals/DefaultModal';
import Spacer from '../../../../components/Spacer';
import Typography from '../../../../components/Typography';
import ModalService from '../../../../services/ModalService';
import useEmployeeStatement from '../data/useEmployeeStatement';

type Props = {
  employeeId: string;
};

const FormSchema = z.object({
  dayInterval: z.number({ required_error: 'Campo obrigatório' }),
});

function EmployeeStatementModal(props: Props) {
  const modalController = ModalService.useModalController();
  const { employeeStatementReport, loading } = useEmployeeStatement();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const handleSubmit = form.handleSubmit((parsedValues) => {
    employeeStatementReport(
      {
        dayInterval: parsedValues.dayInterval,
        employeeId: props.employeeId,
        format: 'csv',
      },
      () => modalController.remove(),
    );
  });

  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      title="Extrato do colaborador"
      size="sm"
      subtitle="Download da planilha em CSV com todas as transações do colaborador."
      children={
        <div style={{ position: 'relative' }}>
          <Typography.Body3 color="neutral_30" weight={700}>
            Informação para Download do CSV
          </Typography.Body3>
          <Spacer y="xs" />
          <Controller
            name="dayInterval"
            control={form.control}
            render={(props) => (
              <SelectField
                value={props.field.value}
                onSelectChange={(_, value) => {
                  props.field.onChange(value?.value);
                }}
                label="Período de lançamento"
                helperText={props.formState.errors.dayInterval?.message}
                options={[
                  { label: '15 dias atrás', value: 15 },
                  { label: '30 dias atrás', value: 30 },
                  { label: '45 dias atrás', value: 45 },
                  { label: '60 dias atrás', value: 60 },
                  { label: '90 dias atrás', value: 90 },
                ]}
              />
            )}
          />
        </div>
      }
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
        confirm: {
          text: 'Enviar',
          onClick: () => void handleSubmit(),
          loading: loading,
        },
      }}
    />
  );
}

export default ModalService.create(EmployeeStatementModal);
