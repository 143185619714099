import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';

export function LoadingPaymentFile() {
  return (
    <Flex align="center" gap="xs3" direction="row" padding={'xs2'}>
      <Spinner size={32} />
      <Flex direction="column">
        <Typography.Headline9 color="neutral_30">
          Carregando as informações do seu depósito...
        </Typography.Headline9>
        <Typography.Body4 color="neutral_40">
          Por favor, aguarde alguns instantes.
        </Typography.Body4>
      </Flex>
    </Flex>
  );
}
