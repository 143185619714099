import { RouterInputs, RouterOutputs, trpc } from '@frontend/utils/trpc';
import { useEffect, useState } from 'react';
import { initialPagination } from '../tableSetup';

export type GetStatementOutput = RouterOutputs['company']['cards']['statement'];
export type GetStatementInput = RouterInputs['company']['cards']['statement'];

export function useTableData(employeeNanoId: string) {
  const [amountFilters, setAmountFilters] = useState<{
    max: number;
    min: number;
  }>({ min: 0, max: 999999999999 });
  const [filters, setFilters] = useState<
    Omit<GetStatementInput['filter'], 'employeeId'>
  >({ value: amountFilters });
  const [pagination, setPagination] = useState(initialPagination);

  const filter = {
    ...(!!filters && {
      ...filters,
    }),
  };

  const { data, isInitialLoading, isRefetching, isStale } =
    trpc.company.cards.statement.useQuery(
      {
        pagination,
        filter: {
          employeeNanoId,
          ...filter,
          status: [
            'AUTHORIZED',
            'COMPLETED',
            'SETTLED',
            'CANCELED',
            'REVERTED',
          ],
        },
      },
      {
        keepPreviousData: true,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: 'always',
        staleTime: 20 * 60 * 1000,
        refetchInterval: 2 * 60 * 1000,
      },
    );

  const getStatement = data || {
    transactions: [],
    pagination: { totalPages: 0 },
  };

  useEffect(() => {
    if (
      data?.filter?.value?.max !== undefined &&
      data?.filter?.value?.min !== undefined
    ) {
      setAmountFilters(data.filter.value);
    }
  }, [data?.filter?.value?.max, data?.filter?.value?.min]);

  return {
    value: amountFilters,
    getStatement,
    setPagination,
    pagination,
    isFiltered: Object.keys(filter).length > 0,
    setFilters,
    getStatementLoading: isInitialLoading || (isStale && isRefetching),
  };
}
