import { PasswordField } from '@flash-tecnologia/hros-web-ui-v2';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { Controller } from 'react-hook-form';
import useForm from './useForm';

type Props = {
  onClick?: (_password: string) => void;
};

function ShowSensitiveDataModal(props: Props) {
  const modalController = ModalService.useModalController();

  const { form, onSubmit } = useForm({
    onSubmit(formValues) {
      props.onClick?.(formValues.password);
    },
  });

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Acessar',
          onClick: onSubmit,
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Acessar informações de seus cartões',
        body: (
          <>
            <Typography.Body4>
              Para visualizar as informações dos seus cartões,{' '}
              <b>digite sua senha de transação.</b>
            </Typography.Body4>
            <Spacer y="m" />
            <Controller
              control={form.control}
              name="password"
              render={(controller) => (
                <PasswordField
                  fullWidth
                  label="Senha de transação"
                  type={'password'}
                  value={controller.field.value}
                  onChange={controller.field.onChange}
                  error={controller.fieldState.invalid}
                  helperText={controller.fieldState.error?.message}
                />
              )}
            />
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(ShowSensitiveDataModal);
