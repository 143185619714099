import { BarcodeGenerator } from '@frontend/components/Barcode';
import Flex from '@frontend/components/frames/Flex';
import { QRCode } from '@frontend/components/QrCode';
import { useSummaryData } from '../../data/useSummaryData';

export function PaymentAvailable(data: ReturnType<typeof useSummaryData>) {
  if (!data.deposit.paymentDetails) return null;
  return (
    <Flex padding={'xs2'} gap="xs2" direction="column">
      {data.deposit.paymentDetails.paymentMethod?.label === 'Pix' &&
        data.pixCode && (
          <Flex justify="center">
            <QRCode code={data.pixCode} pdfUrl={data.downloadPdf?.pdfUrl} />
          </Flex>
        )}
      {data.deposit.paymentDetails.paymentMethod?.label === 'Boleto' &&
        data.barcode && (
          <Flex justify="center">
            <BarcodeGenerator
              barcode={data.barcode}
              pdfUrl={data.downloadPdf?.pdfUrl}
            />
          </Flex>
        )}
    </Flex>
  );
}
