import errors from '@frontend/utils/commonTexts/errors';
import { DateTime } from 'luxon-business-days';
import { z } from 'zod';

const BaseDepositSchema = z.object({
  /* -------------------------- Authentication -------------------------- */
  confirmationToken: z.string().optional(),

  /* ------------------------ Basic deposit setup ----------------------- */
  /** Amount that should be deposited for each employee */
  amount: z
    .number({ required_error: errors.forms.generic.required })
    .positive(errors.forms.generic.required),
  /** Date of the (first) employee credit */
  creditDate: z
    .date({
      errorMap: () => ({
        message: errors.forms.date.invalid,
      }),
    })
    .min(DateTime.now().startOf('day').toJSDate(), errors.forms.date.future)
    .max(
      DateTime.now().plus({ years: 2 }).toJSDate(),
      errors.forms.date.tooFarInFuture,
    ),
  /** Payment method for the deposit */
  paymentMethod: z.enum(['FLASH_CASH', 'PIX', 'BILLET'], {
    required_error: errors.forms.generic.required,
  }),
});

const DepositFeaturesSchema = z.object({
  /* ------------------------- Expiration setup ------------------------- */
  /** Whether the credited balance should expire at the `expirationDate`. Only valid for single deposits */
  expires: z.boolean().catch(false),
  /** Expiration date of the credited balance. Only valid for single deposits, when `expires: true` */
  expirationDate: z
    .date({
      invalid_type_error: errors.forms.date.invalid,
    })
    .optional(),

  /* ------------------------- Recurrence setup ------------------------- */
  /** Whether the deposit should be recurrent. */
  automatic: z.boolean().catch(false),
  /** Whether the deposit should only complement the employee balance to a target amount. Only valid for automatic deposits */
  complementary: z.boolean().catch(false),
});

const DepositSchema = BaseDepositSchema.and(DepositFeaturesSchema)
  .refine(
    // When `expires` is enabled, `expirationDate` is required
    (data) => {
      return data.expires ? Boolean(data.expirationDate) : true;
    },
    {
      message: errors.forms.generic.required,
      path: ['expirationDate'],
    },
  )
  /** Checks if billet deposits are at least 3 business days in the future */
  .refine(
    (data) => {
      if (data.paymentMethod === 'BILLET' && data.creditDate) {
        const createDateFrom = DateTime.now()
          .plusBusiness({ days: 3 })
          .startOf('day')
          .toJSDate();

        return data.creditDate >= createDateFrom;
      }
      return true;
    },
    {
      message:
        'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
      path: ['creditDate'],
    },
  )
  .refine(
    (data) => {
      return (
        data.paymentMethod !== 'BILLET' || (data.amount && data.amount >= 1000)
      );
    },
    {
      message: errors.forms.paymentMethod.minimumBilletValue,
      path: ['amount'],
    },
  )
  .refine(
    (data) => {
      if (
        (data.paymentMethod === 'BILLET' || data.paymentMethod === 'PIX') &&
        data.amount &&
        data.amount > 1_000_000_000
      )
        return false;

      return true;
    },
    {
      message: errors.forms.paymentMethod.maxValue,
      path: ['amount'],
    },
  );

const DepositPartialSchema = BaseDepositSchema.partial()
  .and(DepositFeaturesSchema)
  .refine(
    (data) => {
      if (data.paymentMethod === 'BILLET' && data.creditDate) {
        const createDateFrom = DateTime.now()
          .plusBusiness({ days: 3 })
          .startOf('day')
          .toJSDate();

        return data.creditDate >= createDateFrom;
      }
      return true;
    },
    {
      message:
        'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
      path: ['creditDate'],
    },
  )
  .refine(
    (data) => {
      return (
        (data.amount === undefined && data.creditDate === undefined) ||
        data.paymentMethod !== undefined
      );
    },
    {
      message: errors.forms.generic.required,
      path: ['paymentMethod'],
    },
  )
  .refine(
    (data) => {
      return (
        (data.amount === undefined && data.paymentMethod === undefined) ||
        data.creditDate !== undefined
      );
    },
    {
      message: errors.forms.date.invalid,
      path: ['creditDate'],
    },
  )
  .refine(
    (data) => {
      return (
        (data.creditDate === undefined && data.paymentMethod === undefined) ||
        data.amount
      );
    },
    {
      message: errors.forms.generic.required,
      path: ['amount'],
    },
  )
  .refine(
    (data) => {
      return (
        data.paymentMethod !== 'BILLET' || (data.amount && data.amount >= 1000)
      );
    },
    {
      message: errors.forms.paymentMethod.minimumBilletValue,
      path: ['amount'],
    },
  )
  .refine(
    (data) => {
      if (
        (data.paymentMethod === 'BILLET' || data.paymentMethod === 'PIX') &&
        data.amount &&
        data.amount > 1_000_000_000
      )
        return false;

      return true;
    },
    {
      message: errors.forms.paymentMethod.maxValue,
      path: ['amount'],
    },
  );

const ActivateUsersWithDepositFormSchema = z.object({
  /** Set of employeeIds that will be activated */
  employeeIds: z.set(z.string()).min(1, errors.forms.generic.required),
  /** boolean indicating whether deposit should be required or not */
  requiredDeposit: z.literal(true),
  /** first deposit for all employees being activated */
  deposit: DepositSchema,
});

export const ActivateUsersFormSchema = z.union([
  z.object({
    /** Set of employeeIds that will be activated */
    employeeIds: z.set(z.string()).min(1, errors.forms.generic.required),
    /** boolean indicating whether deposit should be required or not */
    requiredDeposit: z.literal(false),
    /** optional first deposit for all employees being activated */
    deposit: DepositPartialSchema,
  }),
  ActivateUsersWithDepositFormSchema,
]);
