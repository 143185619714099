import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import TextField from '@frontend/components/inputs/TextField';
import React, { useState } from 'react';
import Typography from '../../Typography';
import { ModalV2 } from '../ModalV2';

type IconName = React.ComponentProps<typeof Icons>['name'];

type Props = {
  variant: 'default' | 'error' | 'success';
  isOpen: boolean;
  onClose: () => void;
  text: {
    highlightedText?: string;
    highlightedSize?: 'default' | 'compact';
    highlightVariant?: 'announcement' | 'success' | 'warning';
    title: string;
    body: React.ReactNode;
  };
  buttons?: {
    confirm: {
      disabled?: boolean;
      loading?: boolean;
      text: string;
      onClick: () => void;
      icon?: IconName;
    };
    cancel?: {
      text: string;
      onClick: () => void;
      variant?: 'default' | 'neutral';
      icon?: IconName;
    };
    secondary?: {
      text: string;
      loading?: boolean;
      onClick: () => void;
      icon?: IconName;
    };
    action?: {
      text: string;
      loading?: boolean;
      onClick: () => void;
      icon?: IconName;
    };
  };
  confirmationWord?: string;
};

export default function ConfirmationModal(props: Props) {
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [confirmationWordError, setConfirmationWordError] = useState(false);
  const [word, setWord] = useState('');

  function checkConfirmationWord(event: React.ChangeEvent<HTMLInputElement>) {
    const newWord = event.target.value;
    setWord(newWord);

    if (
      props.confirmationWord?.length &&
      props.confirmationWord.toUpperCase() === newWord?.toUpperCase()
    ) {
      setConfirmationChecked(true);
      return;
    }

    if (confirmationChecked) {
      setConfirmationChecked(false);
    }
  }

  function handleOnClick() {
    if (!props.confirmationWord || confirmationChecked) {
      props.buttons?.confirm.onClick();
      return;
    }

    setConfirmationWordError(true);
  }

  return (
    <ModalV2
      open={props.isOpen}
      onClose={props.onClose}
      highlight={
        props.text.highlightedText
          ? {
              description: props.text.highlightedText,
              size: props.text.highlightedSize,
              variant:
                props.text.highlightVariant ??
                (props.variant === 'error' ? 'warning' : 'success'),
            }
          : undefined
      }
      size="sm"
      title={props.text.title}
      actions={{
        ...(props.buttons?.confirm && {
          primary: {
            description: props.buttons.confirm.text,
            onClick: handleOnClick,
            disabled: props.buttons.confirm.disabled,
            loading: props.buttons.confirm.loading,
            color: props.variant,
            icon: props.buttons.confirm.icon,
          },
        }),
        ...(props.buttons?.secondary && {
          secondary: {
            ...props.buttons.secondary,
            loading: props.buttons.secondary.loading,
            description: props.buttons.secondary.text,
          },
        }),
        ...(props.buttons?.cancel && {
          cancel: {
            ...props.buttons.cancel,
            description: props.buttons.cancel.text,
          },
        }),
      }}
    >
      <Flex direction="column" gap="xs">
        <Typography.Body4 color="neutral_40">
          {props.text.body}
        </Typography.Body4>

        {props.confirmationWord && (
          <TextField
            fullWidth
            label={'Digite ' + props.confirmationWord.toUpperCase()}
            onChange={checkConfirmationWord}
            value={word}
            error={
              confirmationWordError
                ? 'Palavra digitada incorreta. Verifique e tente novamente.'
                : undefined
            }
          />
        )}

        {props.buttons?.action && (
          <LinkButton
            variant="default"
            onClick={props.buttons.action.onClick}
            style={{ alignSelf: 'center' }}
          >
            {props.buttons.action.text}
          </LinkButton>
        )}
      </Flex>
    </ModalV2>
  );
}
