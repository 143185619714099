import Flex from '@frontend/components/frames/Flex';
import SummaryFeedback from '@frontend/components/SummaryFeedback';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import SummaryModal from '../../../01-Form/components/SummaryModal/SummaryModal';
import { useDepositSummaryData } from '../../data/useSummaryData';

type Props = {
  form: UseFormReturn;
};

export function FailedActivationSummary(props: Props) {
  const data = useDepositSummaryData({ form: props.form });
  const couldNotActivateEmployees = data.activation.couldNotActivateEmployees;

  return (
    <SummaryFeedback
      icon="IconX"
      title={couldNotActivateEmployees?.formattedAsText ?? ''}
      onClickViewMore={
        couldNotActivateEmployees?.employeeIds
          ? () => {
              ModalService.show(SummaryModal, {
                employeeIds: couldNotActivateEmployees?.employeeIds,
                form: props.form,
              });
            }
          : undefined
      }
    >
      {props.form.result?.activation?.status === 'PARTIAL' && (
        <Flex direction="column" padding="xs2">
          <Typography.Body4 color="neutral_20" weight={600}>
            Não foi possível ativar o uso corporativo dessas pessoas
          </Typography.Body4>
          <Flex>
            <Typography.Caption color="neutral_40">
              Tivemos um erro ao processar as informações. Tente novamente e, se
              o erro persistir, entre em contato com o nosso atendimento.
            </Typography.Caption>
          </Flex>
        </Flex>
      )}
    </SummaryFeedback>
  );
}
