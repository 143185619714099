import React from 'react';

import { IconsProps, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import * as SC from './styled';

type ModalV2Props = {
  open: boolean;
  onClose?(): void;
  loading?: boolean;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  highlight?: {
    description: string;
    size?: 'default' | 'compact';
    /**
     * @default announcement
     */
    variant?: 'announcement' | 'success' | 'warning';
    icon?: IconsProps['name'];
  };
  bgImageUrl?: string;
  /**
   * Add footer with button actions
   */
  actions?: React.ComponentProps<typeof ModalFooter>;
  /**
   * @default md
   */
  size?: 'sm' | 'md' | 'lg';
};

export function ModalV2(props: ModalV2Props) {
  const hasActions =
    props.actions?.cancel || props.actions?.primary || props.actions?.secondary;

  function onClose() {
    void props.onClose?.();
  }

  return (
    <SC.Modal open={props.open} onClose={onClose}>
      {props.onClose && (
        <SC.CloseContainer>
          <PillButton
            onClick={onClose}
            icon="IconX"
            variant="default"
            size="extra-small"
            type="primary"
          />
        </SC.CloseContainer>
      )}

      <SC.Container size={props.size ?? 'md'}>
        <ModalHeader
          title={props.title}
          subtitle={props.subtitle}
          bgImageUrl={props.bgImageUrl}
          highlight={props.highlight}
        />

        <SC.Content>{props.children}</SC.Content>
      </SC.Container>

      {hasActions && <ModalFooter {...props.actions} />}
    </SC.Modal>
  );
}
