import Button from '@frontend/components/Button';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
`;
