import { DatePicker } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import TextField from '@frontend/components/inputs/TextField';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import Spacer from '@frontend/components/Spacer';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { Controller } from 'react-hook-form';
import { AutomaticCredit } from './AutomaticCredit';
import {
  EditAutomaticCreditProvider,
  EditDepositModalProps,
  useEditDepositContext,
} from './context';

function Content() {
  const controller = ModalService.useModalController();
  const context = useEditDepositContext();

  function isDisabledDate(date: Date): boolean {
    return (
      DateTime.now().startOf('day').diff(DateTime.fromJSDate(date), 'days')
        .days > 0
    );
  }

  return (
    <DefaultModal
      isOpen={controller.visible}
      onClose={controller.remove}
      title={
        context.isAutomaticCredit
          ? 'Editar depósito recorrente'
          : 'Editar depósito único'
      }
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: controller.remove,
        },
        confirm: {
          text: context.isLoading ? 'Salvando...' : 'Confirmar',
          loading: context.isLoading,
          onClick: context.isLoading ? () => null : context.onSubmit,
        },
      }}
    >
      <Spacer y="xs3" />

      <Flex direction="column" gap="xs1">
        <Controller
          control={context.form}
          name="amount"
          render={(controller) => (
            <TextField
              fullWidth
              label="Valor do depósito"
              value={
                controller.field.value ? toCurrency(controller.field.value) : ''
              }
              onChange={(e) =>
                controller.field.onChange(
                  e.target.value
                    ? Number(e.target.value.replace(/\D/g, ''))
                    : 0,
                )
              }
              error={controller.fieldState.error?.message}
              helperText={controller.fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={context.form}
          name="creditDate"
          render={(controller) => (
            <DatePicker
              onDateChange={(date) => {
                controller.field.onChange(date?.toDate());
              }}
              value={controller.field.value}
              label="Data de disponibilização"
              disabledDate={isDisabledDate}
            />
          )}
        />

        {context.isAutomaticCredit && <AutomaticCredit />}
      </Flex>
    </DefaultModal>
  );
}

function Provider(props: EditDepositModalProps) {
  return (
    <EditAutomaticCreditProvider {...props}>
      <Content />
    </EditAutomaticCreditProvider>
  );
}

export const EditDepositModal = ModalService.create(Provider);
