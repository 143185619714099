import FeatureFlagService from '@frontend/services/FeatureFlagService';
import PlanFeaturesService from '@frontend/services/PlanFeatures';

type UseExternalCardsHook = {
  /**
   * Check if the feature flag is active
   *
   * Flag: FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS_V2
   */
  useV2: boolean;
  /**
   * Check if the feature flag is active and if it has BASIC_CARDS functionality
   *
   * Flag: FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS
   */
  enabled: boolean;
};

export function useExternalCards(): UseExternalCardsHook {
  const externalCardsEnabled = PlanFeaturesService.get('BASIC_CARDS');

  return {
    useV2: FeatureFlagService.getFlag('externalCardsV2'),
    enabled: externalCardsEnabled,
  };
}
