import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';

export const FormSchema = z.object({
  format: z.enum(['CSV', 'PDF', 'XLSX'], {
    required_error: errors.forms.generic.required,
  }),
  template: z.literal('expense-employees'),
  date: z.date({
    invalid_type_error: errors.forms.date.invalid,
    required_error: errors.forms.generic.required,
  }),
});

export type FormSchemaOutput = z.output<typeof FormSchema>;
