import Typography from '@frontend/components/Typography';
import * as SC from './styled';

import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';

type DrawerHeaderProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: IconsProps['name'];
};

export function DrawerHeader(props: DrawerHeaderProps) {
  return (
    <SC.Header>
      {props.icon && (
        <Icon
          name={props.icon}
          color="neutral_40"
          background="neutral"
          size={32}
        />
      )}

      <div>
        <Typography.Headline7 color="neutral_10">
          {props.title}
        </Typography.Headline7>

        {props.subtitle && (
          <Typography.Body3 color="neutral_50">
            {props.subtitle}
          </Typography.Body3>
        )}
      </div>
    </SC.Header>
  );
}
