import { useRef } from 'react';

import { IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import CardSelectErrorMessage from './CardSelectErrorMessage';
import CardSelectTag from './CardSelectTag';
import * as SC from './styled';

type Props = {
  icon: IconTypes;
  title: string;
  description: string;
  onClick: () => void;
  selected: boolean;
  className?: string;
  tag?: {
    description: string;
    variant: React.ComponentProps<typeof CardSelectTag>['variant'];
  };
  disabled?: boolean;
  errorMessage?: string;
};

export default function RadioButtonCard(props: Props) {
  const ref = useRef();

  return (
    <SC.Container>
      <SC.Button
        selected={props.selected}
        disabled={props.disabled}
        className={props.className}
        onClick={() => !props.disabled && props.onClick()}
      >
        <SC.TopContainer>
          <Icon
            background={
              props.disabled || !props.selected
                ? ('neutral' as const)
                : ('default' as const)
            }
            color={
              props.disabled || !props.selected ? 'neutral_40' : 'secondary_50'
            }
            name={props.icon}
            size={32}
          />
          <SC.RadioButton
            inputRef={ref}
            onChange={props.onClick}
            checked={props.selected}
          />
        </SC.TopContainer>
        <Typography.Body4 weight={700}>{props.title}</Typography.Body4>
        {props.description && (
          <SC.Description>{props.description}</SC.Description>
        )}
        {props.tag && (
          <CardSelectTag
            description={props.tag.description}
            variant={props.tag.variant}
            disabled={props.disabled}
          />
        )}
      </SC.Button>

      {props.errorMessage && (
        <CardSelectErrorMessage>{props.errorMessage}</CardSelectErrorMessage>
      )}
    </SC.Container>
  );
}
