import Typography from '@frontend/components/Typography';
import Spacer from '@frontend/components/Spacer';
import * as styles from '../../styled';
import { Card } from '../../components/Card';
import useMenuActions from './table/useMenuActions';
import { UseGetExternalCardsOutput } from './data/useGetExternalCards';
import { capitalizeWords } from 'backend/src/utils/masks/capitalizeWords';
import ExternalCardsTable from './table';

type Props = {
  externalCards: UseGetExternalCardsOutput;
  view: 'grid' | 'list';
};

export default function ExternalCardsSection(props: Props) {
  const actions = useMenuActions();

  if (!props.externalCards.enabled || !props.externalCards.items.length)
    return null;

  return (
    <>
      <Typography.Headline8>Terceiros</Typography.Headline8>
      <Spacer y="xs" />
      {props.view === 'grid' ? (
        <styles.HorizontalList>
          {props.externalCards.items.map((item, index) => (
            <Spacer key={index} useMargin x="xs">
              <Card
                balances={new Set(['CORPORATE'])}
                provider={item.provider}
                cardName={item.provider}
                cardNumber={item.cardNumber}
                cardType={item.cardType}
                ownerName={capitalizeWords(item.name)}
                status={item.active ? 'ENABLED' : 'DISABLED'}
                menuOptions={actions()}
              />
            </Spacer>
          ))}
        </styles.HorizontalList>
      ) : (
        <ExternalCardsTable
          loading={props.externalCards.isLoading}
          data={props.externalCards.items}
        />
      )}
      <Spacer y="xs" />
    </>
  );
}
