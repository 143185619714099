import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import * as styles from '../../styled';
import { Card } from '../../components/Card';
import { capitalizeWords } from '@frontend/utils/masks';
import PlasticCardsTable from './table';
import useMenuActions from './table/useMenuActions';
import { UseGetPlasticCardsReturn } from './data/useGetPlasticCards';

type Props = {
  plasticCards: UseGetPlasticCardsReturn;
  view: 'grid' | 'list';
};

export default function PlasticCardsSection(props: Props) {
  const actions = useMenuActions();

  if (!props.plasticCards.items.length) return null;

  return (
    <>
      <Typography.Headline8>Físico</Typography.Headline8>
      <Spacer y="xs" />
      {props.view === 'grid' ? (
        <styles.HorizontalList>
          {props.plasticCards.items.map((item, index) => (
            <Spacer key={index} useMargin x="xs">
              <Card
                balances={new Set(item.balances)}
                cardName={item.cardName}
                cardNumber={item.cardNumber ?? ''}
                cardType={item.paymentType}
                menuOptions={actions(item)}
                ownerName={capitalizeWords(item.ownerName ?? '')}
                policy={item.policy}
                provider={item.provider}
              />
            </Spacer>
          ))}
        </styles.HorizontalList>
      ) : (
        <PlasticCardsTable
          loading={props.plasticCards.isLoading}
          data={props.plasticCards.items}
        />
      )}
      <Spacer y="m" />
    </>
  );
}
