import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, httpLink, splitLink } from '@trpc/client';
import React from 'react';

import { getAccessToken } from '@flash-tecnologia/hros-web-utility';
import EnvService from '@frontend/services/EnvService';
import { createTRPCReact } from '@trpc/react-query';
import superjson from 'superjson';
import { services } from '../services';

import type { AppRouter } from '@flash-tecnologia/corporate-card-bff';
import AuthService from '@frontend/services/AuthService';
export type {
  RouterInputs,
  RouterOutputs,
} from '@flash-tecnologia/corporate-card-bff';

const url = services.bff[EnvService.BUILD_ENV];

export const trpc = createTRPCReact<AppRouter>();

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient({
    logger: {
      log: () => {},
      warn: () => {},
      error: () => {},
    },
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const company = AuthService.useCompany();

  async function headers() {
    const accessToken = await getAccessToken();
    const flashAuth = url.includes('localhost')
      ? accessToken
      : `Bearer ${accessToken}`;
    return {
      authorization: accessToken,
      ['company-id']: company?.id,
      ['x-flash-auth']: flashAuth,
    };
  }

  const trpcClient = trpc.createClient({
    transformer: superjson,
    links: [
      splitLink({
        condition(op) {
          return !!op.context.skipBatch;
        },
        true: httpLink({
          url,
          headers,
        }),
        false: httpBatchLink({
          url,
          headers,
        }),
      }),
    ],
  });
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
