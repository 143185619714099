import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import React, { useState } from 'react';

import { getAccessToken } from '@flash-tecnologia/hros-web-utility';
import EnvService from '@frontend/services/EnvService';
import { createTRPCReact } from '@trpc/react-query';
import superjson from 'superjson';
import { services } from '../services';

import AuthService from '@frontend/services/AuthService';
import type { AppRouter } from 'backend';
export type { RouterInputs, RouterOutputs } from 'backend';

const url = services.bff[EnvService.BUILD_ENV];

export const trpc = createTRPCReact<AppRouter>();

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        logger: {
          log: () => {},
          warn: () => {},
          error: () => {},
        },
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
  );
  const company = AuthService.useCompany();

  const trpcClient = trpc.createClient({
    transformer: superjson,
    links: [
      httpBatchLink({
        url,
        async headers() {
          const accessToken = await getAccessToken();
          return {
            authorization: accessToken,
            ['company-id']: company?.id,
            ['x-flash-auth']: accessToken,
          };
        },
      }),
    ],
  });
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
