import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '../Icon';
import Typography from '../Typography';
import { useVersion } from './context';
import { Feature } from './context/types';
import { useCalloutData } from './New/useCalloutData';
import * as SC from './styled';

type Props = {
  feature: Feature;
};

export default function VersionCalloutNew(props: Props) {
  const context = useVersion(props.feature);

  if (!context.showCallout) {
    return null;
  }

  const data = useCalloutData(props.feature);

  return (
    <SC.Container useNewVersion background={data.background}>
      <SC.IconContainer background={data.icon.background}>
        <Icon name={data.icon.name} size={16} color={data.icon.color} />
      </SC.IconContainer>

      <Typography.Body4 weight={700} color={data.titleColor}>
        {data.title}
      </Typography.Body4>

      <Typography.Body4 color={data.messageColor}>
        {data.message}
      </Typography.Body4>

      <SC.ActionContainer>
        <PillButton
          icon="IconArrowRight"
          size="small"
          label={data.action.label}
          type="primary"
          variant={data.action.variant}
          onClick={() => context.onChangeVersion()}
        />
      </SC.ActionContainer>
    </SC.Container>
  );
}
