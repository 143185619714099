import { ComboBoxField } from '@frontend/components/inputs/ComboBoxField/ComboBoxField';
import { ROUTE_Employees } from '@frontend/routes/ExternalRoutes';
import RouterService from '@frontend/services/RouterService';
import { trpc } from '@frontend/trpc';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { useGetEmployees } from '../../../../../data/useGetEmployees';

type Props = {
  form: UseFormReturn;
};

export function EmployeeIds(props: Props) {
  const employees = useGetEmployees();
  const navigate = RouterService.useNavigate();

  function handleNavigateToEmployees() {
    navigate({
      route: ROUTE_Employees,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  const context = trpc.useContext();

  return (
    <Controller
      control={props.form.control}
      name="employeeIds"
      render={(ctx) => (
        <ComboBoxField
          icon="IconUsers"
          label="Selecione uma ou mais pessoas"
          onChange={(newValue) => ctx.field.onChange(newValue)}
          value={ctx.field.value}
          fetch={(data) => {
            void employees.fetch(data);
          }}
          setSelected={(checked, selectedValue) => {
            void context.corporateCard.employees.search.setData(
              { activeOnly: true },
              (oldData) => {
                if (selectedValue) {
                  return oldData?.map((data) => ({
                    ...data,
                    selected:
                      data.id === selectedValue ? checked : data.selected,
                  }));
                }
                return oldData
                  ?.filter((option) => !option.alreadyActive)
                  .map((data) => ({
                    ...data,
                    selected: checked,
                  }));
              },
            );
          }}
          options={employees.employees}
          error={ctx.fieldState.error?.message}
          loading={employees.loading}
          emptyState={{
            allDisabled:
              'Busque por alguma pessoa para encontrar alguém que ainda não use cartão corporativo',
            filtered: {
              title: 'Nenhum resultado encontrado',
              description:
                'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
              linkButton: {
                label: 'Ir para Equipe',
                onClick: handleNavigateToEmployees,
              },
            },
            unfiltered: {
              title: 'Nenhuma pessoa disponível',
              description:
                'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
              linkButton: {
                label: 'Ir para Equipe',
                onClick: handleNavigateToEmployees,
              },
            },
          }}
          errorState={{
            isError: employees.isError,
            title: 'Não foi possível carregar a lista de pessoas',
            description:
              'Tente novamente e, caso o erro persista, entre em contato com o nosso atendimento.',
            linkButton: {
              label: 'Tente novamente',
              onClick: () => {
                void employees.refresh();
              },
            },
          }}
        />
      )}
    />
  );
}
