import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { Controller } from 'react-hook-form';
import useForm from './useForm';

type Props = {
  cardId: string;
  newStatus: 'BLOCKED' | 'ENABLED';
};

function UpdateCardStatusModal(props: Props) {
  const modalController = ModalService.useModalController();
  const form = useForm({ cardId: props.cardId, newStatus: props.newStatus });
  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Confirmar',
          onClick: form.onSubmit,
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      loading={form.isLoading}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title:
          props.newStatus === 'BLOCKED'
            ? 'Bloquear cartão'
            : 'Desbloquear cartão',
        body: (
          <>
            <Typography.Body3 center>
              Digite sua senha de transação para{' '}
              <strong>
                {props.newStatus === 'BLOCKED' ? 'bloquear' : 'desbloquear'}
              </strong>{' '}
              o cartão
            </Typography.Body3>
            <Spacer y="m" />
            <Controller
              control={form.control}
              name="password"
              render={(controller) => (
                <TextField
                  autoComplete="new-password"
                  fullWidth
                  label="Senha de transação"
                  type={'password'}
                  value={controller.field.value}
                  onChange={controller.field.onChange}
                  error={controller.fieldState.invalid}
                  helperText={controller.fieldState.error?.message}
                />
              )}
            />
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(UpdateCardStatusModal);
