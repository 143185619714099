import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import React from 'react';
import Typography from '../../Typography';
import { ModalV2 } from '../ModalV2';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  /**
   * @default sm
   */
  size?: 'sm' | 'md' | 'lg';
  bgImageUrl?: string;
  highlight?: {
    description: string;
    size?: 'default' | 'compact';
    /**
     * @default announcement
     */
    variant?: 'announcement' | 'success' | 'warning';
  };
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  buttons?: {
    confirm: {
      loading?: boolean;
      text: string;
      disabled?: boolean;
      onClick: () => void;
      color?: 'default' | 'success' | 'info' | 'error' | 'neutral';
      icon?: React.ComponentProps<typeof Icons>['name'];
    };
    cancel?: {
      text: string;
      onClick: () => void;
    };
  };
};

export default function DefaultModal(props: Props) {
  return (
    <ModalV2
      open={props.isOpen}
      size={props.size ?? 'sm'}
      highlight={props.highlight}
      onClose={props.onClose}
      bgImageUrl={props.bgImageUrl}
      title={props.title}
      actions={{
        ...(props.buttons?.cancel && {
          cancel: {
            description: props.buttons.cancel.text,
            onClick: props.buttons.cancel.onClick,
          },
        }),
        ...(props.buttons?.confirm && {
          primary: {
            ...props.buttons.confirm,
            description: props.buttons.confirm.text,
          },
        }),
      }}
    >
      {props.subtitle && (
        <>
          <Typography.Body4 color="neutral_40">
            {props.subtitle}
          </Typography.Body4>

          <Spacer y="xs" />
        </>
      )}

      {props.children}
    </ModalV2>
  );
}
