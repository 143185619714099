import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { useDepositSummaryData } from '../../data/useSummaryData';

export function DepositSucceeded(
  data: ReturnType<typeof useDepositSummaryData>,
) {
  if (!data.deposit.paymentDetails) return null;
  return (
    <Flex padding={'xs2'} gap="xs2" direction="column">
      <Flex gap="xs4" justify="space-between" align="center">
        <Flex gap="xs4">
          <Tag
            size="xs"
            variant={data.deposit.paymentDetails.paymentStatus.variant}
            dot
          >
            {data.deposit.paymentDetails.paymentStatus.label}
          </Tag>
          {data.deposit.paymentDetails.paymentMethod && (
            <Tag size="xs" variant="neutral">
              <Flex align="center" gap="xs5">
                <Icon
                  name={data.deposit.paymentDetails.paymentMethod.icon}
                  color="neutral_10"
                  size={14}
                />
                {data.deposit.paymentDetails.paymentMethod.label}
              </Flex>
            </Tag>
          )}
        </Flex>
      </Flex>
      <Flex backgroundColor="neutral_95" radius="s" padding={['xs4', 'xs3']}>
        <Flex gap="xs4" align="center" grow={1}>
          <Icon name="IconRepeat" color="neutral_40" background="neutral" />
          <Flex direction="column">
            <Typography.Body4 color="neutral_40" weight={600}>
              {data.deposit.depositType}
            </Typography.Body4>
            <Typography.Caption color="neutral_40">
              {data.deposit.creditDateDescription}
            </Typography.Caption>
          </Flex>
        </Flex>
        <Flex gap="xs4" align="center" grow={1}>
          <Icon name="IconCoin" color="neutral_40" background="neutral" />
          <Flex direction="column">
            <Typography.Body4 color="neutral_40" weight={600}>
              {data.deposit.depositAmount.totalAmount}
            </Typography.Body4>
            <Typography.Caption color="neutral_40">
              {data.deposit.depositAmount.individualAmount}
            </Typography.Caption>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
