import { ComboBoxField } from '@frontend/components/inputs/ComboBoxField/ComboBoxField';
import { ROUTE_Employees } from '@frontend/routes/ExternalRoutes';
import RouterService from '@frontend/services/RouterService';
import { Controller } from 'react-hook-form';
import { useGetEmployees } from '../../../data/useGetEmployees';
import { UseFormReturn } from '../../../form/useForm';

type Props = {
  form: UseFormReturn;
};

export function EmployeeIds(props: Props) {
  const employees = useGetEmployees();
  const navigate = RouterService.useNavigate();

  function handleNavigateToEmployees() {
    navigate({
      route: ROUTE_Employees,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  return (
    <Controller
      control={props.form.control}
      name="employeeIds"
      render={(ctx) => (
        <ComboBoxField
          icon="IconUsers"
          label="Selecione uma ou mais pessoas"
          onChange={(newValue) => ctx.field.onChange(newValue)}
          value={ctx.field.value}
          options={employees.employees}
          error={ctx.fieldState.error?.message}
          loading={employees.loading}
          emptyState={{
            allDisabled:
              'Todas as pessoas cadastradas em Equipe já usam cartão corporativo',
            filtered: {
              title: 'Nenhum resultado encontrado',
              description:
                'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
              linkButton: {
                label: 'Ir para Equipe',
                onClick: handleNavigateToEmployees,
              },
            },
            unfiltered: {
              title: 'Nenhuma pessoa disponível',
              description:
                'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
              linkButton: {
                label: 'Ir para Equipe',
                onClick: handleNavigateToEmployees,
              },
            },
          }}
          errorState={{
            isError: employees.isError,
            title: 'Não foi possível carregar a lista de pessoas',
            description:
              'Tente novamente e, caso o erro persista, entre em contato com o nosso atendimento.',
            linkButton: {
              label: 'Tente novamente',
              onClick: () => {
                void employees.refresh();
              },
            },
          }}
        />
      )}
    />
  );
}
