import RouterService from '@frontend/services/RouterService';
import { Navigate, Route } from 'react-router-dom';
import BasePath from '../../../routes/BasePath';
// import Deposits from './Deposits'; // TODO: Remove in next PR

export const ROUTE_Deposit = RouterService.create(
  `${BasePath.relativePath}/transactions/deposits`,
);

export const RouteComponent_Deposit = () => {
  return (
    <Route
      path={ROUTE_Deposit.path}
      element={<Navigate to="/expense/statement/cards" />}
    />
  );
};

// /expense/statement/cards
