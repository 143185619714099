import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';
import { Controller } from 'react-hook-form';
import { CardSetup } from './CardSetup';

export const Cards = ({ form }) => {
  return (
    <ModuleContainer gap="16px" padding="32px">
      <Flex direction="column" gap="xs3">
        <Typography.Headline8 color="neutral_20">
          Defina os detalhes do cartão virtual
        </Typography.Headline8>
        <Typography.Body4 color="neutral_50">
          Adicione quantos cartões forem necessários e personalize de acordo com
          o uso.
        </Typography.Body4>
      </Flex>
      <Controller
        name="cards"
        control={form.control}
        render={(props) => {
          const cards = props.field.value;
          const employee = form.getValues('employee') || {};
          const errors = props.formState.errors.cards || [];

          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              {cards.map((card, i) => {
                const setCardMetadata = (card) => {
                  const updatedCardList = cards;
                  updatedCardList[i] = card;

                  props.field.onChange(updatedCardList);
                };

                return (
                  <CardSetup
                    card={card}
                    cardIndex={i}
                    setCardMetadata={setCardMetadata}
                    key={i}
                    employeeName={employee.name || ''}
                    error={errors[i] || {}}
                  />
                );
              })}
              {/* <LinkButton
                variant={'primary'}
                onClick={() => props.field.onChange([...cards, {}])}
                style={{ alignSelf: 'center' }}
              >
                Adicionar mais um cartão virtual +
              </LinkButton> */}
            </div>
          );
        }}
      />
    </ModuleContainer>
  );
};
