import errors from '@frontend/utils/commonTexts/errors';
import { DateTime } from 'luxon-business-days';
import { z } from 'zod';

export const DepositFormSchema = z
  .object({
    /* -------------------------- Authentication -------------------------- */
    /** OTP sent to the admin's email */
    confirmationToken: z.string().optional(),
    /** Whether the user has confirmed proceeding with a duplicate deposit */
    duplicateWarningConfirmation: z.boolean().optional(),

    /* ------------------------ Basic deposit setup ----------------------- */
    /** List of deposits imported from the spreadsheet */
    deposits: z.array(
      z.object({
        /** EmployeeId */
        id: z.string(),
        /** Employee's document number */
        documentNumber: z.string(),
        /** Employee's name */
        name: z.string(),
        /** Employee's deposit value in BRL cents */
        value: z.number(),
      }),
    ),
    /** Date of the (first) employee credit */
    creditDate: z
      .date({
        errorMap: () => ({
          message: errors.forms.date.invalid,
        }),
      })
      .min(DateTime.now().startOf('day').toJSDate(), errors.forms.date.future)
      .max(
        DateTime.now().plus({ months: 6 }).toJSDate(),
        errors.forms.date.tooFarInFuture,
      ),
    /** Payment method for the deposit */
    paymentMethod: z.enum(['FLASH_CASH', 'PIX', 'BILLET'], {
      required_error: errors.forms.generic.required,
    }),

    /* ------------------------- Expiration setup ------------------------- */
    /** Whether the credited balance should expire at the `expirationDate`. Only valid for single deposits */
    expires: z.boolean().catch(false),
    /** Expiration date of the credited balance. Only valid for single deposits, when `expires: true` */
    expirationDate: z
      .date({
        invalid_type_error: errors.forms.date.invalid,
      })
      .optional(),

    /* ------------------------- Recurrence setup ------------------------- */
    /** Whether the deposit should be recurrent. */
    automatic: z.boolean().catch(false),
    /** Whether the deposit should only complement the employee balance to a target amount. Only valid for automatic deposits */
    complementary: z.boolean().catch(false),
  })

  .refine(
    // When `expires` is enabled, `expirationDate` is required
    (data) => {
      if (data.expires) return Boolean(data.expirationDate);
      return true;
    },
    {
      message: errors.forms.generic.required,
      path: ['expirationDate'],
    },
  )
  .refine(
    /** Checks if billet deposits are at least 3 business days in the future */
    (data) => {
      if (data.paymentMethod === 'BILLET' && data.creditDate) {
        const createDateFrom = DateTime.now()
          .plusBusiness({ days: 3 })
          .startOf('day')
          .toJSDate();

        if (data.creditDate < createDateFrom) {
          return false;
        }
      }
      return true;
    },
    {
      message:
        'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
      path: ['creditDate'],
    },
  )
  .refine(
    (data) => {
      if (
        data.paymentMethod === 'BILLET' &&
        data.deposits.reduce((total, deposit) => total + deposit.value, 0) <
          1000
      )
        return false;

      return true;
    },
    {
      message: errors.forms.paymentMethod.minimumBilletValue,
      path: ['paymentMethod'],
    },
  )
  .refine(
    (data) => {
      if (
        (data.paymentMethod === 'BILLET' || data.paymentMethod === 'PIX') &&
        data.deposits.reduce((total, deposit) => total + deposit.value, 0) >
          1_000_000_000
      )
        return false;

      return true;
    },
    {
      message: errors.forms.paymentMethod.maxValue,
      path: ['paymentMethod'],
    },
  );
