import LazyComponent from '@frontend/components/LazyComponent';

export { RequestBalanceTab } from '@frontend/pages/Management/tabs/RequestBalanceSection';
export { RequestBalanceApprovalTab } from '@frontend/pages/Management/tabs/RequestBalanceSectionV2';
export { CustomizationsTab } from './Customizations';
export { FirstStepsChecker } from './FirstStepsChecker';
export { showActivateUsers } from './Onboarding/showActivateUsers';
export { ExternalRoot } from './Root';
export {
  showAddFlashCashModal,
  showCancelAutomaticCreditModal,
  showEditAutomaticCreditModal,
} from './StatementSection';
export { Version } from './Version';

export function DashboardSection() {
  return <LazyComponent factory={() => import('./DashboardSection')} />;
}

type ReportSectionProps = {
  session: 'expenses' | 'corp-card';
  expensesVersion: 'v1' | 'v2';
};
export function ReportSection(props: ReportSectionProps) {
  return (
    <LazyComponent
      factory={() => import('./ReportsSection')}
      innerProps={props}
    />
  );
}

export function ReportSectionTabLabel() {
  return <LazyComponent factory={() => import('./ReportsSectionTabLabel')} />;
}

export function ReportNewsBanner() {
  return (
    <LazyComponent
      factory={() => import('./ReportsSection/components/ReportNewsBanner')}
    />
  );
}

export function CardsSection() {
  return (
    <LazyComponent factory={() => import('./CardsSection/CardsSection')} />
  );
}

export function EmptyStateSection() {
  return (
    <LazyComponent
      factory={() => import('./EmptyStateSection/EmptyStateSection')}
    />
  );
}
