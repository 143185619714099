import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import FormService from '@frontend/services/FormService';
import ModalService from '@frontend/services/ModalService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { DateTime } from 'luxon-business-days';
import React from 'react';
import InvalidActivationModal from '../../components/steps/01-Form/components/InvalidActivationModal/InvalidActivationModal';
import InvalidDepositModal from '../../components/steps/01-Form/components/InvalidDepositModal/InvalidDepositModal';
import { useActivateUsers } from '../../data/useActivateUsers';
import { ActivateUsersFormSchema } from './schema';

type Input = {
  onSuccess: () => void;
  onAuthError: () => void;
  requiredDeposit?: boolean;
};

export function useForm(input: Input) {
  const form = FormService.useCreateForm(ActivateUsersFormSchema, {
    mode: 'onChange',
    defaultValues: {
      employeeIds: new Set(),
      requiredDeposit: !!input.requiredDeposit,
      deposit: {
        automatic: false,
        complementary: false,
        expires: false,
      },
    },
  });
  /* --------------------- Additional schema validations -------------------- */
  const formValues = form.watch();

  /** When `expires` is enabled, disable `automatic` and `complementary` */
  React.useEffect(() => {
    if (formValues.deposit.expires) {
      form.setValue('deposit.automatic', false);
      form.setValue('deposit.complementary', false);
    } else {
      form.resetField('deposit.expirationDate');
    }
  }, [formValues.deposit.expires]);

  /** When `automatic` is enabled:
   * - disable `expires`;
   * - deselect PIX/BILLET payment method;
   */
  React.useEffect(() => {
    if (formValues.deposit.automatic) {
      form.setValue('deposit.expires', false);
      if (isIncluded(formValues.deposit.paymentMethod, ['BILLET', 'PIX'])) {
        form.resetField('deposit.paymentMethod');
      }
    }
  }, [formValues.deposit.automatic]);

  /** Checks if billet deposits are at least 3 business days in the future */
  React.useEffect(() => {
    if (!formValues.deposit.creditDate) return;

    if (formValues.deposit.paymentMethod === 'BILLET') {
      const createDateFrom = DateTime.now()
        .plusBusiness({ days: 3 })
        .startOf('day')
        .toJSDate();

      if (formValues.deposit.creditDate < createDateFrom) {
        form.setError('deposit.creditDate', {
          type: 'custom',
          message:
            'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
        });
        return;
      }
    }
    void form.trigger('deposit.creditDate');
  }, [formValues.deposit.creditDate, formValues.deposit.paymentMethod]);
  /* ------------------------------- On Submit ------------------------------ */
  const activateUsers = useActivateUsers();

  const onSubmit = form.handleSubmit(
    async function (values) {
      const { amount, creditDate, paymentMethod } = values.deposit;
      await activateUsers.mutateAsync({
        employeeIds: values.employeeIds,
        ...(values.requiredDeposit && {
          requiredDeposit: values.requiredDeposit,
        }),
        deposit:
          amount && creditDate && paymentMethod
            ? {
                ...values.deposit,
                amount,
                creditDate,
                paymentMethod,
              }
            : null,
        onSuccess: input.onSuccess,
        onAuthError: input.onAuthError,
      });
    },
    function (error) {
      if (formValues.requiredDeposit) {
        ModalService.show(InvalidDepositModal, {});
        ErrorMonitorService.error({
          message: 'Error while creating deposits',
          severity: 'fatal',
          extras: {
            zodErrors: JSON.stringify(error),
          },
        });

        return;
      }

      ModalService.show(InvalidActivationModal, {});
      ErrorMonitorService.error({
        message: 'Error while activating users',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(error),
        },
      });
    },
  );

  return {
    ...form,
    onSubmit,
    result: activateUsers.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
