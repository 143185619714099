import * as featureFlag from '@flash-tecnologia/feature-flags';
import AuthService from './AuthService';
import EnvService from './EnvService';

const activateUsersV2 = 'FLASH_OS_CORPORATE_CARD_ACTIVATE_USERS_V2';
const corporateCardRequest = 'FLASH_OS_CORPORATE_CARD_REQUEST_CARD';
const externalCards = 'FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS';
const externalCardsV2 = 'FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS_V2';
const policies = 'FLASH_OS_CORPORATE_CARD_POLICIES';
const uploadTransactions = 'FLASH_OS_CORPORATE_CARD_UPLOAD_TRANSACTIONS';
const exportReportAsPdf = 'FLASH_OS_CORPORATE_CARD_EXPORT_REPORT_AS_PDF';
const limitByCategoryPolicy =
  'FLASH_OS_CORPORATE_CARD_POLICY_LIMIT_BY_CATEGORY';
const accountManagement = 'FLASHOS_CORPORATE_CARD_MANAGEMENT_V2';

const FEATURES = {
  activateUsersV2,
  corporateCardRequest,
  externalCards,
  policies,
  uploadTransactions,
  exportReportAsPdf,
  externalCardsV2,
  limitByCategoryPolicy,
  accountManagement,
};

export default class FeatureFlagService {
  static getFlag(flagName: keyof typeof FEATURES) {
    featureFlag.WithFlag({
      flagName: FEATURES[flagName],
    });
    return featureFlag.useFlag({
      flagName: FEATURES[flagName],
    });
  }
}

export function FeatureFlagProvider(props: { children: React.ReactNode }) {
  const employeeId = AuthService.useEmployeeId();
  const company = AuthService.useCompany();
  return (
    <featureFlag.FlagsProvider
      appName={`flash_os&environment=${EnvService.BUILD_ENV}`}
      token={EnvService.UNLEASH_TOKEN}
      url={EnvService.UNLEASH_URL}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId: employeeId ?? '',
        properties: {
          employeeNanoId: employeeId ?? '',
          companyNanoId: company?.id ?? '',
          environment: EnvService.BUILD_ENV,
        },
      }}
    >
      {props.children}
    </featureFlag.FlagsProvider>
  );
}
