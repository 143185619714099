import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import { UseFormReturn } from '../../../../form/useForm';
import { DepositSummary } from '../DepositSummary/DepositSummary';
import { FailedActivationSummary } from '../FailedActivationSummary/FailedActivationSummary';
import { NextStepCards } from '../NextStepCards/NextStepCards';

type Props = {
  form: UseFormReturn;
};

export function Partial(props: Props) {
  return (
    <>
      <Flex direction="column" align="center">
        <Icon
          name="IconAlertCircle"
          background="info"
          color="info_40"
          size={48}
        />
        <Spacer y="xs4" />
        <Typography.Headline8 center>
          Uso corporativo ativado parcialmente
        </Typography.Headline8>
        <Spacer y="xs4" />
        <Typography.Body4 center color="neutral_40">
          Conseguimos ativar o uso corporativo para as seguintes pessoas:
        </Typography.Body4>
        <Spacer y="m" />
        <DepositSummary form={props.form} />
        <Spacer y="m" />
        <FailedActivationSummary form={props.form} />
      </Flex>
      <Spacer y="m" />
      <Typography.Headline9>Próximos passos</Typography.Headline9>
      <Spacer y="xs4" />
      <NextStepCards />
      <Spacer y="m" />
      <SurveyStore
        title="Como você avalia a facilidade de uso da plataforma ao ativar o uso corporativo?"
        type="activate-users-feedback"
      />
    </>
  );
}
