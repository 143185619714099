import React from 'react';

import ErrorMonitorService from '@frontend/services/MonitorService';
import { TrackingService } from '@frontend/services/TrackingService';
import { ErrorContent } from './ErrorContent';

type Props = {
  children: React.ReactNode;
  moduleName: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
};

export class Boundary extends React.Component<Props> {
  state = {
    hasError: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
      message: error?.message || 'Nenhum erro listado',
      stack: error.stack,
      page: location.href,
      createdAt: new Date().toISOString(),
    };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error });

    TrackingService.track('corporate_card_fatal_crashed', {
      stack: JSON.stringify(error?.stack),
      message: error?.message ?? '',
      moduleName: this.props.moduleName,
    });

    ErrorMonitorService.error({
      message: `[${this.props.moduleName}] - ${error.message}]`,
      error,
      severity: 'fatal',
      extras: {
        stack: error.stack,
        page: location.href,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContent
          moduleName={this.props.moduleName}
          title={this.props.title}
          description={this.props.description}
          printIsolatedError={this.props.printIsolatedError}
        />
      );
    }

    return this.props.children;
  }
}
