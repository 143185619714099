import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import useDeletePolicy from './useDeletePolicy';

type Props = {
  policyId: string;
  onSuccess?: () => void;
};

function DeletePolicyModal(props: Props) {
  const modalController = ModalService.useModalController();
  const deletePolicy = useDeletePolicy();

  async function handleConfirm() {
    await deletePolicy.mutateAsync({
      policyId: props.policyId,
      onSuccess: () => {
        modalController.remove();
        props.onSuccess?.();
      },
    });
  }

  return (
    <ConfirmationModal
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
        confirm: {
          text: 'Confirmar exclusão',
          onClick: () => {
            void handleConfirm();
          },
          loading: deletePolicy.isLoading,
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja excluir essa política?',
        body: (
          <>
            Ao excluir, todos os dados relacionados a ela serão perdidos, e os
            cartões vinculados ficarão sem regras de uso até que sejam
            associados a uma outra política
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(DeletePolicyModal);
