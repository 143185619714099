import Button from '@frontend/components/Button';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';

type Props = {
  onRetryClick: () => void;
  email: string;
};

export function PaymentFailed(props: Props) {
  return (
    <Flex direction="column" align="center" padding={'xs4'}>
      <Spacer y="xs4" />
      <Icon name="IconX" background="error" color="error_40" size={48} />
      <Spacer y="xs4" />
      <Typography.Headline8 center>
        Não foi possível gerar o código.
      </Typography.Headline8>
      <Spacer y="xs4" />
      <Typography.Body4 center color="neutral_40">
        Porém, ele também será enviado para o e-mail{' '}
        <Typography.Body4 weight={700} as="span">
          {props.email}.
        </Typography.Body4>
        Tente novamente e, se o erro persistir, entre em contato com o nosso
        atendimento.
      </Typography.Body4>
      <Spacer y="xs4" />
      <div>
        <Button
          icon="IconRefresh"
          variant="primary"
          color="error"
          onClick={props.onRetryClick}
        >
          Tentar novamente
        </Button>
      </div>
    </Flex>
  );
}
