import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import PolicyDrawer from '@frontend/external/CardsSection/drawers/PolicyDrawer';
import { useCheckIsCorporateCard } from '@frontend/pages/Statement/data/useCheckIsCorporateCard';
import ModalService from '@frontend/services/ModalService';
import conditionalList from '@frontend/utils/conditionalList';
import { useNavigate } from 'react-router-dom';
import { UseGetPlasticCardsReturn } from '../data/useGetPlasticCards';

type Item = UseGetPlasticCardsReturn['items'][number];

export default function useMenuActions() {
  const navigate = useNavigate();
  const { isCorporateCardUser } = useCheckIsCorporateCard();

  return function actions(
    item: Item,
  ): React.ComponentProps<typeof Menu>['options'] {
    return conditionalList([
      [
        true,
        {
          label: 'Ver extrato',
          onClick: () => navigate('/corporateCard/statement'),
        },
      ],
      [
        !!item.policy && isCorporateCardUser,
        {
          label: 'Ver política de uso corporativo',
          onClick: () =>
            ModalService.show(PolicyDrawer, {
              card: {
                bu: item.balances.includes('BENEFITS')
                  ? 'BENEFITS'
                  : 'EXPENSE_MANAGEMENT',
                cardName: item.cardName,
                lastFourDigits: item.cardNumber ?? '',
                policy: item.policy!,
                type: 'PLASTIC',
              },
            }),
        },
      ],
    ]);
  };
}
