import {
  Icons,
  LinkButton,
  PillButton,
  type IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import {
  ROUTE_CompanyCustomization,
  ROUTE_PlasticCards,
} from '@frontend/routes/ExternalRoutes';
import VirtualCard from '../../../../../../../../../../assets/cards/virtual_card_icon.svg';
import * as SC from './styled';

type IconType = IconsProps['name'] | React.ReactElement;

export function NextStepCards() {
  const cardData: Array<{
    icon: IconType;
    title: string;
    description: string;
    onClick: () => void;
  }> = [
    {
      icon: <VirtualCard />,
      title: 'Criar cartões virtuais corporativos',
      description: 'Você também pode criar cartões virtuais para as pessoas',
      onClick: () => {
        const url = new URL(
          '/corporateCard/user-accounts',
          window.location.origin,
        );
        url.searchParams.set('openCreateVirtualCardModal', 'true');
        window.open(url.toString(), '_blank');
      },
    },
    {
      icon: 'IconShieldCheck',
      title: 'Definir políticas para os cartões',
      description:
        'Limite o uso dos cartões corporativos de acordo com as regras da empresa',
      onClick: () => {
        const url = new URL(
          ROUTE_CompanyCustomization.path,
          window.location.origin,
        );
        url.searchParams.set('tab', 'corporate-card');
        window.open(url.toString(), '_blank');
      },
    },
    {
      icon: 'IconCreditCard',
      title: 'Pedir cartões físicos',
      description: 'Peça o cartão Flash para as pessoas que ainda não o tenham',
      onClick: () => {
        window.open(ROUTE_PlasticCards.path, '_blank');
      },
    },
  ];

  return (
    <SC.Container>
      {cardData.map((card) => (
        <SC.CardWrapper key={card.title}>
          <SC.CardContent>
            {typeof card.icon === 'string' ? (
              <PillButton
                icon={card.icon}
                variant="pink"
                size="extra-small"
                type="primary"
              />
            ) : (
              <SC.IconWrapper>{card.icon}</SC.IconWrapper>
            )}
            <Flex direction="column" grow={1}>
              <Typography.Headline9>{card.title}</Typography.Headline9>
              <Typography.Body4 color="neutral_40">
                {card.description}
              </Typography.Body4>
            </Flex>
            <LinkButton
              size="medium"
              variant="default"
              onClick={card.onClick}
              underlined={false}
            >
              Acessar
              <Spacer x="xs5" />
              <Icons name="IconArrowRight" size={12} />
            </LinkButton>
          </SC.CardContent>
        </SC.CardWrapper>
      ))}
    </SC.Container>
  );
}
