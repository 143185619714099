import {
  cardStatusGroupsToStatusMap,
  sortedCardStatusGroups,
} from '@frontend/utils/dataFormatters/cardStatus.dataFormatter';
import { useParamsState } from '@frontend/utils/hooks/searchParams';
import { z } from 'zod';

/** Params used to filter and paginate the users' table
 *
 * Also used to do optimistic updates when mutating the table
 */
export function useGetUsersInput() {
  const [tableParams, setTableParams] = useParamsState<TableParams>(
    {
      active: ['active'],
      pageNumber: 1,
      pageSize: 10,
    },
    tableParamsSchema,
  );

  const { pageSize, pageNumber, ...filters } = tableParams;

  const formattedFilters = {
    ...filters,
    ...(filters.balanceMin !== undefined &&
      filters.balanceMax !== undefined && {
        balance: {
          max: filters.balanceMax,
          min: filters.balanceMin,
        },
      }),
    ...(filters.cardStatus?.length && {
      cards: {
        status: filters.cardStatus.flatMap(
          (group) => cardStatusGroupsToStatusMap[group],
        ),
      },
    }),
  };

  const pagination = {
    pageNumber,
    pageSize,
  };

  return {
    input: {
      filter: formattedFilters,
      pagination: {
        ...pagination,
        currentPage: pageNumber - 1,
      },
      projection: {
        cardNumber: true,
        policy: true,
      },
    },
    pagination,
    tableParams,
    setTableParams,
  };
}

export const tableParamsSchema = z.object({
  // Filters
  active: z
    .string()
    .transform((data) =>
      z
        .array(z.enum(['active', 'disabled', 'inactive']))
        .catch([])
        .parse(data.split(',')),
    )
    .optional()
    .catch([]),
  balanceMax: z.coerce.number().optional().catch(undefined),
  balanceMin: z.coerce.number().optional().catch(undefined),
  cardStatus: z
    .string()
    .transform((data) =>
      z.array(z.enum(sortedCardStatusGroups)).catch([]).parse(data.split(',')),
    )
    .optional()
    .catch([]),
  stringInput: z.string().optional().catch(undefined),

  //Pagination
  pageNumber: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
});

export type TableParams = z.infer<typeof tableParamsSchema>;
