import { Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';

type Props = {
  children: React.ReactElement;
  value: string;
};

export function ValueTooltip(props: Props) {
  return (
    <Tooltip enterTouchDelay={0} placement="top" title={props.value}>
      {props.children}
    </Tooltip>
  );
}
