import Flex from '@frontend/components/frames/Flex';
import SummaryFeedback from '@frontend/components/SummaryFeedback';
import Typography from '@frontend/components/Typography';
import { useGetCode } from '../../data/useGetCode';
import { LoadingPaymentFile } from './LoadingPaymentFile';
import { PaymentFailed } from './PaymentFailed';
import { PaymentSucceeded } from './PaymentSucceeded';

type Props = {
  codeData: ReturnType<typeof useGetCode>;
};

export function PaymentSummary(props: Props) {
  if (!props.codeData) return null;
  const data = props.codeData;

  function Content() {
    if (data.isLoading) return <LoadingPaymentFile />;
    if (data.isError)
      return (
        <PaymentFailed
          email={data.paymentInfos?.email}
          onRetryClick={data.onRetryClick}
        />
      );

    return <PaymentSucceeded {...data} />;
  }

  return (
    <SummaryFeedback
      title={data.paymentInfos.paymentTitle}
      headerGridTemplateColumns="auto;"
    >
      <Flex padding={['xs3', 'xs3', undefined, 'xs3']}>
        <Typography.Body4 color="neutral_30">
          {data.paymentInfos?.emailText}{' '}
          <Typography.Body4 weight={700} as="span">
            {data.paymentInfos?.email}
          </Typography.Body4>
        </Typography.Body4>
      </Flex>
      <Content />
    </SummaryFeedback>
  );
}
