import { trpc } from '@frontend/trpc';

type Input = {
  orderId?: string;
  paymentMethod?: 'BILLET' | 'PIX';
};

export default function useGetOrder(input: Input) {
  if (!input.orderId)
    return {
      isLoading: false,
      isError: true,
      refetch: () => {},
    };

  const order = trpc.corporateCard.deposits.getBill.useQuery({
    orderId: input.orderId,
  });

  return {
    barcode: order.data?.code,
    pixCode: order.data?.code,
    pdfUrl: order.data?.pdfLink,
    isLoading: order.isFetching,
    isError: order.isError,
    refetch: order.refetch,
  };
}
