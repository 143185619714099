import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { CopyButton } from '@frontend/components/CopyButton';
import Flex from '@frontend/components/frames/Flex';
import { BilletUtilities } from '@frontend/utils/billet';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import Barcode from 'react-barcode';
import Typography from '../Typography';

type Props = {
  barcode: string;
  pdfUrl?: string;
  width?: number;
  height?: number;
  doNotDisplayBarCode?: boolean;
};

export function BarcodeGenerator({
  barcode,
  width = 1,
  height = 50,
  pdfUrl = undefined,
  doNotDisplayBarCode = false,
}: Props) {
  const formattedLine = getFormattedLine(barcode);

  const downloadLink = (link?: string) => {
    const newWindow = window.open(link);
    if (newWindow) {
      dispatchToast({
        type: 'success',
        content: 'boleto baixado com sucesso!',
      });
      newWindow.focus();
    } else {
      dispatchToast({
        type: 'error',
        content: 'Não foi possível baixar o boleto.',
        description:
          'Tente novamente. Se o problema persistir, entre em contato com o suporte',
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="center">
      {doNotDisplayBarCode ? (
        <Typography.Body4 center>{formattedLine}</Typography.Body4>
      ) : (
        <Barcode
          value={formattedLine}
          width={width}
          height={height}
          displayValue={true}
          fontSize={16}
        />
      )}
      <Flex padding="xs4" gap="xs2" direction="row">
        <CopyButton
          textToCopy={formattedLine}
          copiedLabel="Código copiado!"
          defaultLabel="Copiar código de barras"
        />
        {pdfUrl && (
          <PillButton
            icon={'IconDownload'}
            size="small"
            label="Baixar PDF"
            type="secondary"
            variant="pink"
            onClick={() => downloadLink(pdfUrl)}
            iconPosition="left"
          />
        )}
      </Flex>
    </Flex>
  );
}

function getFormattedLine(barcode: string): string {
  const billetUtilities = new BilletUtilities();
  return billetUtilities.getFormattedLine(barcode);
}
