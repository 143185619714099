import { NiceModalHocProps } from '@ebay/nice-modal-react';
import { DataGrid, PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import Button from '@frontend/components/Button';
import Typography from '@frontend/components/Typography';
// eslint-disable-next-line @cspell/spellchecker
import AutomaticCreditSettedWarningModal from '@frontend/pages/Home/FlashCard/modals/AutomaticCreditSettedWarning/AutomaticCreditSettedWarningModal';
import CorporateMode from '@frontend/pages/Home/FlashCard/modals/CorporateMode';
import CreateCorporateCardUserSuccess from '@frontend/pages/Home/FlashCard/modals/CreateCorporateCardUser/feedbacks/CreateCorporateCardUserSuccess';
import DisableUserModal from '@frontend/pages/Home/FlashCard/modals/DisableUserModal';
import DownloadEmployeeStatement from '@frontend/pages/Home/FlashCard/modals/DownloadEmployeeStatement';
import CancelAutomaticCreditFeedback from '@frontend/pages/Home/FlashCard/modals/EditAutomaticCredit/CancelAutomaticCreditFeedback';
import EditAutomaticCreditModal from '@frontend/pages/Home/FlashCard/modals/EditAutomaticCredit/EditAutomaticCreditModal';
import EnableUserModal from '@frontend/pages/Home/FlashCard/modals/EnableUserModal';
import LinkPolicyModal from '@frontend/pages/Home/FlashCard/modals/LinkPolicyModal';
import RefundUserBalanceModal from '@frontend/pages/Home/FlashCard/modals/RefundUserBalance/RefundUserBalanceModal';
import UnlinkPolicyModal from '@frontend/pages/Home/FlashCard/modals/UnlinkPolicyModal';
import UpdateCardStatusModal from '@frontend/pages/Home/FlashCard/modals/UpdateCardStatusModal';
import OnboardingAddEmployeeEndModal from '@frontend/pages/Home/modals/onboardingAddEmployee/OnboardingAddEmployeeEndModal';
import OnboardingAddEmployeeStartModal from '@frontend/pages/Home/modals/onboardingAddEmployee/OnboardingAddEmployeeStartModal';
import OnboardingAddEmployeeTutorialModal from '@frontend/pages/Home/modals/onboardingAddEmployee/OnboardingAddEmployeeTutorialModal';
import OnboardingCoupledVirtualCardEndModal from '@frontend/pages/Home/modals/onboardingCoupledVirtualCard/OnboardingCoupledVirtualCardEndModal';
import OnboardingCoupledVirtualCardStartModal from '@frontend/pages/Home/modals/onboardingCoupledVirtualCard/OnboardingCoupledVirtualCardStartModal';
import OnboardingCoupledVirtualCardTutorialModal from '@frontend/pages/Home/modals/onboardingCoupledVirtualCard/OnboardingCoupledVirtualCardTutorialModal';
import OnboardingRechargeEndModal from '@frontend/pages/Home/modals/onboardingRecharge/OnboardingRechargeEndModal';
import OnboardingRechargeStartModal from '@frontend/pages/Home/modals/onboardingRecharge/OnboardingRechargeStartModal';
import OnboardingRechargeTutorialModal from '@frontend/pages/Home/modals/onboardingRecharge/OnboardingRechargeTutorialModal';
import OnboardingVirtualCardStartModal from '@frontend/pages/Home/modals/onboardingVirtualCard/OnboardingVirtualCardStartModal';
import OnboardingVirtualCardTutorialModal from '@frontend/pages/Home/modals/onboardingVirtualCard/OnboardingVirtualCardTutorialModal';
import SuccessAddUserModal from '@frontend/pages/Home/modals/SuccessAddUserModal';
import FailedAutomaticCreditFeedback from '@frontend/pages/Management/modals/FailedAutomaticCreditFeedback';
import OnboardingFlashCashEndModal from '@frontend/pages/Management/modals/onboardingFlashCash/OnboardingFlashCashEndModal';
import OnboardingFlashCashStartModal from '@frontend/pages/Management/modals/onboardingFlashCash/OnboardingFlashCashStartModal';
import OnboardingFlashCashTutorialModal from '@frontend/pages/Management/modals/onboardingFlashCash/OnboardingFlashCashTutorialModal';
import ApproveRequestModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/ApproveRequestModal';
import OnboardingRequestBalanceEndModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/onboardRequestBalance/OnboardingRequestBalanceEndModal';
import OnboardingRequestBalanceStartModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/onboardRequestBalance/OnboardingRequestBalanceStartModal';
import OnboardingRequestBalanceTutorialModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/onboardRequestBalance/OnboardingRequestBalanceTutorialModal';
import ReproveRequestModal from '@frontend/pages/Management/tabs/RequestBalanceSection/modals/ReproveRequestModal';
import CreatePolicyErrorModal from '@frontend/pages/Policies/focusedFlows/CreatePolicy/modals/CreatePolicyErrorModal';
import CreatePolicySuccessModal from '@frontend/pages/Policies/focusedFlows/CreatePolicy/modals/CreatePolicySuccessModal';
import EditConfirmationModal from '@frontend/pages/Policies/focusedFlows/EditPolicy/modals/EditConfirmationModal';
import DeletePolicyModal from '@frontend/pages/Policies/modals/DeletePolicyModal';
import OnboardingPoliciesEndModal from '@frontend/pages/Policies/modals/onboardingPoliciesModal/OnboardingPoliciesEndModal';
import OnboardingPoliciesStartModal from '@frontend/pages/Policies/modals/onboardingPoliciesModal/OnboardingPoliciesStartModal';
import OnboardingPoliciesTutorialModal from '@frontend/pages/Policies/modals/onboardingPoliciesModal/OnboardingPoliciesTutorialModal';
import PatchActiveModal from '@frontend/pages/Policies/modals/PatchActiveModal';
import DeleteTransactionModal from '@frontend/pages/Statement/ExternalCards/modals/DeleteTransactionModal';
import ReclassificationError from '@frontend/pages/Statement/modals/reclassification/ReclassificationError';
import ReclassificationPreviewModal from '@frontend/pages/Statement/modals/reclassification/ReclassificationPreviewModal';
import ReclassificationSuccessModal from '@frontend/pages/Statement/modals/reclassification/ReclassificationSuccessModal';
import CancelBilletModal from '@frontend/pages/Transactions/Billet/modals/CancelBilletModal';
import CancelDepositModal from '@frontend/pages/Transactions/Deposits/CancelDepositModal/CancelDepositModal';
import NextCreditStatusModal from '@frontend/pages/Wallet/CorporateCard/modals/NextCreditStatusModal';
import ModalService from '@frontend/services/ModalService';
import React from 'react';

export default function Modals() {
  return (
    <PageContainer>
      <Typography.Headline6>Modais</Typography.Headline6>
      <DataGrid
        hasPagination
        columns={[
          {
            Header: 'Nome',
            accessor: 'title',
          },
          {
            Header: 'Abrir modal',
            accessor: 'show',
          },
        ]}
        data={mappedModals}
      />
    </PageContainer>
  );
}

function renderNiceModal<T extends React.FC<NiceModalHocProps>>(
  modal: T,
  params: Parameters<T>[0],
) {
  return (
    <Button
      variant="primary"
      onClick={() => ModalService.show(modal, params as any)}
    >
      Abrir modal
    </Button>
  );
}
const mappedModals: { title: string; show: React.ReactNode }[] = [
  {
    title: 'Reclassificação - Modal de feedback',
    show: renderNiceModal(ReclassificationSuccessModal, {
      nowIsCorporate: true,
      transactionId: '',
      id: 'teste',
    }),
  },
  {
    title: 'CorporateMode',
    show: renderNiceModal(CorporateMode, {
      id: 'teste',
    }),
  },
  {
    title: 'UpdateCardStatusModal',
    show: renderNiceModal(UpdateCardStatusModal, {
      card: {
        _id: 'teste',
        name: 'Nome do cartão',
      },
      employee: {
        _id: 'teste',
        name: 'Nome do usuário',
      },
      newStatus: 'HARD_LOCKED',
      id: 'teste',
    }),
  },
  {
    title: 'DeleteTransactionModal',
    show: renderNiceModal(DeleteTransactionModal, {
      transactionId: '',
      id: '',
    }),
  },
  // {
  //   title: 'FeedbackSuccessModal',
  //   show: renderModal(FeedbackSuccessModal, {}),
  // },
  // {
  //   title: 'EditAddressModal',
  //   show: renderModal(EditAddressModal, {}),
  // },
  // {
  //   title: 'GroupCompanyListModal',
  //   show: renderModal(GroupCompanyListModal, {}),
  // },
  {
    title: 'DisableUserModal',
    show: renderNiceModal(DisableUserModal, {
      filter: {
        active: undefined,
        balance: undefined,
        behavior: undefined,
        cards: undefined,
        employeeId: undefined,
        stringInput: undefined,
      },
      total: 0,
      id: '',
    }),
  },
  {
    title: 'EmployeeStatementModal',
    show: renderNiceModal(DownloadEmployeeStatement, {
      employeeId: '',
      id: '',
    }),
  },
  {
    title: 'EnableUserModal',
    show: renderNiceModal(EnableUserModal, {
      filter: {
        active: undefined,
        balance: undefined,
        behavior: undefined,
        cards: undefined,
        employeeId: undefined,
        stringInput: undefined,
      },
      total: 0,
      id: '',
    }),
  },
  {
    // eslint-disable-next-line @cspell/spellchecker
    title: 'AutomaticCreditSettedWarningModal',
    // eslint-disable-next-line @cspell/spellchecker
    show: renderNiceModal(AutomaticCreditSettedWarningModal, {
      id: '',
    }),
  },
  {
    title: 'CreateCorporateCardUserSuccess',
    show: renderNiceModal(CreateCorporateCardUserSuccess, {
      singleCard: false,
      cardIssuer: '',
      id: '',
    }),
  },
  {
    title: 'CancelAutomaticCreditFeedback',
    show: renderNiceModal(CancelAutomaticCreditFeedback, {
      automaticCreditMetadata: {
        date: new Date(),
        amount: 0,
        type: '',
        employeeDepositIdList: [],
      },
      id: '',
    }),
  },
  {
    title: 'EditAutomaticCreditModal',
    show: renderNiceModal(EditAutomaticCreditModal, {
      automaticCredit: {
        amount: undefined,
        type: undefined,
        id: undefined,
        legacyId: undefined,
        date: undefined,
      },
      id: '',
    }),
  },
  {
    title: 'LinkPolicyModal',
    show: renderNiceModal(LinkPolicyModal, {
      cardId: '',
      id: '',
    }),
  },
  {
    title: 'RefundUserBalanceModal',
    show: renderNiceModal(RefundUserBalanceModal, {
      filter: {
        active: undefined,
        balance: undefined,
        behavior: undefined,
        cards: undefined,
        employeeId: undefined,
        stringInput: undefined,
      },
      total: 0,
      id: '',
    }),
  },
  {
    title: 'DownloadEmployeeStatement',
    show: renderNiceModal(DownloadEmployeeStatement, {
      employeeId: '',
      id: '',
    }),
  },
  {
    title: 'UnlinkPolicyModal',
    show: renderNiceModal(UnlinkPolicyModal, {
      cardId: '',
      policyId: '',
      id: '',
    }),
  },
  {
    title: 'OnboardingAddEmployeeEndModal',
    show: renderNiceModal(OnboardingAddEmployeeEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingAddEmployeeStartModal',
    show: renderNiceModal(OnboardingAddEmployeeStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingAddEmployeeTutorialModal',
    show: renderNiceModal(OnboardingAddEmployeeTutorialModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingCoupledVirtualCardEndModal',
    show: renderNiceModal(OnboardingCoupledVirtualCardEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingCoupledVirtualCardStartModal',
    show: renderNiceModal(OnboardingCoupledVirtualCardStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingCoupledVirtualCardTutorialModal',
    show: renderNiceModal(OnboardingCoupledVirtualCardTutorialModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingRechargeEndModal',
    show: renderNiceModal(OnboardingRechargeEndModal, {
      callback: () => null,
      id: '',
    }),
  },
  {
    title: 'OnboardingRechargeStartModal',
    show: renderNiceModal(OnboardingRechargeStartModal, {
      callback: () => null,
      id: '',
    }),
  },
  {
    title: 'OnboardingRechargeTutorialModal',
    show: renderNiceModal(OnboardingRechargeTutorialModal, {
      callback: () => null,
      id: '',
    }),
  },
  {
    title: 'OnboardingAddEmployeeEndModal',
    show: renderNiceModal(OnboardingAddEmployeeEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingVirtualCardStartModal',
    show: renderNiceModal(OnboardingVirtualCardStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingVirtualCardTutorialModal',
    show: renderNiceModal(OnboardingVirtualCardTutorialModal, {
      id: '',
    }),
  },
  {
    title: 'SuccessAddUserModal',
    show: renderNiceModal(SuccessAddUserModal, {
      id: '',
    }),
  },
  {
    title: 'FailedAutomaticCreditFeedback',
    show: renderNiceModal(FailedAutomaticCreditFeedback, {
      id: '',
    }),
  },
  {
    title: 'OnboardingFlashCashEndModal',
    show: renderNiceModal(OnboardingFlashCashEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingFlashCashStartModal',
    show: renderNiceModal(OnboardingFlashCashStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingFlashCashTutorialModal',
    show: renderNiceModal(OnboardingFlashCashTutorialModal, {
      id: '',
    }),
  },
  {
    title: 'ApproveRequestModal',
    show: renderNiceModal(ApproveRequestModal, {
      order: {
        _id: '',
        creditDate: new Date(),
        employees: [],
        value: 0,
      },
      id: '',
    }),
  },
  {
    title: 'ReproveRequestModal',
    show: renderNiceModal(ReproveRequestModal, {
      orderId: '',
      id: '',
    }),
  },
  {
    title: 'OnboardingRequestBalanceEndModal',
    show: renderNiceModal(OnboardingRequestBalanceEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingRequestBalanceStartModal',
    show: renderNiceModal(OnboardingRequestBalanceStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingRequestBalanceTutorialModal',
    show: renderNiceModal(OnboardingRequestBalanceTutorialModal, {
      id: '',
    }),
  },
  // {
  // title: 'renderModal',
  // show: renderNiceModal(renderNiceModal, {}),
  // },
  {
    title: 'CreatePolicyErrorModal',
    show: renderNiceModal(CreatePolicyErrorModal, {
      retryCreation: () => null,
      id: '',
    }),
  },
  {
    title: 'CreatePolicySuccessModal',
    show: renderNiceModal(CreatePolicySuccessModal, {
      id: '',
    }),
  },
  {
    title: 'EditConfirmationModal',
    show: renderNiceModal(EditConfirmationModal, {
      edit: function (): Promise<void> {
        throw new Error('Function not implemented.');
      },
      id: '',
    }),
  },
  {
    title: 'DeletePolicyModal',
    show: renderNiceModal(DeletePolicyModal, {
      policyId: '',
      id: '',
    }),
  },
  {
    title: 'OnboardingPoliciesEndModal',
    show: renderNiceModal(OnboardingPoliciesEndModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingPoliciesStartModal',
    show: renderNiceModal(OnboardingPoliciesStartModal, {
      id: '',
    }),
  },
  {
    title: 'OnboardingPoliciesTutorialModal',
    show: renderNiceModal(OnboardingPoliciesTutorialModal, {
      id: '',
    }),
  },
  {
    title: 'PatchActiveModal',
    show: renderNiceModal(PatchActiveModal, {
      policy: {
        id: '',
        cardIds: [],
      },
      onConfirm: () => null,
      id: '',
    }),
  },
  {
    title: 'UnlinkPolicyModal',
    show: renderNiceModal(UnlinkPolicyModal, {
      cardId: '',
      policyId: '',
      id: '',
    }),
  },
  {
    title: 'DeleteTransactionModal',
    show: renderNiceModal(DeleteTransactionModal, {
      transactionId: '',
      id: '',
    }),
  },
  {
    title: 'ReclassificationError',
    show: renderNiceModal(ReclassificationError, {
      message: '',
      id: '',
    }),
  },
  {
    title: 'ReclassificationPreviewModal',
    show: renderNiceModal(ReclassificationPreviewModal, {
      transactionId: '',
      id: '',
    }),
  },
  {
    title: 'ReclassificationSuccessModal',
    show: renderNiceModal(ReclassificationSuccessModal, {
      transactionId: '',
      nowIsCorporate: false,
      id: '',
    }),
  },
  {
    title: 'CancelBilletModal',
    show: renderNiceModal(CancelBilletModal, {
      billet: {
        _id: '',
        code: '',
        totalAmount: '',
        dueDate: '',
      },
      id: '',
    }),
  },
  {
    title: 'CancelDepositModal',
    show: renderNiceModal(CancelDepositModal, {
      employeeDepositId: '',
      employeeName: '',
      amount: 0,
      date: '',
      id: '',
    }),
  },
  {
    title: 'NextCreditStatusModal',
    show: renderNiceModal(NextCreditStatusModal, {
      id: '',
    }),
  },
];
