import { dispatchToast } from '../../../utils/dispatchEvents';
import { trpc } from '../../../utils/trpc';

type Input = {
  transactionId: string;
};

export default function useNotifyReclassification(input: Input) {
  const notifyReclassification =
    trpc.employee.corporateCard.statement.update.action.useMutation({
      onSuccess: successToast,
      onError: errorToast,
    });

  return {
    notifyReclassification: function (this: void) {
      notifyReclassification.mutate(input);
    },
    loading: notifyReclassification.isLoading,
  };
}

function successToast() {
  dispatchToast({
    type: 'success',
    content: 'Comprovante enviado com sucesso!',
  });
}

function errorToast(error?: { message: string }) {
  const formattedError = error?.message;
  if (formattedError) {
    dispatchToast({
      type: 'error',
      content: formattedError,
    });
  } else {
    dispatchToast({
      type: 'error',
      content: 'Não foi possível enviar o comprovante por e-mail.',
      description:
        'Tente novamente. Se o problema persistir, entre em contato com o suporte',
    });
  }
}
