import FeatureFlagService from '@frontend/services/FeatureFlagService';
import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { sortedMccGroups } from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { RouterOutputs } from 'backend';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { z } from 'zod';
import useEditPolicy from '../data/useEditPolicy';
import { useGetPolicy } from '../data/useGetPolicy';
import {
  FormSchema,
  PolicyLimit,
  PolicyWithLimit,
  PolicyWithMccLimits,
  PolicyWithoutLimit,
} from './schema';
import { toasts } from './toasts';

export type FormSchemaOutput = z.output<typeof FormSchema>;
export type LimitSchema = z.infer<typeof PolicyLimit>;

type Input = {
  policyId: string;
  /** Callback for the submit success */
  onSuccess: (policy: RouterOutputs['company']['policies']['patch']) => void;
};

export default function useForm(input: Input) {
  const limitByCategoryEnabled = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );

  const originalPolicy = useGetPolicy({ policyId: input.policyId });

  let policy: PolicyWithoutLimit | PolicyWithMccLimits | PolicyWithLimit = {
    description: originalPolicy.data?.description ?? '',
    title: originalPolicy.data?.title ?? '',
    weekdaysEnabled: originalPolicy.data?.weekdaysEnabled ?? {
      MONDAY: true,
      TUESDAY: true,
      WEDNESDAY: true,
      THURSDAY: true,
      FRIDAY: true,
      SATURDAY: true,
      SUNDAY: true,
    },
    withdrawEnabled: originalPolicy.data?.withdrawEnabled ?? false,
    mccGroups: originalPolicy.data?.mccGroups ?? {
      CONVENIENCE: true,
      CULTURE: true,
      EDUCATION: true,
      GROCERY: true,
      HEALTH: true,
      MEAL: true,
      MOBILITY: true,
    },
    limitType: 'UNLIMITED',
    limitEnabled: false,
  };

  if (!!originalPolicy.data?.mccLimits) {
    policy = {
      ...policy,
      limitType: 'BY_CATEGORY',
      mccLimits: originalPolicy.data?.mccLimits,
      limitEnabled: true,
    } as PolicyWithMccLimits;
  } else if (!!originalPolicy.data?.limit) {
    policy = {
      ...policy,
      limitType: 'GENERAL',
      limit: originalPolicy.data?.limit,
      limitEnabled: true,
    } as PolicyWithLimit;
  }

  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    values: policy,
  });

  /* --------------------- Additional schema validations -------------------- */
  const mccGroups = form.watch('mccGroups');
  const mccLimits = form.watch('mccLimits') || {};
  const withdrawEnabled = form.watch('withdrawEnabled');
  const limitEnabled = form.watch('limitEnabled');
  const limitType = form.watch('limitType');

  React.useEffect(() => {
    if (limitByCategoryEnabled) {
      sortedMccGroups.map((mcc) => {
        if (!mccGroups[mcc]) {
          form.unregister(`mccLimits.${mcc}`);
        }
      });
    }
    void form.trigger();
  }, [mccGroups]);

  React.useEffect(() => {
    if (limitByCategoryEnabled)
      if (!withdrawEnabled) {
        form.unregister('mccLimits.WITHDRAW');
      } else if (!mccLimits['WITHDRAW']) {
        form.resetField('mccLimits.WITHDRAW.amount');
        form.resetField('mccLimits.WITHDRAW.period');
      }
    void form.trigger();
  }, [withdrawEnabled]);

  React.useEffect(() => {
    switch (limitType) {
      case 'GENERAL':
        form.setValue('limitEnabled', true);
        limitByCategoryEnabled && form.unregister('mccLimits');
        break;

      case 'BY_CATEGORY':
        form.unregister('limit');
        form.setValue('limitEnabled', true);
        break;

      case 'UNLIMITED':
        form.setValue('limitEnabled', false);
        form.unregister('limit');
        limitByCategoryEnabled && form.unregister('mccLimits');
        break;
    }

    void form.trigger();
  }, [limitType]);

  React.useEffect(() => {
    if (limitEnabled) {
      if (limitType) {
      }
      form.resetField('limit.amount');
      form.resetField('limit.period');
    } else {
      form.unregister('limit');
    }
  }, [limitEnabled]);

  /* ------------------------ Schema validation check ----------------------- */
  const validSchema = form.formState.isValid && _isEmpty(form.formState.errors);

  /* ------------------------------ Form submit ----------------------------- */
  const editPolicy = useEditPolicy();

  const onSubmit = form.handleSubmit(
    (values) => {
      void editPolicy
        .mutateAsync({
          ...values,
          id: input.policyId,
          ...(values.limitEnabled && 'limit' in values
            ? {
                limit: values.limit,
              }
            : {}),
          ...((!values.limitEnabled || values.limitType === 'BY_CATEGORY') &&
          originalPolicy.data?.limit
            ? {
                limit: null,
              }
            : {}),
          ...(originalPolicy.data?.mccLimits && !('mccLimits' in values)
            ? {
                mccLimits: null,
              }
            : {}),
        })
        .then((result) => {
          if (result) {
            input.onSuccess(result);
          }
        });
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating policy form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isSubmitting: form.formState.isSubmitting || editPolicy.isLoading,
    onSubmit,
    result: editPolicy.data,
    validSchema,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
