import Button from '@frontend/components/Button';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';
import { useDepositSummaryData } from '../../data/useSummaryData';

export function DepositNotRequested(
  data: ReturnType<typeof useDepositSummaryData>,
) {
  return (
    <Flex padding={'xs2'} gap="xs5" direction="column">
      <Typography.Body4 color="neutral_20" weight={600}>
        Nenhum depósito definido
      </Typography.Body4>
      <Flex align="center" gap="xs3" direction="row" justify="space-between">
        <Typography.Caption color="neutral_40">
          As pessoas só terão acesso à função corporativa do cartão Flash quando
          um primeiro depósito de saldo for disponibilizado para elas.
        </Typography.Caption>
        <Button
          variant="secondary"
          onClick={data.goToCreateDepositFlow}
          icon="IconArrowRight"
          size="small"
        >
          Depositar
        </Button>
      </Flex>
    </Flex>
  );
}
