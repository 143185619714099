import FormService from '@frontend/services/FormService';
import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';

export const FormSchema = z.object({
  password: z
    .string({ required_error: errors.forms.generic.required })
    .length(4, errors.forms.transactionPassword.wrongSize),
});
type FormSchemaType = z.output<typeof FormSchema>;

type Input = {
  onSubmit: (_input: FormSchemaType) => void;
};

export default function useForm(input: Input) {
  const form = FormService.useCreateForm(FormSchema);

  const onSubmit = form.handleSubmit((formValues) => {
    input.onSubmit(formValues);
  });

  return {
    form,
    onSubmit,
  };
}
