import Icon from '@frontend/components/Icon';
import { ModalV2 } from '@frontend/components/modals/ModalV2';
import Spacer from '@frontend/components/Spacer';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { UseFormReturn } from '../../../../../controllers/form/useForm';
import { useGetEmployees } from '../../../../../data/useGetEmployees';

type Props = {
  form: UseFormReturn;
};

function SummaryModal(props: Props) {
  const controller = ModalService.useModalController();

  const employees = useGetEmployees({ enabled: false });

  const employeeIds = props.form.getValues('employeeIds');

  const rows = React.useMemo(() => {
    return employees.employees.filter((employee) =>
      employeeIds.has(employee.value),
    );
  }, [employees.employees, employeeIds]);

  const depositAmount = props.form.getValues().deposit.amount;

  return (
    <ModalV2
      title="Detalhes do pedido"
      open={controller.visible}
      onClose={controller.remove}
    >
      <Spacer y={'s'} />
      <TableClient
        emptyState={{
          emptyText: 'Nenhuma pessoa selecionada',
          filteredEmptyText: '',
        }}
        loading={false}
        columns={columns(depositAmount)}
        data={rows}
        enablePagination
        pageSizeOptions={[{ label: '5', value: 5 }]}
      />
    </ModalV2>
  );
}

export default ModalService.create(SummaryModal);

type Row = {
  title: string;
  value: string;
  description: string;
};
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

function columns(depositAmount?: number) {
  return [
    {
      accessorKey: 'title',
      header: 'Pessoa',
      cell: (context) => {
        return (
          <>
            <Typography.Body3 color="neutral_40">
              {context.row.original.title}
            </Typography.Body3>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Icon size={16} name="IconId" color="neutral_70" />
              <Typography.Body4 color="neutral_50">
                {context.row.original.description}
              </Typography.Body4>
            </div>
          </>
        );
      },
    },
    {
      accessorKey: 'value',
      header: 'Valor do depósito',
      enableSorting: false,
      cell: () => {
        return (
          <Typography.Body3 color="neutral_40">
            {depositAmount ? toCurrency(depositAmount) : '-'}
          </Typography.Body3>
        );
      },
    },
  ] satisfies Columns;
}
