import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { Controller } from 'react-hook-form';
import useForm from './useForm';

type Props = {
  cardId: string;
  currentCardName: string;
};

function UpdateAliasModal(props: Props) {
  const modalController = ModalService.useModalController();
  const form = useForm({ cardId: props.cardId });
  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Confirmar',
          onClick: form.onSubmit,
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      loading={form.isLoading}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Alterar o apelido do cartão',
        body: (
          <>
            <Typography.Body3 center>
              O apelido atual de seu cartão é "
              <strong>{props.currentCardName}</strong>". Qual será o novo
              apelido?
            </Typography.Body3>
            <Spacer y="m" />
            <Controller
              control={form.control}
              name="cardName"
              render={(controller) => (
                <TextField
                  autoComplete="new-password" // Prevents browser autofill
                  fullWidth
                  label="Novo apelido"
                  type={'text'}
                  value={controller.field.value}
                  onChange={controller.field.onChange}
                  error={controller.fieldState.invalid}
                  helperText={controller.fieldState.error?.message}
                />
              )}
            />
            <Spacer y="s" />
            <Controller
              control={form.control}
              name="password"
              render={(controller) => (
                <TextField
                  autoComplete="new-password" // Prevents browser autofill
                  fullWidth
                  label="Senha de transação"
                  type={'password'}
                  value={controller.field.value}
                  onChange={controller.field.onChange}
                  error={controller.fieldState.invalid}
                  helperText={controller.fieldState.error?.message}
                />
              )}
            />
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(UpdateAliasModal);
