import { ColorToken, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import { formatDateWithoutYear } from '@frontend/utils/masks/formatDate';
import { Color } from '@frontend/utils/theme/colors';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Feature } from '../context/types';
import { VERSIONS_CONTENT } from '../context/versions.contant';

type IconProps = React.ComponentProps<typeof Icon>;
type PillButtonProps = React.ComponentProps<typeof PillButton>;

export function useCalloutData(feature: Feature) {
  const featureContent = VERSIONS_CONTENT[feature];

  const data = useMemo(() => {
    let variant: Variant = 'primary';

    const [infoDate, alertDate] = featureContent.lifecycle?.timeline ?? [];

    if (featureContent.lifecycle && dayjs().isAfter(infoDate)) {
      variant = dayjs().isAfter(alertDate) ? 'error' : 'info';
    }

    const styles = variantStyles[variant];

    if (variant === 'primary' || !featureContent.lifecycle) {
      return {
        ...styles,
        title: 'Esta área vai mudar!',
        message: `Estamos melhorando a experiência de ${featureContent.featureName}. Você já pode testar a nova versão agora!`,
      };
    }

    return {
      ...styles,
      title: `A experiência atual será descontinuada em ${formatDateWithoutYear(featureContent.lifecycle.discontinuationDate)}.`,
      message: `Comece a usar a nova experiência de ${featureContent.featureName} e dê seu feedback!`,
    };
  }, []);

  return data;
}

type CalloutData = {
  icon: {
    name: IconProps['name'];
    color: IconProps['color'];
    background: ColorToken;
  };
  titleColor: Color;
  messageColor: Color;
  background: ColorToken;
  action: {
    label: string;
    variant: PillButtonProps['variant'];
  };
};

type Variant = 'primary' | 'info' | 'error';

const variantStyles: Record<Variant, CalloutData> = {
  primary: {
    icon: {
      name: 'IconSparkles',
      color: 'secondary_50',
      background: 'secondary.95',
    },
    titleColor: 'secondary_50',
    messageColor: 'secondary_10',
    background: 'secondary.99',
    action: {
      label: 'Experimentar',
      variant: 'pink',
    },
  },
  info: {
    icon: {
      name: 'IconInfoCircle',
      color: 'info_40',
      background: 'feedback.info.70',
    },
    titleColor: 'info_40',
    messageColor: 'info_10',
    background: 'feedback.info.90',
    action: {
      label: 'Começar agora',
      variant: 'default',
    },
  },
  error: {
    icon: {
      name: 'IconInfoCircle',
      color: 'error_40',
      background: 'feedback.error.70',
    },
    titleColor: 'error_40',
    messageColor: 'error_10',
    background: 'feedback.error.90',
    action: {
      label: 'Começar agora',
      variant: 'default',
    },
  },
};
