import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import useCancelBillet from '../data/cancelBillet';

type Props = {
  billet: {
    _id: string;
    code: string;
    totalAmount: string;
    dueDate: string;
  };
};

function CancelBilletModal(props: Props) {
  const { cancelBillet, loading } = useCancelBillet();
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Confirmar cancelamento',
          loading,
          onClick: () =>
            void cancelBillet(props.billet._id).then(() => {
              modalController.remove();
            }),
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja cancelar fatura?',
        body: `Se você cancelar, a fatura Nº${props.billet.code} no valor de ${props.billet.totalAmount} com vencimento para o dia ${props.billet.dueDate} não estará mais disponível.`,
      }}
    />
  );
}

export default ModalService.create(CancelBilletModal);
