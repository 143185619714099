import FormService from '@frontend/services/FormService';
import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';
import useUpdateCardStatus from '../../data/useUpdateCardStatus';

export const FormSchema = z.object({
  password: z
    .string({ required_error: errors.forms.generic.required })
    .length(4, errors.forms.transactionPassword.wrongSize),
});

type Input = {
  cardId: string;
  newStatus: 'BLOCKED' | 'ENABLED';
};

export default function useForm(input: Input) {
  const form = FormService.useCreateForm(FormSchema);
  const updateStatus = useUpdateCardStatus();

  const onSubmit = form.handleSubmit((formValues) => {
    updateStatus
      .mutateAsync({
        cardId: input.cardId,
        status: input.newStatus,
        ...formValues,
      })
      .then((data) => {
        // Mutation succeeded
        if (data.success)
          form.reset({
            password: '',
          });
        // Mutation failed
        else
          switch (data.code) {
            case 'INVALID_TRANSACTION_PASSWORD':
              form.resetField('password', { defaultValue: '' });
              form.setError('password', {
                message: errors.forms.transactionPassword.wrongPassword,
                type: 'validate',
              });
              break;
            case 'TOO_MANY_PASSWORD_ATTEMPTS':
              form.reset({
                password: '',
              });
          }
      });
  });

  return {
    control: form.control,
    onSubmit,
    isLoading: updateStatus.isLoading,
  };
}
