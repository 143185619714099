import { CardJSONSchema } from 'backend/src/externalServices/ExpenseManagementService/users/schemas/card.json-schema';
import { z } from 'zod';

/** All possible card statuses on the backend */
export type CardStatus = z.infer<typeof CardJSONSchema>['status'];
/** Groups the `CardStatus`es on the frontend to simplify data display */
export type CardStatusGroup = (typeof sortedCardStatusGroups)[number];

/** Instead of using the raw backend card statuses, they get grouped to simplify
 * the data display. Sorted by pt-br translation
 */
export const sortedCardStatusGroups = [
  'ACTIVE',
  'BLOCKED',
  'BLOCKED_BY_ADMIN',
  'ACTIVATING',
  'CANCELED',
  'EXPIRED',
  'INACTIVE',
] as const;

export const cardStatusGroupsToStatusMap = {
  ACTIVATING: ['ACTIVATING'],
  ACTIVE: ['NORMAL'],
  BLOCKED: ['BLOCKED', 'BLOCKED_PASSWORD'],
  BLOCKED_BY_ADMIN: ['HARD_LOCKED'],
  CANCELED: ['CANCELED'],
  EXPIRED: ['DISABLED'],
  INACTIVE: ['INACTIVE'],
} as const satisfies Record<CardStatusGroup, Readonly<Array<CardStatus>>>;

export const cardStatusToStatusGroupMap = {
  ACTIVATING: 'ACTIVATING',
  BLOCKED: 'BLOCKED',
  BLOCKED_PASSWORD: 'BLOCKED',
  CANCELED: 'CANCELED',
  DISABLED: 'EXPIRED',
  HARD_LOCKED: 'BLOCKED_BY_ADMIN',
  INACTIVE: 'INACTIVE',
  NORMAL: 'ACTIVE',
} as const satisfies Record<CardStatus, CardStatusGroup>;

export const translatedCardStatusGroups = {
  ACTIVATING: 'Em ativação',
  ACTIVE: 'Ativo',
  BLOCKED: 'Bloqueado',
  CANCELED: 'Cancelado',
  BLOCKED_BY_ADMIN: 'Bloqueado por admin.',
  EXPIRED: 'Expirado',
  INACTIVE: 'Inativo',
} as const satisfies Record<CardStatusGroup, string>;
