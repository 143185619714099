import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import { formatDate } from '@frontend/utils/masks';
import { Controller } from 'react-hook-form';
import { useEditDepositContext } from './context';

export function AutomaticCredit() {
  const context = useEditDepositContext();

  return (
    <>
      {context.fields.creditDate && (
        <Flex align="center" gap="xs5">
          <Icon name="IconInfoCircle" color="neutral_40" size={12} />

          <Typography.Caption color="neutral_40">
            O depósito será feito todo dia {context.fields.creditDate.getDate()}
            , a partir de {formatDate(context.fields.creditDate)}
          </Typography.Caption>
        </Flex>
      )}

      <BorderedSection padding={['xs3', 'xs2']}>
        <Flex align="center" gap="xs3">
          <Controller
            control={context.form}
            name="creditType"
            render={(controller) => (
              <Checkbox
                onChange={(e) =>
                  controller.field.onChange(
                    e.target.checked
                      ? 'automaticCreditFixedValue'
                      : 'automaticCredit',
                  )
                }
                value={controller.field.value === 'automaticCreditFixedValue'}
                defaultChecked={
                  controller.field.value === 'automaticCreditFixedValue'
                }
              />
            )}
          />

          <div>
            <Typography.Body4 weight={600} color="neutral_30">
              Complementar saldo
            </Typography.Body4>

            <Typography.Caption color="neutral_40">
              O valor do depósito definido será um saldo fixo e iremos repor
              apenas o que for consumido no período.
            </Typography.Caption>
          </div>
        </Flex>
      </BorderedSection>
    </>
  );
}
