import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import Icon from '@frontend/components/Icon';
import AuthService from '@frontend/services/AuthService';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { UseFormReturn } from '../../../../controllers/form/useForm';
import useGetOrder from './useGetOrder';

type Input = {
  form: UseFormReturn;
};

export function useSummaryData(input: Input) {
  const formValues = input.form.watch();
  const result = input.form.result;
  const user = AuthService.useUser();

  const orderId =
    result?.deposit?.status === 'SUCCESS' ? result.deposit.orderId : undefined;
  const paymentMethod = formValues.paymentMethod;
  const orderData = useGetOrder({ orderId, paymentMethod });

  return {
    selectedPeople: formatEmployeeList(),
    /** Presentable details of the optional deposit (null if not requested) */
    deposit: depositDetails(),
    /** Downloadable link for PIX and Billet payments' PDF */
    downloadPdf: downloadPdf(),
    paymentTitle: formatPaymentTitle(),
    paymentMethod: formValues.paymentMethod,
    pixCode: orderData?.pixCode,
    barcode: orderData?.barcode,
  };

  /* ---------------------------- Selected people --------------------------- */
  function formatEmployeeList() {
    if (!formValues.deposits.length) return { formattedAsText: '' } as const;

    if (formValues.deposits.length === 1) {
      return {
        formattedAsText: formValues.deposits[0].name,
      } as const;
    }

    return {
      formattedAsText:
        `${formValues.deposits[0].name}, ${formValues.deposits[1].name}` +
        (formValues.deposits.length > 2
          ? ` + ${formValues.deposits.length - 2} pessoas`
          : ''),
    } as const;
  }

  /* -------------------------------- Deposit ------------------------------- */
  function depositDetails() {
    if (!result?.deposit || result.deposit.status === 'FAILED')
      return { status: 'FAILED' } as const;
    return {
      status: 'SUCCESS',
      creditDateDescription: creditDateDescription(),
      depositType: depositType(),
      depositAmount: {
        totalAmount: totalAmount(),
      },
      paymentDetails: {
        paymentStatus: paymentStatus(),
        paymentMethod: selectedPaymentMethod(),
      },
    } as const;
  }

  function creditDateDescription() {
    const creditDate = formValues.creditDate
      ? DateTime.fromJSDate(formValues.creditDate, {
          zone: 'America/Sao_Paulo',
        }).startOf('day')
      : null;

    if (!creditDate?.isValid) return null;

    return formValues.automatic
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy');
  }

  function depositType() {
    if (formValues.automatic) {
      return formValues.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático';
    }
    return formValues.expires
      ? 'Depósito único com expiração de saldo'
      : 'Depósito único';
  }

  function totalAmount() {
    const total = formValues.deposits.reduce(
      (total, deposit) => total + deposit.value,
      0,
    );
    return `${toCurrency(total)} total`;
  }

  function paymentStatus() {
    if (formValues.paymentMethod === 'FLASH_CASH') {
      return {
        label: 'Depósito agendado',
        variant: 'success',
      } as const;
    }
    return {
      label: 'Pagamento pendente',
      variant: 'primary',
    } as const;
  }

  function selectedPaymentMethod() {
    const paymentMethod = formValues.paymentMethod;
    switch (paymentMethod) {
      case 'FLASH_CASH':
        return {
          label: 'Carteira corporativa',
          icon: 'IconWallet' satisfies IconName,
        } as const;
      case 'BILLET':
        return {
          label: 'Boleto',
          icon: 'BarcodeMethod' satisfies IconName,
        } as const;
      case 'PIX':
        return {
          label: 'Pix',
          icon: 'Pix' satisfies IconName,
        } as const;
      default:
        return null;
    }
  }

  function formatPaymentTitle() {
    const paymentMethod = formValues.paymentMethod;
    switch (paymentMethod) {
      case 'BILLET':
        return 'Leia ou copie o código do Boleto';
      case 'PIX':
        return 'Leia ou copie o código Pix';
      default:
        return 'Leia ou copie o código';
    }
  }
  /* ----------------------------- Download PDF ----------------------------- */
  function downloadPdf() {
    const paymentMethod = formValues.paymentMethod;
    if (!isIncluded(paymentMethod, ['BILLET', 'PIX'])) return null;
    const emailText = 'Ele também será enviado para o e-mail';

    if (orderData.isError)
      return {
        disabled: true,
        isLoading: false,
        onRetryClick: () => {
          void orderData.refetch();
        },
        pdfUrl: undefined,
        email: user?.email ?? 'cadastrado em seu usuário',
        emailText,
      };

    return {
      disabled: false,
      onRetryClick: null,
      isLoading: orderData.isLoading,
      pdfUrl: orderData.pdfUrl,
      email: user?.email ?? 'cadastrado em seu usuário',
      emailText,
    };
  }
}

export function useDepositSummaryData(input: Input) {
  const formValues = input.form.watch();
  const result = input.form.result;

  return {
    selectedPeople: formatEmployeeList(),
    /** Presentable details of the optional deposit (null if not requested) */
    deposit: depositDetails(),
  };

  /* ---------------------------- Selected people --------------------------- */
  function formatEmployeeList() {
    if (!formValues.deposits.length) return { formattedAsText: '' } as const;

    if (formValues.deposits.length === 1) {
      return {
        formattedAsText: formValues.deposits[0].name,
      } as const;
    }

    return {
      formattedAsText:
        `${formValues.deposits[0].name}, ${formValues.deposits[1].name}` +
        (formValues.deposits.length > 2
          ? ` + ${formValues.deposits.length - 2} pessoas`
          : ''),
    } as const;
  }

  /* -------------------------------- Deposit ------------------------------- */
  function depositDetails() {
    if (!result?.deposit || result.deposit.status === 'FAILED')
      return { status: 'FAILED' } as const;
    return {
      status: 'SUCCESS',
      creditDateDescription: creditDateDescription(),
      depositType: depositType(),
      depositAmount: {
        totalAmount: totalAmount(),
      },
      paymentDetails: {
        paymentStatus: paymentStatus(),
        paymentMethod: selectedPaymentMethod(),
      },
    } as const;
  }

  function creditDateDescription() {
    const creditDate = formValues.creditDate
      ? DateTime.fromJSDate(formValues.creditDate, {
          zone: 'America/Sao_Paulo',
        }).startOf('day')
      : null;

    if (!creditDate?.isValid) return null;

    return formValues.automatic
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy');
  }

  function depositType() {
    if (formValues.automatic) {
      return formValues.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático';
    }
    return formValues.expires
      ? 'Depósito único com expiração de saldo'
      : 'Depósito único';
  }

  function totalAmount() {
    const total = formValues.deposits.reduce(
      (total, deposit) => total + deposit.value,
      0,
    );
    return `${toCurrency(total)} total`;
  }

  function paymentStatus() {
    if (formValues.paymentMethod === 'FLASH_CASH') {
      return {
        label: 'Depósito agendado',
        variant: 'success',
      } as const;
    }
    return {
      label: 'Pagamento pendente',
      variant: 'primary',
    } as const;
  }

  function selectedPaymentMethod() {
    const paymentMethod = formValues.paymentMethod;
    switch (paymentMethod) {
      case 'FLASH_CASH':
        return {
          label: 'Carteira corporativa',
          icon: 'IconWallet' satisfies IconName,
        } as const;
      case 'BILLET':
        return {
          label: 'Boleto',
          icon: 'BarcodeMethod' satisfies IconName,
        } as const;
      case 'PIX':
        return {
          label: 'Pix',
          icon: 'Pix' satisfies IconName,
        } as const;
      default:
        return null;
    }
  }
}

type IconName = React.ComponentProps<typeof Icon>['name'];
