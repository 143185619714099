import Tag from '@frontend/components/TagV2';
import { RouterOutputs } from 'backend';
import styled from 'styled-components';

type Props = {
  status: RouterOutputs['employee']['flashCards']['virtual']['getAll']['cards'][number]['status'];
};

export default function (props: Props) {
  const status =
    CARD_STATUS[props.status in CARD_STATUS ? props.status : 'ENABLED'];

  return (
    <Tag size="xs" variant={status.variant}>
      <StyledRow>
        <StyledDot variant={status.variant} />
        {status.label}
      </StyledRow>
    </Tag>
  );
}

const CARD_STATUS = {
  ENABLED: {
    label: 'Ativo',
    variant: 'success',
  },
  DISABLED: {
    label: 'Desativado',
    variant: 'neutral',
  },
  BLOCKED: {
    label: 'Bloqueado',
    variant: 'error',
  },
} as const;

type StyledDotProps = {
  variant: 'success' | 'neutral' | 'error';
};

const StyledDot = styled.div<StyledDotProps>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(p) => {
    switch (p.variant) {
      case 'success':
        return p.theme.colors.feedback.success[50];
      case 'neutral':
        return p.theme.colors.neutral[70];
      case 'error':
        return p.theme.colors.feedback.error[50];
    }
  }};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;
