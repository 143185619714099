import errors from '@frontend/utils/commonTexts/errors';
import { DateTime } from 'luxon-business-days';
import { z } from 'zod';

export const ActivateUsersFormSchema = z.object({
  /** Set of employeeIds that will be activated */
  employeeIds: z.set(z.string()).min(1, errors.forms.generic.required),
  /** Optional first deposit for all employees being activated */
  deposit: z
    .object({
      /* -------------------------- Authentication -------------------------- */
      confirmationToken: z.string().optional(),

      /* ------------------------ Basic deposit setup ----------------------- */
      /** Amount that should be deposited for each employee */
      amount: z.number().optional(),
      /** Date of the (first) employee credit */
      creditDate: z
        .date({
          errorMap: () => ({
            message: errors.forms.date.invalid,
          }),
        })
        .min(DateTime.now().startOf('day').toJSDate(), errors.forms.date.future)
        .max(
          DateTime.now().plus({ years: 2 }).toJSDate(),
          errors.forms.date.tooFarInFuture,
        )
        .optional(),
      /** Payment method for the deposit */
      paymentMethod: z.enum(['FLASH_CASH', 'PIX', 'BILLET']).optional(),

      /* ------------------------- Expiration setup ------------------------- */
      /** Whether the credited balance should expire at the `expirationDate`. Only valid for single deposits */
      expires: z.boolean().catch(false),
      /** Expiration date of the credited balance. Only valid for single deposits, when `expires: true` */
      expirationDate: z
        .date({
          invalid_type_error: errors.forms.date.invalid,
        })
        .optional(),

      /* ------------------------- Recurrence setup ------------------------- */
      /** Whether the deposit should be recurrent. */
      automatic: z.boolean().catch(false),
      /** Whether the deposit should only complement the employee balance to a target amount. Only valid for automatic deposits */
      complementary: z.boolean().catch(false),
    })
    .refine(
      /** Checks if billet deposits are at least 3 business days in the future */
      (data) => {
        if (data.paymentMethod === 'BILLET' && data.creditDate) {
          const createDateFrom = DateTime.now()
            .plusBusiness({ days: 3 })
            .startOf('day')
            .toJSDate();

          if (data.creditDate < createDateFrom) {
            return false;
          }
        }
        return true;
      },
      {
        message:
          'Para pagamentos com Boleto, a data de depósito precisa ser a partir de 3 dias úteis',
        path: ['creditDate'],
      },
    ),
});
