import { Skeleton as SkeletonBase } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';

export const Skeleton = styled(SkeletonBase)`
  margin-bottom: 0;
  display: flex;
  flex-grow: 1;
`;

export const DueDateText = styled(Flex)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  font-size: 14px;
  padding: 10px;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
`;
