import FeatureFlagService from '@frontend/services/FeatureFlagService';
import PlanFeaturesService from '@frontend/services/PlanFeatures';
import { trpc } from '@frontend/trpc';

/** Retrieves the list of external cards owned by the user */
export default function useGetExternalCards() {
  const externalCardsEnabled =
    FeatureFlagService.getFlag('externalCards') &&
    PlanFeaturesService.get('BASIC_CARDS');

  const externalCards = trpc.employee.externalCards.getAll.useQuery(undefined, {
    enabled: externalCardsEnabled,
  });

  return {
    enabled: externalCardsEnabled,
    items: externalCards.data?.items ?? [],
    isLoading: externalCards.isLoading,
  };
}

export type UseGetExternalCardsOutput = ReturnType<typeof useGetExternalCards>;
