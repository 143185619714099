import styled from 'styled-components';

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.l} 0px`};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs4};

  @media screen and (max-width: 671px) {
    padding: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const Body = styled.div`
  width: 671px;

  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.l} ${theme.spacings.m}`};

  @media screen and (max-width: 671px) {
    width: 100vw;
    padding: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const CloseContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;
