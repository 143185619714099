import SummaryFeedback from '@frontend/components/SummaryFeedback';
import ModalService from '@frontend/services/ModalService';
import { UseFormReturn } from '../../../../form/useForm';
import SummaryModal from '../../../01-Form/components/SummaryModal/SummaryModal';
import { useSummaryData } from '../../data/useSummaryData';
import { DepositFailed } from './DepositFailed';
import { DepositNotRequested } from './DepositNotRequested';
import { DepositSucceeded } from './DepositSucceeded';

type Props = {
  form: UseFormReturn;
};

export function DepositSummary(props: Props) {
  const data = useSummaryData({
    form: props.form,
  });
  const activatedEmployees = data.activation.activatedEmployees;
  const status = data.deposit.status;

  return (
    <SummaryFeedback
      icon="IconUsers"
      title={activatedEmployees?.formattedAsText ?? ''}
      onClickViewMore={
        activatedEmployees?.employeeIds
          ? () => {
              ModalService.show(SummaryModal, {
                employeeIds: activatedEmployees?.employeeIds,
                form: props.form,
              });
            }
          : undefined
      }
    >
      {status === 'NOT_REQUESTED' && <DepositNotRequested {...data} />}
      {status === 'FAILED' && <DepositFailed {...data} />}
      {status === 'SUCCESS' && <DepositSucceeded {...data} />}
    </SummaryFeedback>
  );
}
