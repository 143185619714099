import BalanceField from '@frontend/components/inputs/BalanceField';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../form/useForm';

type Props = {
  form: UseFormReturn;
};

export function DepositAmount(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="deposit.amount"
      render={(ctx) => (
        <BalanceField
          fullWidth
          label="Valor do depósito (R$)"
          value={ctx.field.value}
          onChange={ctx.field.onChange}
          error={ctx.fieldState.error?.message}
          helperText="Valor que cada pessoa receberá"
        />
      )}
    />
  );
}
