import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import React from 'react';
import CardStatusTag from '../../../components/CardStatusTag';
import type { UseGetExternalCardsOutput } from '../data/useGetExternalCards';
import useMenuActions from './useMenuActions';

type Row = UseGetExternalCardsOutput['items'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'provider',
    header: 'Emissora',
    filterFn: 'customInSet',
    cell: (context) => (
      <CardProviderChip
        provider={context.row.original.provider}
        variant="color"
      />
    ),
  },
  {
    accessorKey: 'cardNumber',
    header: 'Cartão',
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        ––– {context.row.original.cardNumber}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nome no cartão',
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.name.toUpperCase()}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'cardType',
    header: 'Tipo de cartão',
    filterFn: 'customInSet',
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {cardTypeMap[context.row.original.cardType]}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'active',
    header: 'Status',
    cell: (context) => (
      <CardStatusTag
        status={context.row.original.active ? 'ENABLED' : 'DISABLED'}
      />
    ),
  },
  {
    id: 'actions',
    header: 'Ações',
    isSticky: true,
    cell: () => {
      const actions = useMenuActions();

      return (
        <Menu type="default" locked options={actions()}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;

const cardTypeMap = {
  CREDIT: 'Crédito',
  PREPAID: 'Pré-pago',
} as const satisfies Record<Row['cardType'], string>;
