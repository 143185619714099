import { Icons, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Card from '@frontend/components/display/Card';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import useReclassifyTransaction from '../../data/useReclassifyTransaction';
import useReclassifyTransactionPreview from '../../data/useReclassifyTransactionPreview';

type Props = {
  transactionId: string;
};

function ReclassificationPreviewModal(props: Props) {
  const modalController = ModalService.useModalController();
  const theme = useTheme();
  const { data, loading } = useReclassifyTransactionPreview({
    transactionId: props.transactionId,
  });
  const { reclassifyTransaction, requesting } = useReclassifyTransaction({
    transactionId: props.transactionId,
  });

  // Prevents the modal from just hiding
  useEffect(() => {
    if (modalController.visible) return;
    modalController.remove();
  }, [modalController.visible]);

  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      title="Reclassificar compra"
      subtitle={
        data &&
        `O valor será reclassificado para o saldo de ${data.preview.title}.`
      }
      buttons={{
        confirm: {
          text: 'Continuar',
          onClick: reclassifyTransaction,
          disabled: loading,
          loading: requesting,
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner size={64} variant="primary" />
        </div>
      ) : (
        <Body>
          <Card
            variant="primary"
            title={data.current.title}
            subtitle={data.current.body}
            icon={data.current.icon}
          />
          <Icons
            name="IconArrowNarrowRight"
            color={theme.colors.secondary[50]}
          />
          <Card
            active
            variant="primary"
            title={data.preview.title}
            subtitle={data.preview.body}
            icon={data.preview.icon}
          />
        </Body>
      )}
    </DefaultModal>
  );
}

export default ModalService.create(ReclassificationPreviewModal);

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;
