import { trpc } from '@frontend/trpc';
import { cpfMask } from 'backend/src/utils/masks/formatCPF';

/** Returns a list of employees as options for the ComboBox input */
export function useGetEmployees() {
  const getEmployees = trpc.company.cards.findToAdd.useQuery({
    search: '',
    limit: 99999,
  });

  return {
    isError: getEmployees.isError,
    loading: getEmployees.isLoading,
    refresh: getEmployees.refetch,
    employees:
      getEmployees.data?.users
        .sort((a) => (a.alreadyActive ? 1 : -1))
        .map((user) => ({
          /** Employee's name */
          title: user.name,
          /** Employee's nanoID */
          value: user.id,
          /** Employee's document number */
          description: user.alreadyActive
            ? 'Esta pessoa já usa o cartão corporativo'
            : cpfMask(user.documentNumber),
          /** Employee's raw document number */
          additionalFilterKey: user.documentNumber,
          /** Disables selection of the employee if already active */
          disabled: user.alreadyActive,
        })) ?? [],
  };
}
