import FormService from '@frontend/services/FormService';
import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';
import useUpdateCardName from '../../data/useUpdateCardName';

export const FormSchema = z.object({
  cardName: z
    .string({ required_error: errors.forms.generic.required })
    .min(1, errors.forms.generic.required)
    .max(20, 'O campo deve ter no máximo 20 caracteres'),
  password: z
    .string({ required_error: errors.forms.generic.required })
    .length(4, errors.forms.transactionPassword.wrongSize),
});

type Input = {
  cardId: string;
};

export default function useForm(input: Input) {
  const form = FormService.useCreateForm(FormSchema);
  const updateAlias = useUpdateCardName();

  const onSubmit = form.handleSubmit((formValues) => {
    updateAlias.mutateAsync({ ...formValues, ...input }).then((data) => {
      // Mutation succeeded
      if (data.success)
        form.reset({
          cardName: '',
          password: '',
        });
      // Mutation failed
      else
        switch (data.code) {
          case 'INVALID_TRANSACTION_PASSWORD':
            form.resetField('password', { defaultValue: '' });
            form.setError('password', {
              message: errors.forms.transactionPassword.wrongPassword,
              type: 'validate',
            });
            break;
          case 'TOO_MANY_PASSWORD_ATTEMPTS':
            form.reset({
              cardName: '',
              password: '',
            });
        }
    });
  });

  return {
    control: form.control,
    onSubmit,
    isLoading: updateAlias.isLoading,
  };
}
