import { Icons, Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import { TableProps } from '@frontend/components/TableV2';
import Tag from '@frontend/components/TagV2';
import AddFlashCashModal from '@frontend/external/DashboardSection/add-flash-cash-modal';
import CancelAutomaticCreditFeedbackModal from '@frontend/pages/Home/FlashCard/modals/EditAutomaticCredit/CancelAutomaticCreditFeedback';
import * as styled from '@frontend/pages/Home/FlashCard/styled';
import CancelDepositModal from '@frontend/pages/Transactions/Deposits/CancelDepositModal/CancelDepositModal';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import { formatDate } from '@frontend/utils/masks/formatDate';
import { CreditInfo } from './data/useCreditInfo';

interface ITablePageSizeOption {
  label: string;
  value: number;
}

export const pageSizeOptions: ITablePageSizeOption[] = [
  { label: '5 itens', value: 5 },
  { label: '10 itens', value: 10 },
  { label: '20 itens', value: 20 },
];

type Rows = CreditInfo['credits'][number];
type Columns = TableProps<Rows>['columns'];

export const managementColumns: Columns = [
  {
    accessorKey: 'employee.cardNumber',
    header: () => <styled.Cell>Nº do cartão</styled.Cell>,
    accessorFn: (row) => row.employee.cardNumber,
    cell: ({ cell }) => {
      const value = cell.getValue<string>();
      return <styled.Cell>{value ? `––– ${value}` : ''}</styled.Cell>;
    },
  },
  {
    accessorKey: 'employee',
    header: () => (
      <styled.Cell>
        <Spacer x="xs" />
        Colaborador
      </styled.Cell>
    ),
    cell: (context) => {
      const value = context.row.original.employee;
      return (
        <styled.StyledBoxAdornment
          leftAdornment={<styled.StyledIcon>{value.name[0]}</styled.StyledIcon>}
          title={capitalizeWords(value.name)}
          description={
            value.email ? (
              <styled.Wrapper>
                <Icons fill="transparent" name="IconMail" size={15} />
                <Spacer x="xs5" />
                {value.email}
              </styled.Wrapper>
            ) : (
              <></>
            )
          }
        />
      );
    },
  },
  {
    accessorKey: 'type',
    header: () => <styled.Cell>Tipo de recarga</styled.Cell>,
    accessorFn: ({ type }) => {
      switch (type) {
        case 'normal':
          return 'Recarga Única';
        case 'fixedDate':
          return 'Recarga Automática';
        case 'fixedValue':
          return 'Recarga Automática Complementar';
        default:
          return 'Recarga Única';
      }
    },
    cell: ({ cell }) => {
      const value = cell.getValue<string>();
      return <styled.Cell>{value}</styled.Cell>;
    },
  },
  {
    accessorKey: 'date',
    header: () => <styled.Cell>Data da recarga</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return formatDate(value);
    },
  },
  {
    accessorKey: 'value',
    header: () => <styled.Cell>Valor da recarga</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<number>();
      return toCurrency(value);
    },
  },
  {
    accessorKey: 'isOkay',
    header: () => <styled.Cell>Status da recarga</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<boolean>();
      const props: {
        variant: 'error' | 'primary' | 'success' | 'info' | 'neutral';
        description: string;
      } = {
        variant: 'error',
        description: 'Com pendência',
      };
      if (value) {
        props.variant = 'success';
        props.description = 'Sem pendência';
      }
      return (
        <Tag size="md" variant={props.variant}>
          {props.description}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'externalLegacyId',
    accessorFn: (row) => ({
      isOkay: row.isOkay,
      type: row.type,
    }),
    header: () => <styled.Cell>Observação</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<{
        isOkay: boolean;
        type: 'none' | 'normal' | 'fixedDate' | 'fixedValue';
      }>();
      let description = '';
      switch (value.type) {
        case 'none':
        case 'normal':
          description = value.isOkay ? 'Boleto pago' : 'Boleto em aberto';
          break;
        default:
          description = value.isOkay
            ? 'Saldo Flash Cash Corporativo suficiente'
            : 'Saldo Flash Cash Corporativo insuficiente';
          break;
      }
      value.type;

      return <div style={{ width: '200px' }}>{description}</div>;
    },
  },
  {
    isSticky: true,
    accessorKey: 'externalLegacyId',
    accessorFn: (row) => ({
      amount: row.value,
      date: row.date.toISOString(),
      employeeName: row.employee.name,
      employeeDepositId: row.externalLegacyId,
      type: row.type,
      isOkay: row.isOkay,
      employeeId: row.employee.externalLegacyId,
    }),
    header: () => <styled.Cell>Ações</styled.Cell>,
    cell: ({ cell }) => {
      const options: React.ComponentProps<typeof Menu>['options'] = [];
      const props = cell.getValue<{
        amount: number;
        date: string;
        employeeName: string;
        employeeDepositId: string;
        type: 'none' | 'normal' | 'fixedDate' | 'fixedValue';
        isOkay: boolean;
      }>();
      if (!props.isOkay) {
        const { type, date, amount, employeeDepositId } = props;

        const context = trpc.useContext();

        if (type === 'fixedDate' || type === 'fixedValue') {
          options.push({
            label: 'Cancelar recarga',
            onClick: () =>
              ModalService.show(CancelAutomaticCreditFeedbackModal, {
                automaticCreditMetadata: {
                  date: new Date(date),
                  amount,
                  type,
                  employeeDepositIdList: [employeeDepositId],
                },
                onCancel: () => {
                  context.company.deposits.projection.refetch(undefined);
                },
              }),
          });
          options.push({
            label: 'Adicionar Flash Cash corporativo',
            onClick: () => ModalService.show(AddFlashCashModal, {}),
          });
        } else {
          options.push({
            label: 'Cancelar recarga',
            onClick: () =>
              ModalService.show(CancelDepositModal, {
                amount: props.amount,
                employeeName: props.employeeName,
                employeeDepositId: props.employeeDepositId,
                date: props.date,
                onCancel: () => {
                  context.company.deposits.projection.refetch(undefined);
                },
              }),
          });
        }
      }
      return options.length ? (
        <Menu type="default" options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      ) : (
        <div>
          <Spacer y="m" />
          <Spacer y="xs3" />
        </div>
      );
    },
  },
];
