import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import React from 'react';
import { capitalizeWords } from '../../../../../utils/masks/capitalizeWords';
import * as styles from '../../../styled';
import { UseGetPlasticCardsReturn } from '../data/useGetPlasticCards';
import useMenuActions from './useMenuActions';

type Row = UseGetPlasticCardsReturn['items'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'provider',
    enableSorting: false,
    header: () => <styles.NoWrap>Emissora</styles.NoWrap>,
    cell: ({ cell }) => {
      const value = cell.row.original.provider;
      return <CardProviderChip provider={value} variant="color" />;
    },
  },
  {
    accessorKey: 'cardNumber',
    header: 'Cartão',
    cell: ({ cell }) => {
      const value = cell.row.original.cardNumber;
      return <styles.NoWrap>––– {value}</styles.NoWrap>;
    },
  },
  {
    accessorKey: 'ownerName',
    header: 'Nome no cartão',
    cell: ({ cell }) => capitalizeWords(cell.row.original.ownerName ?? ''),
  },
  {
    accessorKey: 'balances',
    header: 'Saldo associado',
    cell: ({ cell }) => {
      const value = cell.row.original.balances;
      return (
        <styles.NoWrap>
          {value.map((balance) => balanceTypeMap[balance]).join('/')}
        </styles.NoWrap>
      );
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    cell: ({ cell }) => {
      const actions = useMenuActions();
      return (
        <Menu type="default" locked options={actions(cell.row.original)}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;

const balanceTypeMap = {
  BENEFITS: 'Benefícios',
  CORPORATE: 'Corporativo',
} as const;
