import {
  GenericProfilePicture,
  Icons,
  SelectField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';
import { Controller } from 'react-hook-form';
import useListUsers from '../data/useListUsers';
import { UseFormReturn } from '../form/useForm';

type UserCardProps = {
  name: string;
  email?: string;
  companyRole: string | null;
  reportTo: string | null;
};

export const UserCard = ({
  name,
  companyRole,
  email,
  reportTo,
}: UserCardProps) => {
  return (
    <ModuleContainer
      padding="16px"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'flex-start',
        gap: '24px',
      }}
    >
      <GenericProfilePicture name={name} size={64} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography.Headline6>{name || ''}</Typography.Headline6>
        {!!companyRole && (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            <Icons name="IconBriefcase" fill="none" />
            <Typography.Body4>{companyRole}</Typography.Body4>
          </div>
        )}
        {!!email && (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            <Icons fill="transparent" name="IconMail" size={15} />
            <Typography.Body4>{email}</Typography.Body4>
          </div>
        )}
        {!!reportTo && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Typography.Body4>Reporta para </Typography.Body4>
            <Typography.Body4 color="primary">{reportTo}</Typography.Body4>
          </div>
        )}
      </div>
    </ModuleContainer>
  );
};

type Props = {
  form: UseFormReturn;
  limit: number;
  loading: boolean;
};

export function SelectPerson({ form, limit, loading: isLoading }: Props) {
  const { findUsers, options, loading } = useListUsers();

  return (
    <Controller
      name="employee"
      control={form.control}
      render={(props) => (
        <ModuleContainer
          gap="16px"
          padding="32px"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              flex: 2,
            }}
          >
            <Flex direction="column" gap="xs3">
              <Typography.Headline8 color="neutral_20">
                Selecione o titular da conta
              </Typography.Headline8>
              <Typography.Body4 color="neutral_50">
                {`Escolha a pessoa para a qual você deseja criar novo cartão ${
                  !isLoading
                    ? `(Limite de ${limit
                        ?.toString()
                        .padStart(2, '0')} cartão virtual por colaborador)`
                    : ''
                }`}
              </Typography.Body4>
            </Flex>
            <SelectField
              filterOptions={(options, state) => {
                const filterString = state.inputValue.toLowerCase();

                return options.filter(
                  (option) =>
                    option.value.toLowerCase().includes(filterString) ||
                    option.label.toLowerCase().includes(filterString),
                );
              }}
              searchable={true}
              label="Nome ou CPF da Pessoa"
              options={options}
              name="employee"
              noOptionsText={
                loading ? 'Buscando...' : 'Digite algo para buscar'
              }
              onChange={({ target }) => {
                const newValue = target.value;
                if (!!newValue.length) {
                  findUsers({ stringSearch: newValue });
                }
              }}
              onSelectChange={(_, selected) => {
                props.field.onChange(!!selected ? selected : {});
              }}
              error={!!props.formState.errors.employee}
              helperText={props.formState.errors.employee?.message}
            />
          </div>
          {!!props.field.value && !!props.field.value.name && (
            <UserCard
              name={props.field.value.name}
              companyRole={null}
              reportTo={null}
            />
          )}
        </ModuleContainer>
      )}
    />
  );
}
