import { RouterOutputs, trpc } from '@frontend/trpc';

type Input = {
  policy?: RouterOutputs['employee']['flashCards']['virtual']['getAll']['cards'][number]['policy'];
};

/** Calculates the available balance for the usage of a corporate card, based on
 * the current balance and the policy limit.
 */
export default function useGetCorporateAvailableBalance(input: Input) {
  const balance = trpc.employee.corporateCard.getBalance.useQuery();

  if (balance.data === undefined)
    // Balance not available yet
    return {
      data: null,
    };

  /** Available limit for corporate card, only considering policy limit */
  const availablePolicyLimit = input.policy?.limit
    ? input.policy.limit.amount - input.policy.limit.used
    : null;

  /** Calculates the available balance as the minimum between the current
   * balance and the policy limit */
  const availableBalance =
    availablePolicyLimit === null
      ? balance.data
      : Math.min(balance.data, availablePolicyLimit);

  return {
    data: availableBalance,
  };
}
