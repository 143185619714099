import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import { useGetCode } from '../../data/useGetCode';
import { UseFormReturn } from '../../form/useForm';
import { PaymentSummary } from '../paymentSummary/PaymentSummary';
import * as SC from './styled';

type Props = {
  form: UseFormReturn;
  codeData: ReturnType<typeof useGetCode>;
};

export function Success(props: Props) {
  return (
    <SC.Wrapper gap="xs" direction="column">
      <Typography.Body4 color="neutral_40">
        Este pagamento vale apenas para a <strong>carteira corporativa</strong>.
        Acompanhe o seu status no Extrato da Carteira.
      </Typography.Body4>
      <PaymentSummary codeData={props.codeData} />
      <SurveyStore
        title="Como você avalia a facilidade de uso da plataforma ao adicionar saldo na carteira corporativa?"
        type="add-flash-cash-feedback"
      />
    </SC.Wrapper>
  );
}
